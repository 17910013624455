import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { useMst } from './store';
import { observer } from 'mobx-react-lite';

import cn from 'classnames';
import { Footer, Header, Sidebar, UserModal, WalletConnectModal } from 'containers';
import RouteManager from 'router/RouteManager';

export const App: FC = observer(() => {
  const { theme, sidebar } = useMst();
  const { pathname } = useLocation();
  const [metaTitle, setMetaTitle] = useState('DAPP Home | RichQUACK');
  const [metaDescription, setMetaDescription] = useState(
    'RichQUACK is an Incubator, Launchpad and a community driven deflationary token, backed by a strong community of over 220,000 on Twitter and 65,000 on Telegram',
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.innerWidth <= 1024 && sidebar.isOpen) {
      sidebar.toggleSidebar();
    }
  }, [sidebar]);

  useEffect(() => {
    if (pathname === '/start') {
      setMetaTitle('Start Raising | RichQUACK');
      setMetaDescription(
        'Launch your project on Quack Launchpad Now! Start raising money, invite friends and get your project to Life!',
      );
    }
    if (pathname === '/levels') {
      setMetaTitle('Launchpad Levels | RichQUACK');
      setMetaDescription(
        'Stake your QUACK, Level Up and be eligible to vote & participate in Upcoming IDOs. RichQUACK is an Incubator, Launchpad and a community driven deflationary token, backed by a strong community of over 220,000 on Twitter and 65,000 on Telegram',
      );
    }
    if (pathname === '/voting') {
      setMetaTitle('Project Voting | RichQUACK');
      setMetaDescription(
        'DYOR & Vote for project to life! RichQUACK is an Incubator, Launchpad and a community driven deflationary token, backed by a strong community of over 220,000 on Twitter and 65,000 on Telegram',
      );
    }
    if (pathname === '/staking') {
      setMetaTitle('Staking | RichQUACK');
      setMetaDescription(
        'QUACK offers multiple staking opportunities for you to get other moonshot tokens! RichQUACK is an Incubator, Launchpad and a community driven deflationary token, backed by a strong community of over 220,000 on Twitter and 65,000 on Telegram',
      );
    }
  }, [pathname]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div className={cn(theme.value, 'main_wrapper')}>
        <Header />
        <Sidebar />
        <div className={cn('page_wrapper', { active: sidebar.isOpen })}>
          <div className="container">
            <RouteManager />
          </div>
        </div>
        <Footer />
      </div>
      <UserModal />
      <WalletConnectModal />
    </>
  );
});
