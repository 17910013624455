import { AxiosResponse } from 'axios';

import axios from './core';

interface getQueryParams {
  search?: string | unknown;
  id?: string | unknown;
  status?: string;
  network?: string;
  type?: string;
  ordering?: string;
}

export default {
  create: (data: any): Promise<AxiosResponse<any>> =>
    axios.post('presale/create/', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    }),
  get: (data?: getQueryParams): Promise<AxiosResponse<any>> => {
    return axios.get(`presale/`, { params: data });
  },
};
