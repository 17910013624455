import { FC, useMemo } from 'react';

import cn from 'classnames';

import s from './Progress.module.scss';

interface IProgressProps {
  view?: 'full' | 'lite';
  all: number;
  totalRaised: number;
  name: string;
  titleClass?: string;
  className?: string;
}
const Progress: FC<IProgressProps> = ({
  view = 'full',
  totalRaised,
  all,
  className,
  titleClass,
  name,
}) => {
  const countPercentage = useMemo(() => {
    return Math.floor((totalRaised / all) * 100);
  }, [all, totalRaised]);
  const fillerColor = useMemo(() => {
    if (view === 'lite') {
      let color;
      switch (true) {
        case countPercentage < 25:
          color = 'red';
          break;
        case countPercentage < 75:
          color = 'yellow';
          break;
        default:
          color = 'green';
          break;
      }
      return color;
    }
    return 'accent';
  }, [countPercentage, view]);

  return (
    <div className={s.progress}>
      <div className={cn(s.title, titleClass)}>
        {view === 'full' && (
          <>
            <span>{name}</span>
            <span className="text-orange">{countPercentage}%</span>
          </>
        )}
      </div>
      <div className={cn(s.container, className, s[view])}>
        <div className={cn(s.filler, s[fillerColor])} style={{ width: `${countPercentage}%` }} />
      </div>
    </div>
  );
};

export default Progress;
