import React from 'react';

import cn from 'classnames';
import Upload from 'rc-upload';
import { UploadProps } from 'rc-upload/lib';

import UploaderImg from 'assets/img/icons/uploader.svg';

import style from './Uploader.module.scss';

interface IUploader extends UploadProps {
  title?: string;
  subtitle?: string;
  className?: string;
  img?: string;
  error?: string;
}

const Uploader: React.FC<IUploader> = (props) => {
  const { title, subtitle, className, img, error, ...other } = props;
  return (
    <div className={style.uploader__wrapper}>
      <Upload prefixCls={cn(style.uploader, className)} {...other}>
        {img ? (
          <img src={img} className={style.uploader__preview} alt="" />
        ) : (
          <>
            <img className={style.uploader__img} src={UploaderImg} alt="" />
            {title ? <div className={style.uploader__title}>{title}</div> : null}
            {subtitle ? (
              <div className={cn(style.uploader__subtitle, 'text-gray')}>{subtitle}</div>
            ) : null}
          </>
        )}
      </Upload>
      {error ? <div className={style.uploader__error}>{error}</div> : null}
    </div>
  );
};

export default Uploader;
