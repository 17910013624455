import { FC, useCallback, useState } from 'react';

import { ThemeEnum, useMst } from '../../../../store';
import { observer } from 'mobx-react-lite';

import cn from 'classnames';
import { Circle } from 'rc-progress';

import { Button } from '../../../../components';

import { progresses } from '../mock';

import logoIcon from 'assets/img/icons/logo.png';

import s from './Presale.module.scss';

const Presale: FC = observer(() => {
  // TODO: ADD ADAPTIVENESS AND LIGHT THEME
  const { theme } = useMst();
  const [whitelist, setWhitelist] = useState<boolean>(false);
  const toggleWhitelist = useCallback(() => {
    setWhitelist(true);
  }, []);
  return (
    <div className={s.presale_wrapper}>
      <div className={s.tab_head}>
        <div>
          <div className={s.title}>
            Whitelist Round Starts in <span>21 Days</span>
          </div>
          <div className={s.subtitle}>
            <span>134347 blocks to go</span>
            <div className={s.dot} />
            <span>Wed 1 Sep 01:52</span>
          </div>
        </div>
        <div className={s.status}>Awaiting Start</div>
      </div>
      <div className={s.progress_wrapper}>
        {progresses.map(({ label, progress, icon }) => {
          const Icon = icon;
          return (
            <div key={label} className={s.progress_item}>
              <Circle
                className={s.progress}
                percent={progress}
                strokeWidth={12}
                trailWidth={8}
                strokeColor={theme.value === ThemeEnum.dark ? '#FFBB0B' : '#FF9900'}
                trailColor={theme.value === ThemeEnum.dark ? '#2C2D6D' : '#ECECEC'}
              />
              <div className={s.icon}>
                <Icon />
              </div>
              <div className={s.percent}>{progress}%</div>
              <div className={s.subtitle}>{label}</div>
            </div>
          );
        })}
      </div>
      <div className={s.requirements}>
        {!whitelist ? (
          <>
            <div className={s.title}>Whitelist Round Requirements</div>
            <div className={s.description}>
              You need to be whitelisted to participate in this round, typically to reward early
              followers of the project or QUACK community incentive. To get whitelisted you need to
              be an active members of the project and participate in their launch events.
            </div>
            <Button className={s.req_btn} size="medium" color="filled" onClick={toggleWhitelist}>
              Whitelist
            </Button>
          </>
        ) : (
          <>
            <div className={s.title}>Moon Round Requirements</div>
            <div className={s.description}>
              To participate in Guaranteed Allocation Round 1 you need to hold at least the
              specified amount of ONE of the following tokens. Tier 1-8 Requirement. Read Staking
            </div>
            <div className={s.moon_round_wrapper}>
              <img src={logoIcon} alt="logo" className={s.logo} />

              <div className={s.info}>
                <div className={s.row}>
                  <div className={s.item}>
                    <div className={s.label}>Pancakeswap V2</div>
                    <div className={cn(s.label, s.value)}>QUACK on BSC</div>
                  </div>
                  <div className={s.item}>
                    <div className={s.label}>Allocation: 0%</div>
                    <div className={cn(s.label, s.value)}>0/0.8 BNB</div>
                  </div>
                  <div className={s.item}>
                    <div className={s.label}>Tier</div>
                    <div className={cn(s.label, s.value)}>1</div>
                  </div>
                </div>
                <div className={s.row}>
                  <div className={s.label}>
                    Your balance:{' '}
                    <span className={cn(s.label, s.lg, s.value)}>50,000,000,000,000</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default Presale;
