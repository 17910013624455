import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import cn from 'classnames';

import dropdownIcon from '../../assets/img/icons/dropdown_arrow.svg';
import SubLink from './SubLink';

import s from './MenuLink.module.scss';

interface IRouteProps {
  menu: boolean;
  icon: string;
  external: boolean;
}
interface IRouteChild {
  name: string;
  path: string;
}
interface IRoute {
  name: string;
  path: string;
  props: IRouteProps;
  children?: Array<IRouteChild>;
}
interface IMenuLinkProps {
  children?: ReactElement | string;
  route: IRoute;
  index: number | null;
}

const MenuLink: FC<IMenuLinkProps> = observer((props) => {
  const { route, index } = props;
  const { sidebar } = useMst();
  // Get route path
  const resolved = useResolvedPath(route.path);
  // Check if route match current url
  const match = useMatch({ path: resolved.pathname, end: true });
  // State to check if sublink active to add active for dropdown
  const [isDropdownActive, setIsDropdownActive] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      if (sidebar.activeMenuIndex !== index) {
        setIsDropdownActive(false);
      }
    };
  }, [sidebar.activeMenuIndex, index]);

  const handleDropdown = useCallback(() => {
    if (isDropdownActive) {
      setIsDropdownActive(false);
    } else {
      setIsDropdownActive(true);
      sidebar.setActiveMenuIndex(index);
    }
  }, [index, isDropdownActive, sidebar]);
  if (Object.prototype.hasOwnProperty.call(route, 'children')) {
    return (
      <div className={s.link_wrapper}>
        {sidebar.isOpen ? (
          <div
            role="button"
            tabIndex={0}
            onClick={handleDropdown}
            onKeyDown={() => {}}
            className={cn(s.link, isDropdownActive ? s.active : '')}
          >
            <img className={s.icon} src={route.props.icon} alt="menu-icon" />
            <span className={s.link_name}>{route.name}</span>
            <img className={s.dropdown} src={dropdownIcon} alt="dropdown-icon" />
          </div>
        ) : (
          <div
            role="button"
            tabIndex={0}
            onClick={sidebar.toggleSidebar}
            onKeyDown={() => {}}
            className={cn(s.link, isDropdownActive ? s.active : '')}
          >
            <img className={s.icon} src={route.props.icon} alt="menu-icon" />
          </div>
        )}
        {isDropdownActive &&
          sidebar.isOpen &&
          route.children?.map((child) => (
            <SubLink key={child.name} name={child.name} path={child.path} />
          ))}
      </div>
    );
  }
  if (route.props.external) {
    return (
      <div key={route.name} className={s.link_wrapper}>
        <a className={cn(s.link, match ? s.active : '')} href={route.path}>
          <img className={s.icon} src={route.props.icon} alt="menu-icon" />
          {sidebar.isOpen && <span className={s.link_name}>{route.name}</span>}
        </a>
      </div>
    );
  }

  if (route.props.external) {
    return (
      <div key={route.name} className={s.link_wrapper}>
        <a className={cn(s.link, match ? s.active : '')} href={route.path}>
          <img className={s.icon} src={route.props.icon} alt="menu-icon" />
          {sidebar.isOpen && <span className={s.link_name}>{route.name}</span>}
        </a>
      </div>
    );
  }

  return (
    <div key={route.name} className={s.link_wrapper}>
      <Link onClick={handleDropdown} className={cn(s.link, match ? s.active : '')} to={route.path}>
        <img className={s.icon} src={route.props.icon} alt="menu-icon" />
        {sidebar.isOpen && <span className={s.link_name}>{route.name}</span>}
      </Link>
    </div>
  );
});

export default MenuLink;
