import React from 'react';

import { withFormik } from 'formik';
import * as Yup from 'yup';

import { isAddress } from 'utils';

import { IForm } from 'pages/CreateProject';

import Form from '../component';

export interface IValues {
  whitelist: string;
}

export interface IWhitelist {
  handleBack: () => void;
  handleUpdateData: (key: keyof IForm, data: IForm[typeof key]) => void;
  initialValues: IValues;
  btnText: string;
  isLoading: boolean;
  isApprovingToken: boolean;
  isApprovingLink: boolean;
}

export type IWhitelistForm = Pick<
  IWhitelist,
  'handleBack' | 'btnText' | 'isLoading' | 'isApprovingToken' | 'isApprovingLink'
>;

const Whitelist: React.FC<IWhitelist> = ({
  handleBack,
  handleUpdateData,
  initialValues,
  btnText,
  isLoading,
  isApprovingToken,
  isApprovingLink,
}) => {
  const FormWithFormik = withFormik<IWhitelistForm, IValues>({
    enableReinitialize: true,
    mapPropsToValues: () => initialValues,
    validationSchema: Yup.object().shape({
      whitelist: Yup.string().test(
        'isAddress',
        'One of the addresses are not valid',
        (value: any): boolean => {
          if (value && value !== '') {
            return value.split('\n').every((str: string) => isAddress(str));
          }
          return true;
        },
      ),
    }),

    handleSubmit: (values) => {
      handleUpdateData('whitelist', values);
    },

    displayName: 'Whitelist',
  })(Form);
  return (
    <FormWithFormik
      handleBack={handleBack}
      btnText={btnText}
      isLoading={isLoading}
      isApprovingToken={isApprovingToken}
      isApprovingLink={isApprovingLink}
    />
  );
};

export default Whitelist;
