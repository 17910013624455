import { EthXs, Lock } from 'assets/img';

export const data = [
  {
    value: '100% BNB raised liquidity lock',
    label: '12 months lock duration',
    icon: Lock,
  },
  {
    value: '100 BNB',
    label: 'Softcap',
    icon: EthXs,
  },
  {
    value: '320 BNB',
    label: 'Hardcap',
    icon: EthXs,
  },
  {
    value: '0.8 BNB',
    label: 'Max spend per account',
    icon: EthXs,
  },
  {
    value: (
      <div>
        68.750 EMPIRE <span>/per BNB</span>
      </div>
    ),
    label: 'Presale price',
    icon: EthXs,
  },
  {
    value: (
      <div>
        61.875 EMPIRE <span>/per BNB</span>
      </div>
    ),
    label: 'Listing price',
    icon: EthXs,
  },
];
