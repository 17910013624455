import { types } from 'mobx-state-tree';

const Sidebar = types
  .model({
    activeMenuIndex: types.optional(types.maybeNull(types.number), null),
    isOpen: types.boolean,
  })
  .actions((self) => {
    const setActiveMenuIndex = (index: number | null) => {
      self.activeMenuIndex = index;
    };
    const toggleSidebar = () => {
      self.isOpen = !self.isOpen;
    };
    return {
      setActiveMenuIndex,
      toggleSidebar,
    };
  });
export default Sidebar;
