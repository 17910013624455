import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import rootStore, { Provider } from 'store';

import { GetData } from 'services';
import WalletConnect from 'services/WalletConnect';

import { App } from './App';

import 'styles/index.scss';

const root = document.getElementById('root');
const app = (
  <Provider value={rootStore}>
    <Router>
      <WalletConnect>
        <GetData>
          <App />
        </GetData>
      </WalletConnect>
    </Router>
  </Provider>
);

ReactDOM.render(app, root);
