import { FC } from 'react';

import cn from 'classnames';

import style from './CheckItem.module.scss';

interface ICheckItem {
  onClick: (index: number) => void;
  index: number;
  isActive?: boolean;
  centered?: boolean;
  color?: 'dark' | 'blue' | 'dark-border';
  isAbsoluteItem?: boolean;
  className?: string;
}

const CheckItem: FC<ICheckItem> = ({
  children,
  isActive = false,
  onClick,
  index,
  centered = true,
  color = 'dark',
  className,
  isAbsoluteItem = false,
}) => {
  return (
    <div
      className={cn(style.check, style[color], className, {
        [style.active]: isActive,
        [style.check__center]: centered,
        [style.check__absolute]: isAbsoluteItem,
      })}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={() => onClick(index)}
    >
      <div className={style.check__item} />
      <div className={style.check__content}>{children}</div>
    </div>
  );
};

export default CheckItem;
