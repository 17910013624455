import { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import { Button, CheckItem, Icon, Modal } from 'components';
import { wallets } from 'utils/constants';

import { useCheck } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { chainsEnum } from 'types';

import s from './ConnectModal.module.scss';

const WalletConnect: FC = observer(() => {
  const { connect } = useWalletConnectorContext();
  const {
    modals: { walletConnect },
  } = useMst();
  const [activeIndex, handleClick] = useCheck(0);
  const connectToWallet = useCallback(() => {
    connect(chainsEnum['Binance-Smart-Chain'], wallets[activeIndex].providerName)
      .then(() => {
        walletConnect.toggle();
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  }, [activeIndex, connect, walletConnect]);

  return (
    <Modal
      className={s.modal_wrapper}
      visible={walletConnect.isOpen}
      onClose={walletConnect.toggle}
    >
      <div className={s.wallet_connect_wrapper}>
        <div className={s.title}>Connect to Wallet</div>
        <div className={s.wallets_wrapper}>
          {wallets.map(({ name, icon }, index) => (
            <CheckItem
              key={name}
              isActive={activeIndex === index}
              index={index}
              onClick={handleClick}
            >
              <div className={s.wallet}>
                <span>{name}</span>
                <Icon icon={icon} size="big" className={s.icon} />
              </div>
            </CheckItem>
          ))}
        </div>
        <Button
          color="filled"
          isFullWidth
          disabled={activeIndex === null}
          className={s.btn}
          onClick={connectToWallet}
        >
          Connect
        </Button>
        <Button isFullWidth className={s.btn_cancel} onClick={() => walletConnect.toggle()}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
});

export default WalletConnect;
