import { FC, useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import BigNumber from 'bignumber.js/bignumber';

import { Button, CongratulationModal } from 'components';
import { contracts } from 'config';
import { addressWithDots } from 'utils';

import { useModal } from 'hooks';
import { useWalletConnectorContext, WalletService } from 'services';

import userAvatar from 'assets/img/mock/user_avatar.png';

import s from './LevelsHarvest.module.scss';

const LevelsHarvest: FC = observer(() => {
  const { walletService } = useWalletConnectorContext();
  const {
    user,
    quack,
    modals: { walletConnect },
  } = useMst();
  const [
    isVisibleCongratulationModal,
    handleOpenCongratulationModal,
    handleCloseCongratulationModal,
  ] = useModal(false);
  const [isLoading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(['0', '0']);
  const [quackDecimals] = useState(quack.decimals);

  const handleHarvest = useCallback(async () => {
    try {
      setLoading(true);
      await walletService.createTransaction({
        method: 'withdraw',
        data: [0, 0],
        contract: 'STAKING',
      });
      setTotalAmount(['0', '0']);
      user.setRefreshUserData(true);
      setLoading(false);
      handleOpenCongratulationModal();
    } catch (err) {
      setLoading(false);
    }
  }, [handleOpenCongratulationModal, user, walletService]);

  const handleConnect = useCallback(() => {
    walletConnect.toggle();
  }, [walletConnect]);
  const getTotalHarvestAmount = useCallback(async () => {
    try {
      const rewardPromises: Array<Promise<string>> = new Array(4).fill(0).map((_, index) =>
        walletService.callContractMethod({
          contractName: 'STAKING',
          methodName: 'nextReward',
          contractAddress: contracts.params.STAKING[contracts.type].address,
          contractAbi: contracts.params.STAKING[contracts.type].abi,
          data: [user.address, index],
        }),
      );

      const rewards = await Promise.all(rewardPromises);
      let finalAmount = new BigNumber(0);
      Object.values(rewards).forEach((value) => {
        finalAmount = new BigNumber(finalAmount).plus(value);
      });

      return [
        new BigNumber(WalletService.weiToEth(finalAmount.toString(), quackDecimals))
          .multipliedBy(quack.data.price)
          .toFixed(2),
        new BigNumber(WalletService.weiToEth(finalAmount.toString(), quackDecimals)).toFixed(2),
      ];
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      return ['0', '0'];
    }
  }, [quack.data.price, quackDecimals, user.address, walletService]);

  useEffect(() => {
    if (user.address) {
      getTotalHarvestAmount().then((res) => {
        setTotalAmount(res);
      });
    }
  }, [getTotalHarvestAmount, user.address]);
  return (
    <>
      <div className={s.l_harvest}>
        <div className={s.harvest_wrapper}>
          {user.address && (
            <div className={s.user_info}>
              <div className={s.user_img}>
                <img src={userAvatar} alt="avatar" />
              </div>
              <span>Hi, {addressWithDots(user.address, 4, '***')}</span>
            </div>
          )}
          <div className={s.harvest_container}>
            <div className={s.harvest_amount}>
              <div className={s.token}>$QUACK {totalAmount[1]}</div>
              <div className={s.amount}>${totalAmount[0]}</div>
              <div className={s.label}>To collect</div>
            </div>
            {user.address ? (
              <Button
                className={s.harvest_btn}
                color="filled"
                onClick={handleHarvest}
                loading={isLoading}
                disabled={totalAmount[0] === '0.00'}
              >
                Harvest All
              </Button>
            ) : (
              <Button
                color="filled"
                size="medium"
                onClick={handleConnect}
                className={s.harvest_btn}
              >
                Connect
              </Button>
            )}
          </div>
        </div>
      </div>
      <CongratulationModal
        text="You’ve successfully collected your rewards!"
        visible={isVisibleCongratulationModal}
        onClose={handleCloseCongratulationModal}
        btnText="Confirm"
        btnAction={handleCloseCongratulationModal}
      />
    </>
  );
});

export default LevelsHarvest;
