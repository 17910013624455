import { FC, useCallback } from 'react';

import cn from 'classnames';

import s from './Pagination.module.scss';

interface IPaginationProps {
  filters: any;
  setFilters: any;
  prev: null | string;
  next: null | string;
  className?: string;
}
const Pagination: FC<IPaginationProps> = ({ filters, setFilters, prev, next, className }) => {
  const onPagination = useCallback(
    (type: string) => {
      if (type === 'prev') {
        if (!prev) {
          return;
        }
        setFilters({ ...filters, ...{ page: filters.page - 1 } });
      }
      if (type === 'next') {
        if (!next) {
          return;
        }
        setFilters({ ...filters, ...{ page: filters.page + 1 } });
      }
    },
    [filters, next, prev, setFilters],
  );

  return (
    <div className={cn(s.pagination_wrapper, className)}>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        onClick={() => onPagination('prev')}
        className={cn(s.prev, prev !== null && s.active)}
      />
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        onClick={() => onPagination('next')}
        className={cn(s.next, next !== null && s.active)}
      />
    </div>
  );
};

export default Pagination;
