import React from 'react';

import { IProjectCard } from 'types';

import style from './StartProjectItems.module.scss';

interface IStartProjectItems {
  items: IProjectCard[];
}

const StartProjectItems: React.FC<IStartProjectItems> = ({ items }) => {
  return (
    <div className={style.sp_items}>
      <div className={style.sp_items__title}>Recent Presale</div>
      <div className="projects_wrapper">
        {items.map((item) => (
          <React.Fragment key={item.name} />
          // <ProjectCard key={item.name} project={item} />
        ))}
      </div>
    </div>
  );
};

export default StartProjectItems;
