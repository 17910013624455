import React from 'react';

import { StartProjectEmpty, StartProjectItems } from 'containers';

import { Button, PagePreview } from 'components';

// import { project } from './mock';
import style from './StartProject.module.scss';

const preview = {
  title: 'Bring your creative project to life',
  subtitle: 'Start launch',
  btnText: 'CREATE CAMPAIGN',
};

const presales: any = [];

const StartProject: React.FC = () => {
  return (
    <div className={style.s_project}>
      {presales.length ? (
        <>
          <PagePreview {...preview}>
            <Button color="filled" href="/start/type">
              {preview.btnText}
            </Button>
          </PagePreview>
          <StartProjectItems items={presales} />
        </>
      ) : (
        <StartProjectEmpty />
      )}
    </div>
  );
};

export default StartProject;
