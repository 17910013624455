import React from 'react';

import cn from 'classnames';

import { Button, CheckItem, GoBackLink } from 'components';

import { useCheck } from 'hooks';

import PrivateImg from 'assets/img/start_project/type-private.svg';
import PublicImg from 'assets/img/start_project/type-public.svg';
import QubatorImg from 'assets/img/start_project/type-qubator.svg';

import style from './ProjectType.module.scss';

const types = [
  {
    img: PrivateImg,
    title: 'Private IDO',
    text: 'Token vesting on claiming. Community voting not required. Private Sales allow team to raise seed funding before launching public IQO. No listing on DEX after Sale.',
    key: 'private',
  },
  {
    img: PublicImg,
    title: 'Public IDO',
    text: 'Community voting required. Listing on PCS after sale. Token vesting on claiming optional.',
    key: 'public',
  },
  {
    img: QubatorImg,
    title: 'InQubator',
    text: 'Strict acceptance criteria to ensure most trustworthy, high-quality projects. DAO voting required to get accepted. Listing on DEX After Sale. Projects benefit from complete end-to-end assistance.',
    key: 'inqubator',
  },
];

const ProjectType: React.FC = () => {
  const [activeIndex, handleClick] = useCheck(0);

  return (
    <div className={style.p_type}>
      <GoBackLink link="/start" text="Create Presale" />
      <div className={style.p_type__title}>Presale Type</div>
      <div className={style.p_type__box}>
        {types.map((type, index) => (
          <CheckItem
            key={type.title}
            isActive={activeIndex === index}
            index={index}
            onClick={handleClick}
            className={style.p_type__item}
            color="blue"
            isAbsoluteItem
          >
            <div className={style.p_type__item__content}>
              <img src={type.img} alt="" />
              <div className={style.p_type__item__title}>{type.title}</div>
              <div className={cn(style.p_type__item__text, 'text-gray')}>{type.text}</div>
            </div>
          </CheckItem>
        ))}
      </div>
      <Button
        color="filled"
        href={`/start/create/${types[activeIndex].key}`}
        className={style.p_type__btn}
        size="medium"
      >
        Next
      </Button>
    </div>
  );
};

export default ProjectType;
