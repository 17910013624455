import { FC } from 'react';

import cn from 'classnames';

import s from './StatusLabel.module.scss';

interface IProps {
  text: string;
}
const StatusLabel: FC<IProps> = ({ text }) => {
  return (
    <div className={cn(s.status, s[`${text}`])}>
      <span className={s.indicator} />
      <span>{text.replace('-', ' ')}</span>
    </div>
  );
};

export default StatusLabel;
