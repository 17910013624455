import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import cn from 'classnames';
import { LevelsStakeInput } from 'containers';
import { format, getTime } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Button, CongratulationModal } from 'components';
import { contracts } from 'config';
import { convertQuackTokens } from 'utils';
import checkValueDecimals from 'utils/checkValueDecimals';

import { useCheck, useModal } from 'hooks';
import useApprove from 'hooks/useApprove';
import { useWalletConnectorContext } from 'services';
import { ILevel, IPeriod } from 'types';

import 'swiper/swiper.scss';
import s from './LevelsStake.module.scss';

interface ILevelsStake {
  items: ILevel[];
  periods: IPeriod[];
  isPeriodsLoading: boolean;
}

const LevelsStake: FC<ILevelsStake> = observer(({ items, periods, isPeriodsLoading }) => {
  const { walletService } = useWalletConnectorContext();
  const {
    user,
    quack,
    modals: { walletConnect },
  } = useMst();
  const [actionType, setActionType] = useState('stake');
  const [activePeriod, setActivePeriod] = useCheck(0);
  const [stakeAmount, setStakeAmount] = useState('');
  const [unstakeAmount, setUnstakeAmount] = useState('');
  const [stakeInputError, setStakeInputError] = useState(false);
  const [unstakeInputError, setUnstakeInputError] = useState(false);
  const [quackDecimals] = useState(quack.decimals);
  const [isLoading, setLoading] = useState(false);
  const [earlyUnstakeFee, setEarlyUnstakeFee] = useState(0);

  const [
    isVisibleCongratulationModal,
    handleOpenCongratulationModal,
    handleCloseCongratulationModal,
  ] = useModal(false);
  const [isApproved, isApproving, handleApprove, approveError] = useApprove({
    tokenName: 'QUACK',
    tokenAddress: contracts.params.QUACK[contracts.type].address,
    approvedContractName: 'STAKING',
    amount: stakeAmount,
    walletAddress: user.address,
  });

  const showUnstakeWarning =
    user.address && +user.lockUpItems[activePeriod]?.enteredAt > 0
      ? user.lockUpItems[activePeriod].lockEnd * 1000 > getTime(new Date())
      : false;

  const handleChangeStakeAmount = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = checkValueDecimals(e.target.value, quackDecimals);
      setStakeAmount(value);
      if (+value > +user.quackBalance) {
        setStakeInputError(true);
      } else {
        setStakeInputError(false);
      }
    },
    [quackDecimals, user.quackBalance],
  );

  const handleChangeUnstakeAmount = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = checkValueDecimals(e.target.value, quackDecimals);
      setUnstakeAmount(value);
      if (+value > +user.lockUpItems[activePeriod].amountLock) {
        setUnstakeInputError(true);
      } else {
        setUnstakeInputError(false);
      }
    },
    [activePeriod, quackDecimals, user.lockUpItems],
  );

  const handleMax = useCallback(
    (type: 'stake' | 'unstake') => {
      if (type === 'stake') {
        setStakeAmount(user.quackBalance);
        setStakeInputError(false);
      } else {
        setUnstakeAmount(user.lockUpItems[activePeriod].amountLock);
        setUnstakeInputError(false);
      }
    },
    [activePeriod, user.quackBalance, user.lockUpItems],
  );

  const handleStake = useCallback(async () => {
    try {
      setLoading(true);
      setActionType('stake');
      const finalAmount = await walletService.calcTransactionAmount(
        contracts.params.QUACK[contracts.type].address,
        stakeAmount,
      );
      await walletService.createTransaction({
        method: 'deposit',
        data: [finalAmount, activePeriod],
        contract: 'STAKING',
      });
      user.setRefreshUserData(true);
      setLoading(false);
      setStakeAmount('');
      handleOpenCongratulationModal();
    } catch (err) {
      setLoading(false);
    }
  }, [activePeriod, handleOpenCongratulationModal, stakeAmount, user, walletService]);

  const handleUnstake = useCallback(async () => {
    try {
      setLoading(true);
      setActionType('unstake');
      const finalAmount = await walletService.calcTransactionAmount(
        contracts.params.QUACK[contracts.type].address,
        unstakeAmount,
      );
      await walletService.createTransaction({
        method: 'withdraw',
        data: [finalAmount, activePeriod],
        contract: 'STAKING',
      });
      user.setRefreshUserData(true);
      setLoading(false);
      setUnstakeAmount('');
      handleOpenCongratulationModal();
    } catch (err) {
      setLoading(false);
    }
  }, [activePeriod, handleOpenCongratulationModal, unstakeAmount, user, walletService]);
  const handleChangePeriod = useCallback(
    (index) => {
      setActivePeriod(index);
    },
    [setActivePeriod],
  );

  const handleConnect = useCallback(() => {
    walletConnect.toggle();
  }, [walletConnect]);

  useEffect(() => {
    Promise.all([
      walletService.callContractMethod({
        contractName: 'STAKING',
        methodName: 'earlyUnstakeFee',
        contractAddress: contracts.params.STAKING[contracts.type].address,
        contractAbi: contracts.params.STAKING[contracts.type].abi,
      }),
    ]).then((res) => {
      setEarlyUnstakeFee(res[0] / 100);
    });
  }, [walletService]);

  return (
    <>
      <div className={s.l_stake}>
        <div className={cn(s.l_stake__info, s.l_stake__box)}>
          <div className={cn(s.l_stake__info__item, s.l_stake__box__item)}>
            <div className={cn(s.l_stake__info__item__value, 'text-orange')}>
              {convertQuackTokens(user.totalStaked)} QUACK
            </div>
            <div className={cn(s.l_stake__info__item__title, 'text-gray')}>Total Staked</div>
          </div>
          <div className={cn(s.l_stake__info__item, s.l_stake__box__item)}>
            <div className={cn(s.l_stake__info__item__value, 'text-orange')}>
              {user.level === 0 ? 'NONE' : items[user.level - 1]?.name.toUpperCase()}
            </div>
            <div className={cn(s.l_stake__info__item__title, 'text-gray')}>Your Level</div>
          </div>
        </div>
        <div className={s.l_stake__box}>
          <div className={cn(s.l_stake__periods, s.l_stake__box__item)}>
            <div className={s.l_stake__periods__items}>
              {periods.map((period, index) => (
                <div
                  key={period.days}
                  className={cn(s.l_stake__periods__item, {
                    [s.l_stake__periods__item_active]: index === activePeriod,
                  })}
                  onClick={() => handleChangePeriod(index)}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex={0}
                >
                  {isPeriodsLoading ? '-' : period.days} days
                </div>
              ))}
            </div>
            <Swiper slidesPerView={2.4} spaceBetween={8} className={s.l_stake__periods__items_sm}>
              {periods.map((period, index) => (
                <SwiperSlide key={period.days}>
                  <div
                    className={cn(s.l_stake__periods__item, {
                      [s.l_stake__periods__item_active]: index === activePeriod,
                    })}
                    onClick={() => handleChangePeriod(index)}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    <span>{isPeriodsLoading ? '-' : period.days} days</span>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className={s.l_stake__periods__content}>
              <div className={s.l_stake__periods__content__wrapper}>
                <div className={s.l_stake__periods__content__info}>
                  <div className={s.l_stake__periods__content__info__item}>
                    <span className="text-gray">Lock Period:</span>
                    <span className="text-orange">
                      {isPeriodsLoading ? '-' : periods[activePeriod].days} days
                    </span>
                  </div>
                  <div className={s.l_stake__periods__content__info__item}>
                    <span className="text-gray">Re-locks on registration:</span>
                    <span className="text-orange">
                      {periods[activePeriod].relocks ? 'Yes' : 'No'}
                    </span>
                  </div>
                  <div className={s.l_stake__periods__content__info__item}>
                    <span className="text-gray">
                      {periods[activePeriod].notEligible.length ? 'Not ' : ''}Eligible:
                    </span>
                    <span className="text-orange">
                      {periods[activePeriod].notEligible.length
                        ? `Level ${periods[activePeriod].notEligible.reduce((acc, item, index) => {
                            if (index) {
                              return `${acc} - ${item}`;
                            }
                            return acc + item;
                          }, '')}`
                        : `All`}
                    </span>
                  </div>
                  <div className={s.l_stake__periods__content__info__item}>
                    <span className="text-gray">Early unstake fee:</span>
                    <span className="text-orange">{earlyUnstakeFee}%</span>
                  </div>
                </div>
                <div className={s.l_stake__periods__content__apr}>
                  <div className={cn(s.l_stake__periods__content__apr__title, 'text-gray')}>
                    APR*
                  </div>
                  <div className={cn(s.l_stake__periods__content__apr__value, 'text-orange')}>
                    {isPeriodsLoading ? '-' : periods[activePeriod].apr}%
                  </div>
                </div>
              </div>
              <div className={s.l_stake__periods__content__text}>
                Once staked, you need to register for IDOs, so we can calculate the guaranteed
                allocation. Once registered, we lock your tokens, but you still can participate in
                other IDOs.
              </div>
            </div>
          </div>
          <div className={cn(s.l_stake__form, s.l_stake__box__item)}>
            <LevelsStakeInput
              type="stake"
              amount={stakeAmount}
              available={user.quackBalance}
              handleChangeAmount={handleChangeStakeAmount}
              handleMax={() => handleMax('stake')}
              error={stakeInputError || approveError !== ''}
              errorMsg={
                approveError !== ''
                  ? approveError
                  : 'The value exceeds the number of tokens in your wallet.'
              }
              btn={
                <>
                  {!user.address ? (
                    <Button
                      color="filled"
                      size="medium"
                      onClick={handleConnect}
                      className={s.l_stake__btn}
                    >
                      Connect
                    </Button>
                  ) : null}
                  {!isApproved && user.address ? (
                    <Button
                      color="filled"
                      size="medium"
                      onClick={handleApprove}
                      loading={isApproving}
                      disabled={stakeAmount === '' || stakeAmount === '0' || stakeInputError}
                      className={s.l_stake__btn}
                    >
                      Approve
                    </Button>
                  ) : null}
                  {isApproved && user.address ? (
                    <Button
                      color="filled"
                      size="medium"
                      onClick={handleStake}
                      className={s.l_stake__btn}
                      disabled={stakeAmount === '' || stakeAmount === '0' || stakeInputError}
                      loading={isLoading}
                    >
                      Stake
                    </Button>
                  ) : null}
                </>
              }
            />
            <LevelsStakeInput
              type="stake"
              amount={unstakeAmount}
              available={
                user.lockUpItems.length > 0 ? user.lockUpItems[activePeriod].amountLock : '0'
              }
              handleChangeAmount={handleChangeUnstakeAmount}
              handleMax={() => handleMax('unstake')}
              error={unstakeInputError}
              errorMsg="The value exceeds the number of staked tokens for selected period."
              btn={
                user.address ? (
                  <Button
                    color="filled"
                    size="medium"
                    onClick={handleUnstake}
                    disabled={unstakeAmount === '' || unstakeAmount === '0' || unstakeInputError}
                    loading={isLoading}
                    className={s.l_stake__btn}
                  >
                    Unstake
                  </Button>
                ) : (
                  <Button
                    color="filled"
                    size="medium"
                    onClick={handleConnect}
                    className={s.l_stake__btn}
                  >
                    Connect
                  </Button>
                )
              }
            />

            {showUnstakeWarning && (
              <>
                <div className={s.l_stake__unstake_data}>
                  {`Stake end time: 
        ${format(user.lockUpItems[activePeriod].lockEnd * 1000, 'dd.MM.yy')}`}
                </div>
                <div className={s.l_stake__unstake_error}>
                  {`If you unstake your tokens before the end of the lockup period you will be subject to
              a fee of ${earlyUnstakeFee}%`}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <CongratulationModal
        text={`The ${
          actionType === 'stake' ? 'staking' : 'unstaking'
        } process is successful. Please check your pool.`}
        visible={isVisibleCongratulationModal}
        onClose={handleCloseCongratulationModal}
        btnText="Confirm"
        btnAction={handleCloseCongratulationModal}
      />
    </>
  );
});

export default LevelsStake;
