import React from 'react';

import BigNumber from 'bignumber.js';
import { addMinutes, getTime } from 'date-fns';
import { validateYupSchema, withFormik, yupToFormErrors } from 'formik';
import * as Yup from 'yup';

import { is_production } from 'config';

import { IForm } from 'pages/CreateProject';

import { TOptionable } from 'types';

import Form from '../component';

export interface IValues {
  tokenContractAddress: string;
  ticker: string;
  tokenSupply: TOptionable<number>;
  tokenPool: TOptionable<number>;
  fundingToken: string;
  tokenIdoPrice: number | undefined;
  softCap: TOptionable<number>;
  hardCap: number | undefined;
  // marketCap: TOptionable<number>;
  // tokenCirculation: TOptionable<number>;
  saleStartTime: TOptionable<number> | Date;
  saleEndTime: TOptionable<number> | Date;
  // minContribution: TOptionable<number>;
  // maxContribution: TOptionable<number>;
  // tokenDecimals: TOptionable<number>;
  unsoldTokensAddress: string;
}

export interface ITokenPoolInfo {
  handleBack: () => void;
  handleUpdateData: (key: keyof IForm, data: IForm[typeof key]) => void;
  initialValues: IValues;
  projectType: string | undefined;
}

export type ITokenPoolInfoForm = Pick<ITokenPoolInfo, 'handleBack'>;

const TokenPoolInfo: React.FC<ITokenPoolInfo> = ({
  handleBack,
  handleUpdateData,
  initialValues,
  projectType,
}) => {
  const calculateMinSaleStartTime = (currentValue: any): boolean => {
    // return true;
    if (projectType === 'public' || projectType === 'inqubator') {
      // return addDays(new Date(), 16);
      return getTime(currentValue) > getTime(addMinutes(new Date(), is_production ? 21600 : 15));
    }
    return getTime(currentValue) > getTime(new Date());
  };
  const calculateMinSaleEndTime = (checkValue: any, currentValue: any): boolean => {
    // return true;
    if (projectType === 'public' || projectType === 'inqubator') {
      // return addHours(checkValue, 9);
      return getTime(currentValue) > getTime(addMinutes(new Date(), is_production ? 540 : 40));
    }
    return getTime(checkValue) > getTime(new Date());
  };
  const validateHardCap = (hardCap: any, softCap: any): boolean => {
    return new BigNumber(hardCap).isGreaterThan(softCap);
  };
  const FormWithFormik = withFormik<ITokenPoolInfoForm, IValues>({
    enableReinitialize: true,
    mapPropsToValues: () => initialValues,
    validate: async (values) => {
      const validationSchema = Yup.object().shape({
        tokenContractAddress: Yup.string().required('Token Contract Address is required'), // check for valid address
        ticker: Yup.string().required('Ticker is required'),
        tokenSupply: Yup.number()
          .min(1, 'Token Supply must be greater than 0')
          .required('Token Supply is required'),
        tokenPool: Yup.number().required('Token Pool Size is required').positive(),
        fundingToken: Yup.string().required('Funding Token is required'),
        tokenIdoPrice: Yup.number().required('Token IDO Price is required').positive(),
        // marketCap: Yup.number()
        //   .min(1, 'Initial market cap must be greater than 0')
        //   .required('Initial Market Cap is required'),
        // tokenCirculation: Yup.number().required('Initial Token Circulation is required'),
        softCap: Yup.number().required('SoftCap is required').positive(),
        hardCap: Yup.number()
          .required('HardCap is required')
          .positive()
          .test('is-greater', 'Hard cap must be greater than soft cap', () =>
            validateHardCap(values.hardCap, values.softCap),
          ),
        saleStartTime: Yup.date()
          .test(
            'is-greater',
            projectType === 'private'
              ? 'Sale Start Time should be more than current day'
              : 'Sale Start Time must be at least 16 days later than the current date',
            () => calculateMinSaleStartTime(values.saleStartTime),
          )
          .required('Sale Start Time is required'),
        saleEndTime: Yup.date()
          .test(
            'is-greater',
            projectType === 'private'
              ? 'Sale Start Time should be more than current day'
              : 'Sale End Time must be at least 9 hours later than the Sale Start Time',
            () => calculateMinSaleEndTime(values.saleStartTime, values.saleEndTime),
          )
          .required('Sale End Time is required'),
        // minContribution: Yup.number().required('Minimum Contribution is required'),
        // maxContribution: Yup.number()
        //   .min(
        //     values.minContribution || 0,
        //     'Minimum Contribution is less than minimum contribution',
        //   )
        //   .required('Minimum Contribution is required'),
        // tokenDecimals: Yup.number().required('Token decimals is required'),
        unsoldTokensAddress: Yup.string().required('Unsold Tokens Address is required'), // check for valid address
      });
      try {
        await validateYupSchema(values, validationSchema, true, values);
        return {};
      } catch (err: any) {
        return yupToFormErrors(err);
      }
    },

    handleSubmit: (values) => {
      handleUpdateData('tokenPoolInfo', values);
    },

    displayName: 'TokenPoolInfo',
  })(Form);
  return <FormWithFormik handleBack={handleBack} />;
};

export default TokenPoolInfo;
