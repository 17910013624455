export const menuLinks = {
  about: [
    { name: 'Docs', path: 'https://docs.richquack.com/' },
    { name: 'Team', path: 'https://www.richquack.com/team' },
    { name: 'News', path: 'https://news.richquack.com/' },
    { name: 'Github', path: 'https://github.richquack.com/' },
    { name: 'Bug Bounty', path: 'https://www.richquack.com/bugbounty' },
  ],
  product: [
    { name: 'Launchpad', path: '/' },
    { name: 'Voting', path: '/voting' },
    { name: 'Staking', path: '/staking' },
    { name: 'Start', path: '/start' },
  ],
  services: [
    { name: 'Influencer Program', path: 'https://www.richquack.com/influencer' },
    { name: 'Apply to Launch', path: 'https://www.richquack.com/apply' },
    { name: 'Community', path: 'https://www.richquack.com/community' },
    { name: 'FAQ', path: 'https://www.richquack.com/faq' },
  ],
};
