import { FC } from 'react';
import { Route, Routes as Switch } from 'react-router-dom';

import { page404, routes } from '..';

const RouteManager: FC = () => {
  const routeComponents = routes.map((route) => {
    if (Object.prototype.hasOwnProperty.call(route, 'children')) {
      return route.children?.map((child) => (
        <Route path={child.path} element={child.component} key={child.name} />
      ));
    }
    return <Route path={route.path} element={route.component} key={route.name} />;
  });
  return (
    <Switch>
      {routeComponents}
      <Route path={page404.path} element={page404.component} />
    </Switch>
  );
};

export default RouteManager;
