import { FC } from 'react';

import cn from 'classnames';

import styles from './Switch.module.scss';

interface ISwitchProps {
  className?: string;
  value: boolean;
  setValue: (foo: boolean) => void;
  color?: 'moon-sun' | 'light' | 'dark';
}

const Switch: FC<ISwitchProps> = ({ className, value, setValue, color = 'moon-sun' }) => {
  return (
    <label htmlFor="toggle" className={cn(styles.switch, className)}>
      <input
        id="toggle"
        className={styles.input}
        type="checkbox"
        checked={value}
        onChange={() => setValue(!value)}
      />
      <span className={cn(styles.inner, styles[color])}>
        <span className={styles.box} />
      </span>
    </label>
  );
};

export default Switch;
