import { FC, useCallback, useState } from 'react';

import { useMst } from 'store';

import cn from 'classnames';
import { VotingTable } from 'containers';

import { GoBackLink, Loading, Pagination, Search } from 'components';
import { filterItems } from 'utils/constants';

import { usePresaleSimple } from 'hooks';
import { IFilters } from 'types';

import { tableHead } from './mock';

import style from './Voting.module.scss';
import s from 'pages/Discover/Discover.module.scss';

// const DEFAULT_PROPOSAL_ID = 0;
const Voting: FC = () => {
  const { user } = useMst();
  // const [tableItems, setTableItems] = useState(items);
  // const { proposalInfo } = usePropsalInfo(DEFAULT_PROPOSAL_ID);
  const [filters, setFilters] = useState<IFilters>({
    status: 'voting,voting_failed',
    network: filterItems[1].defaultOption.value,
    type: filterItems[2].defaultOption.value,
    ordering: filterItems[3].defaultOption.value,
    page: 1,
  });
  const [presales, [prevLink, nextLink], , presalesLoading] = usePresaleSimple(
    user.address,
    '',
    '',
    filters,
  );

  const handleSearch = useCallback((str: string) => {
    console.log(str);
    // if (str === '') {
    //   setTableItems(items);
    //   return;
    // }
    // setTableItems(
    //   items.filter(
    //     (project) => project.name.substr(0, str.length).toLowerCase() === str.toLowerCase(),
    //   ),
    // );
  }, []);

  return (
    <div className={style.voting}>
      <GoBackLink link="/" text="Explore" />
      <h1 className={cn(style.voting__title, 'h1')}>Presale Voting</h1>
      <div className={style.voting__search}>
        <Search onChange={handleSearch} placeholder="Search for name or token" />
      </div>
      {presalesLoading ? (
        <Loading className={s.loader} />
      ) : (
        <VotingTable head={tableHead} presales={presales} />
      )}
      <Pagination
        filters={filters}
        setFilters={setFilters}
        prev={prevLink}
        next={nextLink}
        className={style.pagination}
      />
    </div>
  );
};

export default Voting;
