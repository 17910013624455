import { FC, PropsWithChildren } from 'react';

import { useMst } from '../../store';
import { observer } from 'mobx-react-lite';

import cn from 'classnames';
import Dialog from 'rc-dialog';

import { IModalProps } from 'types';

import s from './Modal.module.scss';

const Modal: FC<PropsWithChildren<IModalProps>> = observer(
  ({ className, visible, onClose, children }) => {
    const { theme } = useMst();

    return (
      <Dialog
        // transitionName="zoom"
        // maskTransitionName="fade"
        prefixCls="modal"
        zIndex={1000}
        destroyOnClose
        className={cn(s.modal_wrapper, theme.value, className)}
        closable={false}
        visible={visible}
        maskClosable
        onClose={onClose}
      >
        {children}
      </Dialog>
    );
  },
);

export default Modal;
