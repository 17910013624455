import { FC, useCallback } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';

import { observer } from 'mobx-react-lite';
import { ThemeEnum, useMst } from 'store';

import cn from 'classnames';

import { Button, CheckItem, Icon, Modal } from 'components';

import { useCheck } from 'hooks';
import { IModalProps } from 'types';

import { exchanges } from './mock';

import s from './BuyQuackModal.module.scss';

const BuyQuackModal: FC<IModalProps> = observer(({ visible, onClose }) => {
  const [activeIndex, handleClick] = useCheck(0);

  const { theme } = useMst();
  const handleBuyClick = useCallback(() => {
    window.open(exchanges[activeIndex].link);
    handleClick(0);
    onClose();
  }, [activeIndex, handleClick, onClose]);

  return (
    <Modal className={s.modal_wrapper} visible={visible} onClose={onClose}>
      <div className={s.buy_quack_wrapper}>
        <div className={s.title}>Buy Quack at</div>
        <div className={s.exchanges_wrapper}>
          <Scrollbar
            style={{
              width: '100%',
              height: '390px',
            }}
            trackYProps={{
              renderer: (props) => {
                const { elementRef, ...restProps } = props;
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    className={theme.value === ThemeEnum.dark ? s.trackY : cn(s.trackY, s.light)}
                  />
                );
              },
            }}
            thumbYProps={{
              renderer: (props) => {
                const { elementRef, ...restProps } = props;
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    className={theme.value === ThemeEnum.dark ? s.thumbY : cn(s.thumbY, s.light)}
                  />
                );
              },
            }}
          >
            {exchanges.map(({ name, icon }, index) => (
              <CheckItem
                key={name}
                isActive={activeIndex === index}
                index={index}
                onClick={handleClick}
                className={s.exchange_item}
              >
                <div className={s.exchange}>
                  <span>{name}</span>
                  <Icon icon={icon} size="big" className={s.icon} />
                </div>
              </CheckItem>
            ))}
          </Scrollbar>
        </div>
        <Button
          color="filled"
          isFullWidth
          disabled={activeIndex === null}
          className={s.btn}
          onClick={handleBuyClick}
        >
          Buy
        </Button>
      </div>
    </Modal>
  );
});

export default BuyQuackModal;
