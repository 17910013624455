import React from 'react';

import { getTime } from 'date-fns';
import { validateYupSchema, withFormik, yupToFormErrors } from 'formik';
import * as Yup from 'yup';

import { IForm } from 'pages/CreateProject';

import { TOptionable } from 'types';

import Form from '../component';

export interface IValues {
  listingPrice: number | undefined;
  listingTime: TOptionable<Date>;
  allocationPercent: number;
  lockedDays: TOptionable<number>;
  lockingProvider: string;
}

export interface ISwapPlan {
  handleBack: () => void;
  handleUpdateData: (key: keyof IForm, data: IForm[typeof key]) => void;
  initialValues: IValues;
  saleEndTime: TOptionable<number> | Date;
}

export type ISwapPlanForm = Pick<ISwapPlan, 'handleBack'>;

const SwapPlan: React.FC<ISwapPlan> = ({
  handleBack,
  handleUpdateData,
  initialValues,
  saleEndTime,
}) => {
  const calculateMinListingTime = (checkValue: any, currentValue: any): boolean => {
    // return true;
    return getTime(currentValue) > getTime(checkValue);
  };
  const FormWithFormik = withFormik<ISwapPlanForm, IValues>({
    enableReinitialize: true,
    mapPropsToValues: () => initialValues,
    validate: async (values) => {
      const validationSchema = Yup.object().shape({
        listingPrice: Yup.number().required('Listing Price is required'),
        allocationPercent: Yup.number()
          .max(90, 'Allocation Percent is greater than 90%')
          .required('Allocation Percent is required'),
        listingTime: Yup.date()
          .test('is-greater', 'Listing Time is should be greater than Sale End Time', () =>
            calculateMinListingTime(saleEndTime, values.listingTime),
          )
          .required("Listing Time can't be earlier than Sale End Time"),
        lockedDays: Yup.number().required('Lock Duration Days is required'),
        lockingProvider: Yup.string().required('Liquidity Locking Provider  is required'),
      });
      try {
        await validateYupSchema(values, validationSchema, true, values);
        return {};
      } catch (err: any) {
        return yupToFormErrors(err);
      }
    },

    handleSubmit: (values) => {
      handleUpdateData('swapPlan', values);
    },

    displayName: 'SwapPlan',
  })(Form);
  return <FormWithFormik handleBack={handleBack} />;
};

export default SwapPlan;
