import BigNumber from 'bignumber.js';
import Web3 from 'web3';

import { contracts } from 'config';
import { uniswapV2PairAbi } from 'config/abi';
import { ZERO_ADDRESS } from 'utils/constants';

export const getAddLiquidityData = async ({
  web3,
  presaleType,
  tokenAddress,
  tokenDecimals,
  presaleAddress,
}: {
  web3: Web3;
  presaleType: string;
  tokenAddress: string;
  tokenDecimals: number;
  presaleAddress: string;
}): Promise<{ tokenOutMin: string; usdtOutMin: string }> => {
  const USDT_ADDRESS = contracts.params.USDT[contracts.type].address;

  const pancakeFactoryContract = new web3.eth.Contract(
    contracts.params.PANCAKE_FACTORY[contracts.type].abi,
    contracts.params.PANCAKE_FACTORY[contracts.type].address,
  );

  const pairAddress = await pancakeFactoryContract.methods
    .getPair(USDT_ADDRESS, tokenAddress)
    .call();

  const lpContract = new web3.eth.Contract(uniswapV2PairAbi as any[], pairAddress);

  const presaleContractName = `PRESALE_${presaleType.toUpperCase()}`;
  const presaleContract = new web3.eth.Contract(
    contracts.params[presaleContractName][contracts.type].abi,
    presaleAddress,
  );

  const dexInfo = await presaleContract.methods.dexInfo().call();
  const intermediate = await presaleContract.methods.intermediate().call();

  const DENOMINATOR = 100;
  const paymentAmountBN = new BigNumber(
    new BigNumber(intermediate.raisedAmount).multipliedBy(dexInfo.liquidityPercentageAllocation),
  ).dividedBy(DENOMINATOR);
  const tokenAmountBN = new BigNumber(
    paymentAmountBN.multipliedBy(new BigNumber(10).pow(tokenDecimals)),
  ).dividedBy(dexInfo.listingPrice);
  if (pairAddress === ZERO_ADDRESS) {
    return {
      tokenOutMin: tokenAmountBN.toFixed(),
      usdtOutMin: paymentAmountBN.toFixed(),
    };
  }
  const { _reserve0, _reserve1 } = await lpContract.methods.getReserves().call();
  if (+_reserve0 === 0 && +_reserve1 === 0) {
    return {
      tokenOutMin: tokenAmountBN.toFixed(),
      usdtOutMin: paymentAmountBN.toFixed(),
    };
  }

  const token0Address: string = await lpContract.methods.token0().call();
  let tokenReserve;
  let usdtReserve;
  if (token0Address.toLowerCase() === tokenAddress) {
    tokenReserve = _reserve0;
    usdtReserve = _reserve1;
  } else {
    tokenReserve = _reserve1;
    usdtReserve = _reserve0;
  }
  const amountUsdtOptimalBN = tokenAmountBN.multipliedBy(usdtReserve).dividedBy(tokenReserve);
  if (amountUsdtOptimalBN.isLessThanOrEqualTo(paymentAmountBN)) {
    return {
      tokenOutMin: tokenAmountBN.toFixed(),
      usdtOutMin: amountUsdtOptimalBN.toFixed(),
    };
  }
  const amountTokenOptimalBN = paymentAmountBN.multipliedBy(tokenReserve).dividedBy(usdtReserve);

  if (amountTokenOptimalBN.isLessThanOrEqualTo(tokenAmountBN)) {
    return {
      tokenOutMin: amountTokenOptimalBN.toFixed(),
      usdtOutMin: paymentAmountBN.toFixed(),
    };
  }
  return {
    tokenOutMin: '0',
    usdtOutMin: '0',
  };
};
