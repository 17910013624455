import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import BigNumber from 'bignumber.js';
import cn from 'classnames';

import { Button, Progress, StatusLabel } from 'components';
import { convertToDaysLeft, getRegisterDate } from 'utils';

import { useProjectStatusTextSimple } from 'hooks';
import { IPresale, statusEnum } from 'types';

import usdtLogo from 'assets/img/icons/currencies/usdt.svg';
import userAvatar from 'assets/img/mock/avatar.png';

// import projectImg from 'assets/img/mock/project-img.png';
import s from './ProjectCard.module.scss';

interface IProjectCardProps {
  presale: IPresale;
  className?: string;
}

const ProjectCard: FC<IProjectCardProps> = observer(({ presale, className }) => {
  const { user } = useMst();
  const navigate = useNavigate();

  const statusText = useProjectStatusTextSimple(presale.status, presale.round, presale.stage);
  const [linkState, setLinkState] = useState<any>({
    link: `launchpad/${presale.id}`,
    title: 'DYOR',
  });

  const handleClickProjectCard = useCallback(() => {
    navigate(linkState.link);
  }, [linkState.link, navigate]);

  useEffect(() => {
    if (
      (presale.type === 'private' || presale.type === 'inqubator') &&
      user.address &&
      user.address.toLowerCase() === presale.creator.toLowerCase()
    ) {
      setLinkState({
        link: `launchpad/${presale.id}`,
        title: 'DYOR',
      });
    }

    if (presale.status === statusEnum.register && user.level === 0) {
      setLinkState({ link: '/levels', title: 'Stake for Levels' });
    }
    if (
      presale.status === statusEnum.register &&
      !presale.user.isUserRegistered &&
      presale.type !== 'private'
    ) {
      setLinkState({
        link: `launchpad/${presale.id}`,
        title: 'Register Now',
      });
    }
    if (
      presale.status === statusEnum.open &&
      user.level > 0 &&
      user.level < 4 &&
      presale.user.isUserWinLottery
    ) {
      setLinkState({
        link: `launchpad/${presale.id}`,
        title: 'Invest',
      });
    }
    if (presale.status === statusEnum.open && user.level >= 4 && presale.user.isUserRegistered) {
      setLinkState({
        link: `launchpad/${presale.id}`,
        title: 'Invest',
      });
    }
  }, [
    presale.creator,
    presale.id,
    presale.status,
    presale.title,
    presale.type,
    presale.user.isUserRegistered,
    presale.user.isUserWinLottery,
    user.address,
    user.level,
  ]);

  return (
    <div
      tabIndex={-1}
      onKeyDown={() => {}}
      role="button"
      onClick={handleClickProjectCard}
      className={cn(s.project_card_wrapper, className)}
    >
      <div className={s.img_wrapper}>
        <img className={s.project_img} src={presale.img} alt="projectImage" />
        <div className={s.status_wrapper}>
          <StatusLabel text={statusText[0]} />
        </div>
      </div>
      <div className={s.project_content_wrapper}>
        <div className={s.flex_item}>
          <div className={s.logos_wrapper}>
            <div className={s.avatar_wrapper}>
              <img className={s.user_avatar} src={userAvatar} alt="userAvatar" />
              <img className={s.currency} src={usdtLogo} alt="currency" />
            </div>
          </div>
          <div className={s.access}>{presale.type}</div>
          <div className={s.name}>{presale.title}</div>
          <div className={s.description}>{presale.description}</div>
        </div>
        <div className={s.flex_item}>
          {presale.status === statusEnum.upcoming && (
            <>
              <div className={s.subtitle}>
                {presale.type === 'private' ? 'Sale Opens in' : 'Registration Opens in'}
              </div>
              <div className={s.raised}>
                {presale.type === 'private'
                  ? getRegisterDate(presale.salesStart)
                  : getRegisterDate(presale.registrationOpens)}
              </div>
            </>
          )}
          {presale.status === statusEnum.register && (
            <>
              <div className={s.subtitle}>Registration Closed in</div>
              <div className={s.raised}>{getRegisterDate(presale.registrationClosed)}</div>
            </>
          )}
          {presale.status === statusEnum.registerClosed && (
            <>
              <div className={s.subtitle}>Sale Opens in</div>
              <div className={s.raised}>{getRegisterDate(presale.salesStart, false)}</div>
            </>
          )}
          {presale.status === statusEnum.open && (
            <>
              <div className={s.subtitle}>Days left</div>
              <div className={s.raised}>{getRegisterDate(presale.salesEnd, false)}</div>
            </>
          )}
          {(presale.status === statusEnum.completedSuccess ||
            presale.status === statusEnum.completedFail) && (
            <>
              <div className={s.subtitle}>Total Raised</div>
              <div className={s.raised}>
                {new BigNumber(presale.totalRaised).decimalPlaces(6, 1).toString()}
              </div>
            </>
          )}
          <div className={s.progress_wrapper}>
            <Progress
              totalRaised={+presale.totalRaised}
              all={+presale.hardCap}
              className={s.progress}
              name="Progress"
              titleClass={s.header}
            />
            <div className={s.progress_numbers}>
              {presale.totalRaised}/{presale.hardCap}
            </div>
            {presale.status === statusEnum.open && (
              <div className={s.progress_numbers}>{convertToDaysLeft(presale.salesEnd)}</div>
            )}
          </div>
          <div className={s.container}>
            {presale.type !== 'private' && (
              <div className={s.item}>
                {presale.status === statusEnum.upcoming && presale.type !== 'private' && (
                  <>
                    <div className={s.item_title}>Voters</div>
                    <div className={s.item_content}>{presale.totalVoters}</div>
                  </>
                )}
                {presale.status === statusEnum.register ||
                presale.status === statusEnum.registerClosed ? (
                  <>
                    <div className={s.item_title}>Registered</div>
                    <div className={s.item_content}>{presale.totalRegistered}</div>
                  </>
                ) : (
                  <>
                    <div className={s.item_title}>Bakers</div>
                    <div className={s.item_content}>{presale.totalRegistered}</div>
                  </>
                )}
              </div>
            )}
            <div className={cn(s.item, presale.type === 'private' && s.full_width)}>
              <div className={s.item_title}>Price per Token</div>
              <div className={s.item_content}>{presale.tokenPrice} USDT</div>
            </div>
            <div className={s.item}>
              <div className={s.item_title}>Access</div>
              <div className={s.item_content}>{presale.type}</div>
            </div>
            <div className={s.item}>
              {presale.status === statusEnum.completedFail ||
              presale.status === statusEnum.completedSuccess ? (
                <>
                  <div className={s.item_title}>ATH</div>
                  <div className={s.item_content}>-</div>
                </>
              ) : (
                <>
                  <div className={s.item_title}>Round</div>
                  <div className={s.item_content}>{presale.round}</div>
                </>
              )}
            </div>
          </div>
          {!user.address ? (
            <Button className={s.btn} color="filled" size="medium" isFullWidth>
              Connect Wallet
            </Button>
          ) : (
            <Button
              className={s.btn}
              color="filled"
              href={linkState.link}
              size="medium"
              isFullWidth
            >
              {linkState.title}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});

export default ProjectCard;
