import React from 'react';

import { withFormik } from 'formik';
import * as Yup from 'yup';

import { IForm } from 'pages/CreateProject';

import Form from '../component';

export interface IValues {
  story: string;
  features: string;
  howDo: string;
  marketingPlan: string;
  revenueGeneration: string;
  teamAndAdvisors: string;
  techStack: string;
  investors: string;
  tokenReleaseSchedule: string;
}

export interface IProjectDeepDive {
  handleBack: () => void;
  handleUpdateData: (key: keyof IForm, data: IForm[typeof key]) => void;
  initialValues: IValues;
  projectName: string;
}

export type IProjectDeepDiveForm = Pick<IProjectDeepDive, 'handleBack' | 'projectName'>;

const ProjectDeepDive: React.FC<IProjectDeepDive> = ({
  handleBack,
  handleUpdateData,
  initialValues,
  projectName,
}) => {
  const FormWithFormik = withFormik<IProjectDeepDiveForm, IValues>({
    enableReinitialize: true,
    mapPropsToValues: () => initialValues,
    validationSchema: Yup.object().shape({
      story: Yup.string().required('Story is required'),
      features: Yup.string().required('Features is required'),
      howDo: Yup.string().required('How we are goint to do it is required'),
      marketingPlan: Yup.string().required('marketing Plan is required'),
      revenueGeneration: Yup.string().required('Revenue Generation is required'),
      teamAndAdvisors: Yup.string().required('Team & Advisors is required'),
      techStack: Yup.string().required('Tech Stack is required'),
      investors: Yup.string().required('Investors is required'),
      tokenReleaseSchedule: Yup.string().required('Token Release Schedule is required'),
    }),

    handleSubmit: (values) => {
      handleUpdateData('projectDeepDive', values);
    },

    displayName: 'ProjectDeepDive',
  })(Form);
  return <FormWithFormik handleBack={handleBack} projectName={projectName} />;
};

export default ProjectDeepDive;
