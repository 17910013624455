import React from 'react';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import cn from 'classnames';
import { Field, Form, FormikProps } from 'formik';

import { Button, Input } from 'components';

import { IProjectCreatorInfoForm, IValues } from '../container';

import style from './ProjectCreatorInfo.module.scss';

const ProjectCreatorInfoForm: React.FC<FormikProps<IValues> & IProjectCreatorInfoForm> = observer(
  ({
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    handleBack,
    isEndStep,
    btnText,
    isLoading,
    isApprovingToken,
    isApprovingLink,
  }) => {
    const {
      user,
      modals: { walletConnect },
    } = useMst();

    const handleConnect = React.useCallback(() => {
      walletConnect.toggle();
    }, [walletConnect]);

    return (
      <Form name="ProjectCreatorInfo-form" onSubmit={handleSubmit}>
        <div className={cn('form__create_presale__row')}>
          <Field id="name" name="name">
            {() => (
              <Input
                name="name"
                color="dark"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="e.g. Satoshi"
                value={values.name || ''}
                title={<div className={cn('text-600 text-sm')}>Name</div>}
                subtitle={
                  <div className="text-gray text-sm text-italic">Real name or Pseudonym</div>
                }
                error={touched.name && errors.name ? errors.name : ''}
              />
            )}
          </Field>
          <Field id="email" name="email">
            {() => (
              <Input
                name="email"
                color="dark"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Your Email"
                value={values.email || ''}
                title={<div className={cn('text-600 text-sm')}>Email </div>}
                subtitle={
                  <div className="text-gray text-sm text-italic">
                    Working email that we can use to reach out
                  </div>
                }
                error={touched.email && errors.email ? errors.email : ''}
              />
            )}
          </Field>
        </div>
        <div className={cn('form__create_presale__row')}>
          <Field id="telegramUsername" name="telegramUsername">
            {() => (
              <Input
                name="telegramUsername"
                color="dark"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Your Telegram ID"
                value={values.telegramUsername || ''}
                title={<div className={cn('text-600 text-sm')}>Telegram</div>}
                subtitle={
                  <div className="text-gray text-sm text-italic">
                    Telegram ID that we can use for reach out.
                  </div>
                }
                error={
                  touched.telegramUsername && errors.telegramUsername ? errors.telegramUsername : ''
                }
              />
            )}
          </Field>
        </div>

        <div className={cn(style.pci_form__btns, 'form__create_presale__box')}>
          <div className="text-gray form__create_presale__text" />
          <div className="form__create_presale__btns">
            <Button
              className="form__create_presale__btns__item back"
              onClick={handleBack}
              size="medium"
            >
              Back
            </Button>
            {!isEndStep ? (
              <Button
                color={Object.values(errors).length ? 'disabled' : 'filled'}
                size="medium"
                type="submit"
                className="form__create_presale__btns__item"
              >
                Next
              </Button>
            ) : (
              <>
                {user.address ? (
                  <Button
                    color={Object.values(errors).length ? 'disabled' : 'filled'}
                    size="medium"
                    type="submit"
                    className="form__create_presale__btns__item"
                    loading={isLoading || isApprovingToken || isApprovingLink}
                  >
                    {btnText}
                  </Button>
                ) : (
                  <Button
                    color="filled"
                    className="form__create_presale__btns__item"
                    size="medium"
                    onClick={handleConnect}
                  >
                    Connect
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </Form>
    );
  },
);

export default ProjectCreatorInfoForm;
