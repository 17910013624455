import { FC } from 'react';
import { Link } from 'react-router-dom';

import { data } from './mock';

import s from './Info.module.scss';

const Info: FC = () => {
  return (
    <div className={s.info_wrapper}>
      <div className={s.title}>Presale Info</div>
      <div className={s.links}>
        <div className={s.label}>
          Website:{' '}
          <Link className={s.link} to="https://empire.com/">
            https://empire.com/
          </Link>
        </div>
        <div className={s.label}>
          Twitter:{' '}
          <Link className={s.link} to="https://twitter.com/binnanceempirecommunity">
            https://twitter.com/binnanceempirecommunity
          </Link>
        </div>
        <div className={s.label}>
          Telegram:{' '}
          <Link className={s.link} to="https://t.me/binnanceempirecommunity">
            https://t.me/binnanceempirecommunity
          </Link>
        </div>
      </div>
      <div className={s.label}>Start block: 13368625 (Approx: Sat 11 Dec 05:40)</div>
      <div className={s.label}>End block: 13397426 (Approx: Sun 12 Dec 05:40)</div>
      <div className={s.card_wrapper}>
        {data.map((item) => (
          <div key={item.label} className={s.info_card}>
            <div className={s.icon}>
              <img src={item.icon} alt={item.label} />
            </div>
            <div>
              <div className={s.card_value}>{item.value}</div>
              <div className={s.card_label}>{item.label}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Info;
