import { useCallback, useEffect, useState, VFC } from 'react';

import { useMst } from 'store';

import BigNumber from 'bignumber.js';
import cn from 'classnames';

import { contracts, levelsV1Link } from 'config';
import { convertQuackTokens } from 'utils';

import { useWalletConnectorContext, WalletService } from 'services';

import s from './LevelsPreview.module.scss';

const LevelsPreview: VFC = () => {
  const { walletService } = useWalletConnectorContext();
  const { quack } = useMst();
  const [totalStaked, setTotalStaked] = useState('0');
  const calculateTotalStaked = useCallback(async () => {
    const total = await walletService.callContractMethod({
      contractName: 'STAKING',
      methodName: 'totalStaked',
      contractAddress: contracts.params.STAKING[contracts.type].address,
      contractAbi: contracts.params.STAKING[contracts.type].abi,
    });
    return new BigNumber(WalletService.weiToEth(total, quack.decimals))
      .multipliedBy(quack.data.price)
      .toFixed(2);
  }, [quack.data.price, quack.decimals, walletService]);

  useEffect(() => {
    calculateTotalStaked().then((res) => {
      setTotalStaked(res);
    });
  }, [calculateTotalStaked]);

  return (
    <div className={s.l_preview}>
      <div className={s.l_preview__text}>
        <div className={s.l_preview__title}>Stake for LaunchPAD Participation</div>
        <div className={cn(s.l_preview__subtitle, 'text-gray')}>
          Pool presale participation for the Guaranteed Allocation model is broken down into 9
          Levels based on the number of QUACK you have staked.
          <a
            className={s.l_preview__subtitle_link}
            href={levelsV1Link}
            target="_blank"
            rel="noreferrer"
          >
            (Levels V1)
          </a>
        </div>
      </div>
      <div className={s.l_preview__box}>
        <div className={s.l_preview__box__title}>Total Value Staked</div>
        <div className={cn(s.l_preview__box__value, 'text-orange')}>
          ${convertQuackTokens(totalStaked)}
        </div>
      </div>
    </div>
  );
};

export default LevelsPreview;
