import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import cn from 'classnames';
import { differenceInDays, getTime } from 'date-fns';

import { Button } from 'components';
import { calculatePercentOfVotes } from 'utils';

import { IPresale } from 'types';

import { ReactComponent as DislikeImg } from 'assets/img/icons/dislike-gray.svg';
import { ReactComponent as LikeImg } from 'assets/img/icons/like-gray.svg';
import tableArrowImg from 'assets/img/icons/table-arrow.svg';

import style from './VotingItem.module.scss';

interface IProps {
  contentClassName: string;
  handleConnect: () => void;
  presale: IPresale;
}

const VotingItem: React.FC<IProps> = observer(({ contentClassName, handleConnect, presale }) => {
  const {
    user: { address },
  } = useMst();

  const [isOpen, setOpen] = React.useState(false);

  const handleToggleOpen = React.useCallback(
    (e) => {
      e.stopPropagation();
      setOpen(!isOpen);
    },
    [isOpen],
  );

  if (presale.type !== 'public') {
    return <></>;
  }
  if (presale.status !== 'voting' && presale.status !== 'voting failed') {
    return <></>;
  }

  return (
    <Link
      to={`/launchpad/${presale.id}`}
      className={cn(style.v_item, 'table__item', {
        table__item_active: isOpen,
      })}
    >
      <div className={cn(style.v_item__content, 'table__item__content', contentClassName)}>
        <div className={cn(style.v_item__name, 'text-md text-md-tablet table__item__name')}>
          <div className={cn(style.v_item__img, 'table__item__img')}>
            <img src={presale.img} alt="" />
          </div>
          <span className="text-ellipsis">{presale.title}</span>
        </div>
        <div className={cn(style.v_item__data, 'text-orange text-bold')}>
          {presale.tokenPrice} USDT
        </div>
        <div
          className={cn(style.v_item__data, 'hidden__tablet', 'text-orange text-bold text-upper')}
        >{`${presale.softCap} USDT`}</div>
        <div
          className={cn(style.v_item__data, 'hidden__tablet', 'text-orange text-bold text-upper')}
        >{`${presale.hardCap} USDT`}</div>
        <div className={cn(style.v_item__data, 'text-white')}>
          {presale.status !== 'voting failed' &&
          differenceInDays(getTime(presale.salesStart), new Date()) > 0 ? (
            <>{`${differenceInDays(getTime(presale.salesStart), new Date())} Days`}</>
          ) : (
            '-'
          )}
        </div>
        <div className={cn(style.v_item__data, 'hidden__mobile')}>
          {!address ? (
            <Button color="filled" size="medium" onClick={handleConnect}>
              Connect Wallet
            </Button>
          ) : (
            <>
              {presale.status !== 'voting failed' ? (
                <div className={style.v_item__voting}>
                  <div
                    className={cn(style.v_item__voting__item, style.v_item__voting__item__like, {
                      // [style.v_item__voting__item__like__active]: vote === 'like',
                    })}
                  >
                    <LikeImg className="cursor-pointer" />
                    <span className="text-green text-smd">{`${calculatePercentOfVotes(
                      presale.voters[0],
                      presale.totalVoters,
                    )}%`}</span>
                  </div>
                  <div
                    className={cn(style.v_item__voting__item, style.v_item__voting__item__dislike, {
                      // [style.v_item__voting__item__dislike__active]: vote === 'dislike',
                    })}
                  >
                    <DislikeImg className="cursor-pointer" />
                    <span className="text-red text-smd">{`${calculatePercentOfVotes(
                      presale.voters[1],
                      presale.totalVoters,
                    )}%`}</span>
                  </div>
                </div>
              ) : (
                <div className={style.v_item__voting_failed}>{presale.status}</div>
              )}
            </>
          )}
        </div>
        <div
          className="table__toggle"
          onClick={handleToggleOpen}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <img src={tableArrowImg} alt="" className="table__arrow" />
        </div>
      </div>
      <CSSTransition
        unmountOnExit
        mountOnEnter
        in={isOpen}
        addEndListener={(node, done) => {
          node.addEventListener('transitionend', done, false);
        }}
        classNames="show"
      >
        <div className={cn(style.v_item__content__hidden, 'table__item__content__hidden')}>
          <div className={cn(style.v_item__wrapper, 'table__item__wrapper')}>
            <div className="table__item__element">
              <div className="table__item__element__head text-sm">Softcap</div>
              <div className="table__item__element__content text-md text-orange text-bold">{`${presale.softCap} USDT`}</div>
            </div>
            <div className="table__item__element">
              <div className="table__item__element__head text-sm">Hardcap</div>
              <div className="table__item__element__content text-md text-orange text-bold">{`${presale.hardCap} USDT`}</div>
            </div>
          </div>
          <div className="table__item__wrapper">
            <div className="table__item__element table__item__element_mobile">
              <div className="table__item__element__head text-sm">Voting</div>
              <div className="table__item__element__content text-md text-orange text-bold">
                {!address ? (
                  <Button color="filled" size="medium" onClick={handleConnect}>
                    Connect Wallet
                  </Button>
                ) : (
                  <div className={style.v_item__voting}>
                    <div
                      className={cn(style.v_item__voting__item, style.v_item__voting__item__like, {
                        // [style.v_item__voting__item__like__active]: vote === 'like',
                      })}
                    >
                      <LikeImg className="cursor-pointer" />
                      <span className="text-green text-smd">
                        {calculatePercentOfVotes(presale.voters[0], presale.totalVoters)}%
                      </span>
                    </div>
                    <div
                      className={cn(
                        style.v_item__voting__item,
                        style.v_item__voting__item__dislike,
                        {
                          // [style.v_item__voting__item__dislike__active]: vote === 'dislike',
                        },
                      )}
                    >
                      <DislikeImg className="cursor-pointer" />
                      <span className="text-red text-smd">
                        {calculatePercentOfVotes(presale.voters[1], presale.totalVoters)}%
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </Link>
  );
});

export default VotingItem;
