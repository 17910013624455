import { SnapshotOut, types } from 'mobx-state-tree';

const QuackData = types.model('QuackData', {
  circulatingSupply: types.number,
  marketCap: types.number,
  maxSupply: types.number,
  price: types.number,
  totalSupply: types.number,
});
export type IQuackData = SnapshotOut<typeof QuackData>;

const QuackTokenModel = types
  .model({
    decimals: types.number,
    data: QuackData,
  })
  .actions((self) => ({
    setDecimals: (decimals: number) => {
      self.decimals = decimals;
    },
    setData: (data: IQuackData) => {
      self.data = data;
    },
  }));
export default QuackTokenModel;
