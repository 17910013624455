import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';

import cn from 'classnames';

import { TextArea } from 'components/Inputs';

import { socials } from './mock';

import arrow from 'assets/img/icons/arrow_right.svg';
import avatar from 'assets/img/mock/avatar.png';
import commentImg from 'assets/img/mock/comment_example.png';

import s from './Discuss.module.scss';

const Discuss: FC = () => {
  // TODO: after adding editor, make sure that there will be user images inside
  //  comments and implement AfterAllImagesLoaded(), smth like here:
  //  https://www.npmjs.com/package/react-on-images-loaded to re-render scrollbar height

  const [scrollHeight, setScrollHeight] = useState<number>(160);
  const scrollContentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const content = scrollContentRef.current;
    if (content && content.clientHeight) {
      setScrollHeight((content && content.clientHeight + 60) || 0);
    }
  }, [scrollHeight]);
  return (
    <div className={s.discuss_wrapper}>
      <div className={s.title}>Presale Info</div>
      <span>Discuss the presale token. Mention mints/proxies or dangerous code below</span>
      <div className={s.comments_wrapper}>
        <div className={cn(s.count, s.accent)}>4 comments</div>
        {/*  Put TEXT EDITOR here */}
        <TextArea
          placeholder="Join the discussion"
          className={s.editor}
          rows={1}
          onChange={() => {}}
        />
        <div className={s.login}>
          <span>Login with </span>
          {socials.map((item) => (
            <Link key={item.name} to={item.link} className={s.social}>
              <img src={item.icon} alt={item.name} />
            </Link>
          ))}
        </div>
        <div className={s.comments}>
          <Scrollbar
            id={s.comments}
            className={s.option_wrapper__scroll}
            style={{
              width: '100%',
              height: scrollHeight,
              maxHeight: '510px',
            }}
            trackYProps={{
              renderer: (props) => {
                const { elementRef, ...restProps } = props;
                return <div {...restProps} ref={elementRef} className={s.trackY} />;
              },
            }}
            thumbYProps={{
              renderer: (props) => {
                const { elementRef, ...restProps } = props;
                return <div {...restProps} ref={elementRef} className={s.thumbY} />;
              },
            }}
          >
            <div className={s.scroll_content} ref={scrollContentRef}>
              <div className={s.comment}>
                <div className={s.user}>
                  <div className={s.avatar}>
                    <img src={avatar} alt="avatar" />
                  </div>
                  <div>
                    <div className={cn(s.name, s.accent)}>Tom</div>
                    <span>a day ago</span>
                  </div>
                </div>
                <div className={s.content}>
                  <p>
                    <img src={commentImg} alt="" />
                  </p>
                  <p>
                    <a href="/">Yearnlab.com</a> Presale/ILO will take place on{' '}
                    <a href="/">PinkSale.finance</a>
                  </p>
                  <p>7th Dec-16:00UTC</p>
                  <p>Public listing price: 1 BNB = 13500</p>
                  <p>Liquidity = 10 years</p>
                  <p>
                    <a href="/">https://www.pinksale.financ...</a>
                  </p>
                </div>
                <div className={s.actions}>
                  <div className={s.replies}>
                    <span>12</span>
                    <img className={s.dropdown} src={arrow} alt="arrow" />
                  </div>
                  <div className={s.dot} />
                  <span>Reply</span>
                  <div className={s.dot} />
                  <span>Share</span>
                </div>
              </div>
            </div>
          </Scrollbar>
        </div>
      </div>
    </div>
  );
};

export default Discuss;
