export default [
  {
    inputs: [
      { internalType: 'address', name: 'factory', type: 'address' },
      { internalType: 'address', name: 'staking', type: 'address' },
      { internalType: 'address', name: 'usdt', type: 'address' },
      { internalType: 'address', name: 'dex', type: 'address' },
      { internalType: 'address', name: '_VRFCoordinator', type: 'address' },
      { internalType: 'address', name: '_LINK_ADDRESS', type: 'address' },
      { internalType: 'bytes32', name: '_keyHash', type: 'bytes32' },
      { internalType: 'uint256', name: '_fee', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  { anonymous: false, inputs: [], name: 'Approved', type: 'event' },
  { anonymous: false, inputs: [], name: 'Closed', type: 'event' },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'presale', type: 'address' }],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'payment', type: 'uint256' },
    ],
    name: 'Invested',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'investment', type: 'uint256' },
    ],
    name: 'InvestmentWithdrawn',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint256', name: 'amountLinks', type: 'uint256' }],
    name: 'LinksWithdrawn',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'amountEth', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'amountToken', type: 'uint256' },
    ],
    name: 'LiquidityAdded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint256', name: 'totalLps', type: 'uint256' }],
    name: 'LiquidityTokensClaimed',
    type: 'event',
  },
  { anonymous: false, inputs: [], name: 'LotteryResultsCalculated', type: 'event' },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'totalInvestments', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'unsoldTokens', type: 'uint256' },
    ],
    name: 'RaisedFundsClaimed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'user', type: 'address' }],
    name: 'Registered',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'claimedAmount', type: 'uint256' },
    ],
    name: 'TokensClaimed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint256', name: 'amountTokens', type: 'uint256' }],
    name: 'TokensWithdrawn',
    type: 'event',
  },
  {
    inputs: [],
    name: 'DEX',
    outputs: [{ internalType: 'contract IRouter', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'FACTORY',
    outputs: [{ internalType: 'contract IPresaleFactory', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'STAKING',
    outputs: [{ internalType: 'contract IStaking', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'USDT',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'tokenOutMin', type: 'uint256' },
      { internalType: 'uint256', name: 'usdtOutMin', type: 'uint256' },
    ],
    name: 'addLiquidity',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: 'yes', type: 'bool' }],
    name: 'approve',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'calculateLotteryResults',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'claimLps', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [],
    name: 'claimRaisedFunds',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'claimTokens', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [],
    name: 'closePresale',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'dexInfo',
    outputs: [
      { internalType: 'uint256', name: 'listingPrice', type: 'uint256' },
      { internalType: 'uint256', name: 'lpTokensLockDurationInDays', type: 'uint256' },
      { internalType: 'uint8', name: 'liquidityPercentageAllocation', type: 'uint8' },
      { internalType: 'uint256', name: 'liquidityAllocationTime', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'generalInfo',
    outputs: [
      { internalType: 'address', name: 'creator', type: 'address' },
      { internalType: 'address', name: 'tokenAddress', type: 'address' },
      { internalType: 'uint256', name: 'tokenPrice', type: 'uint256' },
      { internalType: 'uint256', name: 'hardCap', type: 'uint256' },
      { internalType: 'uint256', name: 'softCap', type: 'uint256' },
      { internalType: 'uint256', name: 'openTime', type: 'uint256' },
      { internalType: 'uint256', name: 'closeTime', type: 'uint256' },
      { internalType: 'address', name: 'unsoldTokenToAddress', type: 'address' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getBreakNumber',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCloseTimestamps',
    outputs: [
      { internalType: 'uint256', name: 'moonEnd', type: 'uint256' },
      { internalType: 'uint256', name: 'diamondEnd', type: 'uint256' },
      { internalType: 'uint256', name: 'paperEnd', type: 'uint256' },
      { internalType: 'uint256[4]', name: 'stagesEnd', type: 'uint256[4]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'getMaxInvestment',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getRaisedAmount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getRound',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getStage',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getTotalRegistered',
    outputs: [{ internalType: 'uint256', name: 'participants', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'creator', type: 'address' },
          { internalType: 'address', name: 'tokenAddress', type: 'address' },
          { internalType: 'uint256', name: 'tokenPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'hardCap', type: 'uint256' },
          { internalType: 'uint256', name: 'softCap', type: 'uint256' },
          { internalType: 'uint256', name: 'openTime', type: 'uint256' },
          { internalType: 'uint256', name: 'closeTime', type: 'uint256' },
          { internalType: 'address', name: 'unsoldTokenToAddress', type: 'address' },
        ],
        internalType: 'struct IStructs.PresaleInfo',
        name: '_info',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'uint256', name: 'listingPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'lpTokensLockDurationInDays', type: 'uint256' },
          { internalType: 'uint8', name: 'liquidityPercentageAllocation', type: 'uint8' },
          { internalType: 'uint256', name: 'liquidityAllocationTime', type: 'uint256' },
        ],
        internalType: 'struct IStructs.PresaleDexInfo',
        name: '_dexInfo',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'uint8', name: 'vestingPerc1', type: 'uint8' },
          { internalType: 'uint8', name: 'vestingPerc2', type: 'uint8' },
          { internalType: 'uint256', name: 'vestingPeriod', type: 'uint256' },
        ],
        internalType: 'struct IStructs.VestingInfo',
        name: '_vestInfo',
        type: 'tuple',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'intermediate',
    outputs: [
      { internalType: 'bool', name: 'initialized', type: 'bool' },
      { internalType: 'bool', name: 'withdrawedFunds', type: 'bool' },
      { internalType: 'bool', name: 'approved', type: 'bool' },
      { internalType: 'bool', name: 'approveCalled', type: 'bool' },
      { internalType: 'address', name: 'lpAddress', type: 'address' },
      { internalType: 'uint256', name: 'usdToLiq', type: 'uint256' },
      { internalType: 'uint256', name: 'lpUnlockTime', type: 'uint256' },
      { internalType: 'uint256', name: 'tokensForSaleLeft', type: 'uint256' },
      { internalType: 'uint256', name: 'tokensForLiquidityLeft', type: 'uint256' },
      { internalType: 'uint256', name: 'raisedAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'eachPoolShare', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'payAmount', type: 'uint256' }],
    name: 'invest',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'investments',
    outputs: [
      { internalType: 'uint256', name: 'amountEth', type: 'uint256' },
      { internalType: 'uint256', name: 'amountTokens', type: 'uint256' },
      { internalType: 'uint256', name: 'amountClaimed', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'levelsCounter',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'lotteryWhitelist',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'randomRequested',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'requestId', type: 'bytes32' },
      { internalType: 'uint256', name: 'randomness', type: 'uint256' },
    ],
    name: 'rawFulfillRandomness',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'register', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'registerLevels',
    outputs: [
      { internalType: 'uint256', name: 'level', type: 'uint256' },
      { internalType: 'bool', name: 'lock', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint8', name: '', type: 'uint8' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'roundTokenAllocation',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'vestingInfo',
    outputs: [
      { internalType: 'uint8', name: 'vestingPerc1', type: 'uint8' },
      { internalType: 'uint8', name: 'vestingPerc2', type: 'uint8' },
      { internalType: 'uint256', name: 'vestingPeriod', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'withdrawInvestment',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'withdrawTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
