import {
  CreateProject,
  Discover,
  Levels,
  Page404,
  Project,
  ProjectType,
  Staking,
  StartProject,
  Voting,
} from 'pages';

import menuPools from '../assets/img/icons/menu_pools.svg';
import menuStaking from '../assets/img/icons/menu_staking.svg';
import menuVoting from '../assets/img/icons/menu_voting.svg';

export { default as RouteManager } from './RouteManager';

export const routes = [
  {
    name: 'CreateProject',
    path: '/start/create/:type',
    component: <CreateProject />,
  },
  {
    name: 'ProjectType',
    path: '/start/type',
    component: <ProjectType />,
  },
  // {
  //   name: 'Main',
  //   path: '/',
  //   component: <Landing />,
  // },
  // {
  //   name: 'ProjectContribute',
  //   path: '/project/:id/contribute',
  //   component: <ProjectContribute />,
  // },
  // {
  //   name: 'ProjectTemp',
  //   path: '/launchpad/earthling',
  //   component: <Project />,
  // },
  {
    name: 'Project',
    path: '/launchpad/:presaleId/',
    component: <Project />,
  },
  {
    name: 'LaunchPAD',
    path: '/pools',
    props: {
      menu: true,
      icon: menuPools,
      external: false,
    },
    children: [
      {
        name: 'Discover',
        path: '/',
        component: <Discover />,
      },
      {
        name: 'Start Raising',
        path: '/start',
        component: <StartProject />,
      },
      {
        name: 'Levels',
        path: '/levels',
        component: <Levels />,
      },
    ],
  },
  {
    name: 'Voting',
    path: '/voting',
    component: <Voting />,
    props: {
      menu: true,
      icon: menuVoting,
      external: false,
    },
  },
  {
    name: 'Staking',
    path: '/v1staking',
    props: {
      menu: true,
      icon: menuStaking,
      external: true,
    },
    children: [
      {
        name: 'Staking V1',
        path: '/v1staking',
        component: <Staking />,
      },
      {
        name: 'Staking V2',
        path: 'https://staking.richquack.com/',
      },
    ],
  },
  // {
  //   name: 'Staking',
  //   path: '/staking',
  //   component: <Staking />,
  //   props: {
  //     menu: false,
  //     icon: menuStaking,
  //     external: false,
  //   },
  // },
];
export const page404 = {
  name: 'page404',
  path: '*',
  component: <Page404 />,
};
