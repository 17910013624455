import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import cn from 'classnames';

import { Button, Icon } from 'components';
import { convertNumberReadableForm, convertQuackTokens, convertQuackUsdPrice } from 'utils';
import { wallets } from 'utils/constants';

import { useModal } from 'hooks';

import BuyQuackModal from './BuyQuackModal';
import { menuLinks } from './mock';

import logoIcon from 'assets/img/icons/logo.png';

import s from './Footer.module.scss';

const Footer: FC = observer(() => {
  const { user, sidebar, quack } = useMst();
  const [walletLogo, setWalletLogo] = useState<string>('');
  const [isBuyModalOpen, handleOpenBuyModal, handleCloseBuyModal] = useModal(false);

  useEffect(() => {
    if (user.address) {
      const currentWallet = wallets.find(
        (wallet) => wallet.providerName === localStorage.quack_wallet,
      );
      setWalletLogo(currentWallet ? currentWallet.icon : '');
    } else {
      setWalletLogo('');
    }
  }, [user.address]);

  return (
    <footer className={cn(s.footer_wrapper, { [s.sidebar]: sidebar.isOpen })}>
      <div className={s.container}>
        {/* RCQ INFO */}
        <div className={s.rcq_wrapper}>
          <div className={cn(s.rcq_col, s.quack)}>
            <div className={s.rcq_logo}>
              <div>
                <img src={logoIcon} alt="logo" width="40" height="40" />
              </div>
              <div style={{ marginLeft: 10 }}>
                <div className={s.subtitle}>$QUACK</div>
                <div className={s.rcq_amount}>${convertQuackUsdPrice(quack.data.price)}</div>
              </div>
            </div>
            <div className={s.rcq_actions}>
              <Icon icon={walletLogo} size="big" />
              <Button className={s.wallet_btn} color="filled" onClick={handleOpenBuyModal}>
                Buy $QUACK
              </Button>
            </div>
          </div>
          <div className={cn(s.rcq_col, s.summary)}>
            <div className={s.rcq_summary_item}>
              <div className={s.title}>Max Supply</div>
              <div className={s.description}>{convertQuackTokens(quack.data.maxSupply, false)}</div>
            </div>
            <div className={s.rcq_summary_item}>
              <div className={s.title}>Total Supply</div>
              <div className={s.description}>
                {convertQuackTokens(quack.data.totalSupply, false)}
              </div>
            </div>
            <div className={s.rcq_summary_item}>
              <div className={s.title}>Circulating Supply</div>
              <div className={s.description}>
                {convertQuackTokens(quack.data.circulatingSupply, false)}
              </div>
            </div>
            <div className={s.rcq_summary_item}>
              <div className={s.title}>Market Cap</div>
              <div className={s.description}>
                ${convertNumberReadableForm(quack.data.marketCap)}
              </div>
            </div>
          </div>
        </div>
        {/* FOOTER MENU */}
        <div className={s.menu_wrapper}>
          <div className={s.menu_item}>
            <div className={s.subtitle}>About</div>
            {menuLinks.about.map((item) => (
              <a
                key={item.name}
                className={s.menu_link}
                href={item.path}
                target="_blank"
                rel="noreferrer"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className={s.menu_item}>
            <div className={s.subtitle}>Product</div>
            {menuLinks.product.map((item) => (
              <Link
                key={item.name}
                className={s.menu_link}
                to={item.path}
                target="_blank"
                rel="noreferrer"
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className={s.menu_item}>
            <div className={s.subtitle}>Service</div>
            {menuLinks.services.map((item) => (
              <a
                key={item.name}
                className={s.menu_link}
                href={item.path}
                target="_blank"
                rel="noreferrer"
              >
                {item.name}
              </a>
            ))}
          </div>
        </div>
      </div>
      <BuyQuackModal visible={isBuyModalOpen} onClose={handleCloseBuyModal} />
    </footer>
  );
});

export default Footer;
