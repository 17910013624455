import React from 'react';

import cn from 'classnames';
import { Field, Form, FormikProps } from 'formik';

import { Button, Input } from 'components';

import { IProjectDeepDiveForm, IValues } from '../container';

import style from './ProjectDeepDive.module.scss';

const ProjectDeepDiveForm: React.FC<FormikProps<IValues> & IProjectDeepDiveForm> = ({
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  touched,
  handleBack,
  values,
  projectName,
}) => {
  return (
    <Form name="project-info-form" onSubmit={handleSubmit} className={style.pdd_form}>
      <div className={cn(style.pdd_form__row)}>
        <div className={style.pdd_form__row__title}>The Story behind {projectName}?</div>
        <Field id="story" name="story">
          {() => (
            <Input
              name="story"
              color="dark"
              onChange={handleChange}
              component="textarea"
              rows={7}
              onBlur={handleBlur}
              placeholder="Write Here"
              value={values.story}
              title={
                <div className={cn(style.d_form__input__label, 'text-gray text-sm text-italic')}>
                  Please write detailed brief introduction about your project.
                </div>
              }
              error={touched.story && errors.story ? errors.story : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.pdd_form__row)}>
        <div className={style.pdd_form__row__title}>Features</div>
        <Field id="features" name="features">
          {() => (
            <Input
              name="features"
              color="dark"
              onChange={handleChange}
              component="textarea"
              rows={4}
              max={140}
              onBlur={handleBlur}
              placeholder="Write Here"
              value={values.features}
              title={
                <div className={cn(style.d_form__input__label, 'text-gray text-sm text-italic')}>
                  Description of your project / mission statement. This will be displayed in
                  Discover page.
                </div>
              }
              error={touched.features && errors.features ? errors.features : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.pdd_form__row)}>
        <div className={style.pdd_form__row__title}>How we are going to do it?</div>
        <Field id="howDo" name="howDo">
          {() => (
            <Input
              name="howDo"
              color="dark"
              onChange={handleChange}
              component="textarea"
              rows={4}
              onBlur={handleBlur}
              placeholder="Write Here"
              value={values.howDo}
              title={
                <div className={cn(style.d_form__input__label, 'text-gray text-sm text-italic')}>
                  Full Project Road Map with Timeline
                </div>
              }
              error={touched.howDo && errors.howDo ? errors.howDo : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.pdd_form__row)}>
        <div className={style.pdd_form__row__title}>Marketing Plan</div>
        <Field id="marketingPlan" name="marketingPlan">
          {() => (
            <Input
              name="marketingPlan"
              color="dark"
              onChange={handleChange}
              component="textarea"
              rows={4}
              onBlur={handleBlur}
              placeholder="Write Here"
              value={values.marketingPlan}
              title={
                <div className={cn(style.d_form__input__label, 'text-gray text-sm text-italic')}>
                  What is your go to market strategy? What have you done so far? Whats your branding
                  and positioning in the market? What&apos;s your plan to acquire users in the
                  future?
                </div>
              }
              error={touched.marketingPlan && errors.marketingPlan ? errors.marketingPlan : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.pdd_form__row)}>
        <div className={style.pdd_form__row__title}>Revenue Generation</div>
        <Field id="revenueGeneration" name="revenueGeneration">
          {() => (
            <Input
              name="revenueGeneration"
              color="dark"
              onChange={handleChange}
              component="textarea"
              rows={2}
              onBlur={handleBlur}
              placeholder="Write Here"
              value={values.revenueGeneration}
              title={
                <div className={cn(style.d_form__input__label, 'text-gray text-sm text-italic')}>
                  How are you going to generate revenue for the project?
                </div>
              }
              error={
                touched.revenueGeneration && errors.revenueGeneration
                  ? errors.revenueGeneration
                  : ''
              }
            />
          )}
        </Field>
      </div>
      <div className={cn(style.pdd_form__row)}>
        <div className={style.pdd_form__row__title}>Team & Advisors</div>
        <Field id="teamAndAdvisors" name="teamAndAdvisors">
          {() => (
            <Input
              name="teamAndAdvisors"
              color="dark"
              onChange={handleChange}
              component="textarea"
              rows={4}
              onBlur={handleBlur}
              placeholder="Write Here"
              value={values.teamAndAdvisors}
              title={
                <div className={cn(style.d_form__input__label, 'text-gray text-sm text-italic')}>
                  Profile of your team & advisor, with their elevator pitch.
                </div>
              }
              error={
                touched.teamAndAdvisors && errors.teamAndAdvisors ? errors.teamAndAdvisors : ''
              }
            />
          )}
        </Field>
      </div>
      <div className={cn(style.pdd_form__row)}>
        <div className={style.pdd_form__row__title}>Tech Stack</div>
        <Field id="techStack" name="techStack">
          {() => (
            <Input
              name="techStack"
              color="dark"
              onChange={handleChange}
              component="textarea"
              rows={4}
              onBlur={handleBlur}
              placeholder="Write Here"
              value={values.techStack}
              title={
                <div className={cn(style.d_form__input__label, 'text-gray text-sm text-italic')}>
                  What technology you use for development and what tech were created by the project?
                </div>
              }
              error={touched.techStack && errors.techStack ? errors.techStack : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.pdd_form__row)}>
        <div className={style.pdd_form__row__title}>Investors </div>
        <Field id="investors" name="investors">
          {() => (
            <Input
              name="investors"
              color="dark"
              onChange={handleChange}
              component="textarea"
              rows={4}
              onBlur={handleBlur}
              placeholder="Write Here"
              value={values.investors}
              title={
                <div className={cn(style.d_form__input__label, 'text-gray text-sm text-italic')}>
                  Display list of your investors here.
                </div>
              }
              error={touched.investors && errors.investors ? errors.investors : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.pdd_form__row)}>
        <div className={style.pdd_form__row__title}>Token Release Schedule</div>
        <Field id="tokenReleaseSchedule" name="tokenReleaseSchedule">
          {() => (
            <Input
              name="tokenReleaseSchedule"
              color="dark"
              onChange={handleChange}
              component="textarea"
              rows={4}
              onBlur={handleBlur}
              placeholder="Write Here"
              value={values.tokenReleaseSchedule}
              title={
                <div className={cn(style.d_form__input__label, 'text-gray text-sm text-italic')}>
                  Display graph or write down your token release schedule.
                </div>
              }
              error={
                touched.tokenReleaseSchedule && errors.tokenReleaseSchedule
                  ? errors.tokenReleaseSchedule
                  : ''
              }
            />
          )}
        </Field>
      </div>
      <div className="form__create_presale__box">
        <div className="text-gray form__create_presale__text" />
        <div className="form__create_presale__btns">
          <Button
            className="form__create_presale__btns__item back"
            onClick={handleBack}
            size="medium"
          >
            Back
          </Button>
          <Button
            color={Object.values(errors).length ? 'disabled' : 'filled'}
            size="medium"
            type="submit"
            className="form__create_presale__btns__item"
          >
            Next
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ProjectDeepDiveForm;
