/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';

import { contracts } from 'config';
import { getValueWithDecimals } from 'utils/getValueWithDecimals';

import { useWalletConnectorContext } from 'services';
import { TNullable } from 'types';

const useApprove = ({
  tokenName,
  tokenAddress,
  approvedContractName,
  amount,
  walletAddress,
  approvedContractAddress,
}: {
  tokenName: string;
  tokenAddress: string;
  approvedContractName: string;
  amount: string;
  walletAddress: TNullable<string>;
  approvedContractAddress?: string | undefined;
}): [boolean, boolean, () => void, string] => {
  const { walletService } = useWalletConnectorContext();

  const [isApproved, setApproved] = useState(false);
  const [isApproving, setApproving] = useState(false);
  const [error, setError] = useState('');

  const handleApprove = useCallback(async () => {
    setApproving(true);
    const tokenDecimals = await walletService.getTokenDecimals(tokenAddress);
    const valueWithDecimals = getValueWithDecimals(amount, tokenDecimals);
    walletService
      .approveToken({
        contractName: tokenName,
        contractAddress: tokenAddress,
        amountToApprove: valueWithDecimals,
        approvedAddress:
          approvedContractAddress ||
          contracts.params?.[approvedContractName][contracts.type].address,
      })
      .then(() => {
        setApproved(true);
      })
      .catch((err: any) => {
        console.log('err approve stake', err);
        setError(err.message);
        setApproved(false);
      })
      .finally(() => {
        setApproving(false);
      });
  }, [
    walletService,
    tokenAddress,
    amount,
    tokenName,
    approvedContractAddress,
    approvedContractName,
  ]);

  useEffect(() => {
    if (walletAddress) {
      walletService
        .checkTokenAllowance({
          contractName: tokenName,
          contractAddress: tokenAddress,
          approvedAddress:
            approvedContractAddress ||
            contracts.params?.[approvedContractName][contracts.type].address,
          amount,
        })
        .then((res) => {
          setApproved(res);
        })
        .catch((err) => {
          setApproved(false);
          console.log('check approve stake modal', err);
        });
    }
  }, [
    walletService,
    amount,
    approvedContractName,
    tokenName,
    walletAddress,
    tokenAddress,
    approvedContractAddress,
  ]);

  return [isApproved, isApproving, handleApprove, error];
};

export default useApprove;
