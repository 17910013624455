import React from 'react';

import cn from 'classnames';

import { project } from './mock';

import style from './ProjectInfo.module.scss';

interface IProjectInfo {
  preview: string;
  name: string;
  story?: string;
}

const ProjectInfo: React.FC<IProjectInfo> = ({ preview, name, story }) => {
  return (
    <div className={style.p_info}>
      <div className={style.p_info__preview}>
        <img src={preview} alt={name} />
      </div>
      <div className={style.p_info__box}>
        {story ? (
          <div className={cn(style.p_info__text, 'text-gray')}>
            <div dangerouslySetInnerHTML={{ __html: story }} />
          </div>
        ) : (
          <>
            {project.map((item) => (
              <div key={item.title} className="p_info__block">
                {item.title && <div className="p_info__title text-md text-600">{item.title}</div>}
                {item.beforeImg &&
                  item.beforeImg.map((img) => (
                    <div key={img} className="p_info__img">
                      <img src={img} alt={item.title || 'earthling'} />
                    </div>
                  ))}
                {item.text && item.text}
                {item.img &&
                  item.img.map((img) => (
                    <div key={img} className="p_info__img">
                      <img src={img} alt={item.title || 'earthling'} />
                    </div>
                  ))}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectInfo;
