import React from 'react';

import { withFormik } from 'formik';
import * as Yup from 'yup';

import { IForm } from 'pages/CreateProject';

import { TOptionable } from 'types';

import Form from '../component';

export interface IValues {
  releaseIdo: TOptionable<number>;
  vestingSchedule: string;
  vestingPercentage: TOptionable<number>;
}

export interface ITokenVesting {
  handleBack: () => void;
  handleUpdateData: (key: keyof IForm, data: IForm[typeof key]) => void;
  initialValues: IValues;
}

export type ITokenVestingForm = Pick<ITokenVesting, 'handleBack'>;

const TokenVesting: React.FC<ITokenVesting> = ({ handleBack, handleUpdateData, initialValues }) => {
  const FormWithFormik = withFormik<ITokenVestingForm, IValues>({
    enableReinitialize: true,
    mapPropsToValues: () => initialValues,
    validationSchema: Yup.object().shape({
      releaseIdo: Yup.number().max(100, 'more than 100%').required('Vesting Schedule is required'),
      vestingSchedule: Yup.string().required('Vesting Schedule is required'),
      vestingPercentage: Yup.number()
        .max(100, 'more than 100%')
        .required('Vesting Percentage is required'),
    }),

    handleSubmit: (values) => {
      handleUpdateData('tokenVesting', values);
    },

    displayName: 'TokenVesting',
  })(Form);
  return <FormWithFormik handleBack={handleBack} />;
};

export default TokenVesting;
