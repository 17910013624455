import React from 'react';

import cn from 'classnames';
import { addDays, addMinutes } from 'date-fns';
import { Field, Form, FormikProps } from 'formik';

import { Button, Input } from 'components';

import { ITokenLockingReportsForm, IValues } from '../container';

import style from './TokenLockingReports.module.scss';

const TokenLockingReportsForm: React.FC<FormikProps<IValues> & ITokenLockingReportsForm> = ({
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  touched,
  values,
  handleBack,
  setFieldValue,
}) => {
  return (
    <Form name="TokenLockingReports-form" onSubmit={handleSubmit}>
      <div className={cn(style.tlr_form__row, 'form__create_presale__row')}>
        <Field id="lockingProvider" name="lockingProvider">
          {() => (
            <Input
              name="lockingProvider"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="e.g. Team.Finance"
              value={values.lockingProvider || ''}
              title={<div className={cn('text-600 text-sm')}>Token Locking Provider</div>}
              error={
                touched.lockingProvider && errors.lockingProvider ? errors.lockingProvider : ''
              }
            />
          )}
        </Field>
        <Field id="lockPurpose" name="lockPurpose">
          {() => (
            <Input
              name="lockPurpose"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="e.g. Ecosystem Rewards"
              value={values.lockPurpose || ''}
              title={<div className={cn('text-600 text-sm')}>Token Lock Purpose</div>}
              error={touched.lockPurpose && errors.lockPurpose ? errors.lockPurpose : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.tlr_form__row, 'form__create_presale__row')}>
        <Field id="expiryDate" name="expiryDate">
          {() => (
            <Input
              component="date"
              minDate={addMinutes(addDays(new Date(), 16), 10)}
              value={values.expiryDate || new Date()}
              onBlur={handleBlur('expiryDate')}
              onChange={(value) => setFieldValue('expiryDate', value)}
              title={<div className={cn('text-600 text-sm')}>Liquidity Locking Expiry Date</div>}
              color="dark"
              error={touched.expiryDate && errors.expiryDate ? errors.expiryDate : ''}
            />
          )}
        </Field>
        <Field id="proofLink" name="proofLink">
          {() => (
            <Input
              name="proofLink"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="e.g. https://www.RichQuack.com"
              value={values.proofLink || ''}
              title={<div className={cn('text-600 text-sm')}>Link to proof your Token Lock</div>}
              error={touched.proofLink && errors.proofLink ? errors.proofLink : ''}
            />
          )}
        </Field>
      </div>

      <div className={cn(style.tlr_form__btns, 'form__create_presale__box')}>
        <div className="text-gray form__create_presale__text" />
        <div className="form__create_presale__btns">
          <Button className="form__create_presale__btns__item back" onClick={handleBack}>
            Back
          </Button>
          <Button
            color={Object.values(errors).length ? 'disabled' : 'filled'}
            type="submit"
            className="form__create_presale__btns__item"
          >
            Next
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default TokenLockingReportsForm;
