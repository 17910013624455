import { FC } from 'react';

import cn from 'classnames';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { tokenomics } from './mock';

import bg from 'assets/img/landing/ornament_5.png';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import s from './Tokenomics.module.scss';

SwiperCore.use([Pagination]);

const Tokenomics: FC = () => {
  return (
    <div className={s.tokenomics_wrapper}>
      <div className={s.bg}>
        <img src={bg} alt="ornament" />
      </div>
      <div className={s.title}>QUACK TOKENOMICS</div>
      <Swiper
        slidesPerView={4}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        className={s.swiper}
      >
        {tokenomics.map((item) => (
          <SwiperSlide key={item.title}>
            <div className={cn(s.swiper_item)}>
              <img src={item.icon} alt={item.title} />
              <div className={s.title}>{item.title}</div>
              <div className={s.description}>{item.description}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Tokenomics;
