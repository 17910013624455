import { FC } from 'react';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Input, Modal } from 'components';

import { IModalProps } from 'types';

import s from './SubscribeModal.module.scss';

const SubscribeModal: FC<IModalProps> = ({ visible, onClose }) => {
  return (
    <Modal className={s.modal_wrapper} visible={visible} onClose={onClose}>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Invalid email').required('Email is required'),
        })}
        onSubmit={() => {}}
      >
        {({ errors }) => (
          <Form className={s.subscribe_modal}>
            <div className={s.title}>Subscribe to Upcoming Launch</div>
            <div className={s.subtitle}>
              The Pool is still in beta, please use at your own risk.
            </div>
            <div className={s.label}>Email</div>
            <Field
              name="email"
              type="email"
              placeholder="Your email"
              className={s.input}
              error={errors.email}
              component={Input}
            />
            <div className={s.btn_wrapper}>
              <Button className={s.cancel_btn} onClick={onClose}>
                Cancel
              </Button>
              <Button disabled color="filled" type="submit" className={s.submit_btn}>
                Coming soon...
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default SubscribeModal;
