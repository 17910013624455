import React from 'react';

import { Button, Input } from 'components';
import { convertNumberReadableForm } from 'utils';

import firstCircleImg from 'assets/img/mock/staking/Ellipse297.png';

import s from './LevelsStakeInput.module.scss';

interface ILevelsStakeInput {
  type: 'stake' | 'unstake';
  handleChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  amount: string;
  available: string;
  handleMax: (type: 'stake' | 'unstake') => void;
  btn?: React.ReactElement;
  error?: boolean;
  errorMsg?: string;
}

const LevelsStakeInput: React.FC<ILevelsStakeInput> = ({
  handleMax,
  handleChangeAmount,
  amount,
  available,
  type,
  btn,
  error,
  errorMsg,
}) => {
  return (
    <div className={s.lsi}>
      <Input
        onChange={handleChangeAmount}
        value={amount}
        positiveOnly
        className={s.lsi__input}
        isNumber
        // max={+available}
        color="dark"
        subtitle={
          <div className={s.lsi__input__subtitle_wrapper}>
            <span className={s.lsi__input__subtitle}>
              Balance: {convertNumberReadableForm(available)}{' '}
              <span className="text-600">QUACK</span>
            </span>
            {error && <span className={s.lsi__input__error}>{errorMsg}</span>}
          </div>
        }
        postfix={
          <div className={s.lsi__input__prefix}>
            <Button
              size="small"
              className={s.lsi__input__prefix__btn}
              onClick={() => handleMax(type)}
            >
              MAX
            </Button>
            <div className={s.lsi__input__prefix__img}>
              <img src={firstCircleImg} alt="" />
            </div>
            <div className={s.lsi__input__prefix__text}>QUACK</div>
          </div>
        }
      />
      {btn}
    </div>
  );
};

export default React.memo(LevelsStakeInput);
