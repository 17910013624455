import { FC, useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import { Button, Modal } from 'components';
import { addressWithDots, convertQuackTokens } from 'utils';

import { useWalletConnectorContext } from 'services';

import copyLink from 'assets/img/icons/copy_link.svg';

import s from './UserModal.module.scss';

const UserModal: FC = observer(() => {
  const { disconnect } = useWalletConnectorContext();
  const {
    user,
    modals: { userModal },
  } = useMst();

  const handleDisconnect = useCallback(() => {
    disconnect();
    userModal.toggle();
  }, [disconnect, userModal]);

  return (
    <Modal className={s.modal_wrapper} visible={userModal.isOpen} onClose={userModal.toggle}>
      <div className={s.user_modal_wrapper}>
        <div className={s.title}>Account</div>
        <div className={s.info_wrapper}>
          <div className={s.info_box}>
            <div className={s.label}>Balance</div>
            <div className={s.content}>{convertQuackTokens(user.quackBalance)} QUACK</div>
          </div>
          <div className={s.info_box}>
            <div className={s.label}>Network</div>
            <div className={s.content}>Binance Smart Chain</div>
          </div>
        </div>
        {user.address && (
          <CopyToClipboard text={user.address}>
            <Button className={s.wallet} color="filled" size="medium" isFullWidth>
              <span className={s.address}>{user.address}</span>
              <span className={s.address_sm}>{addressWithDots(user.address, 6)}</span>
              <img src={copyLink} alt="copyLink" />
            </Button>
          </CopyToClipboard>
        )}
        <Button onClick={handleDisconnect} className={s.disconnect_btn}>
          Disconnect
        </Button>
      </div>
    </Modal>
  );
});

export default UserModal;
