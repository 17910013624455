import React from 'react';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import { Button } from 'components';

import emptyImg from 'assets/img/start_project/start_project.png';

import style from './StartProjectEmpty.module.scss';

const StartProjectEmpty: React.FC = observer(() => {
  const {
    user,
    modals: { walletConnect },
  } = useMst();

  const handleConnect = React.useCallback(() => {
    walletConnect.toggle();
  }, [walletConnect]);

  return (
    <div className={style.sp_empty}>
      <img src={emptyImg} alt="" className={style.sp_empty__img} />
      <div className={style.sp_empty__title}>
        Create pitch, start raising money, invite friends and get your creative project to life!
      </div>
      {user.address ? (
        <Button color="filled" href="/start/type" className={style.sp_empty__btn}>
          START RAISING
        </Button>
      ) : (
        <Button color="filled" className={style.sp_empty__btn} onClick={handleConnect}>
          Connect
        </Button>
      )}
    </div>
  );
});

export default StartProjectEmpty;
