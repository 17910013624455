import { FC, Fragment, useCallback } from 'react';

import { ThemeEnum, useMst } from '../../store';
import { observer } from 'mobx-react-lite';

import cn from 'classnames';
import { ConnectButton, MenuLink } from 'containers';

import { Switch } from 'components';

import { routes } from '../../router';

import s from './Sidebar.module.scss';

const Sidebar: FC = observer(() => {
  const { theme, sidebar } = useMst();
  const handleChangeTheme = useCallback(() => {
    if (ThemeEnum.light === localStorage.quack_theme) {
      theme.setTheme(ThemeEnum.dark);
    } else {
      theme.setTheme(ThemeEnum.light);
    }
  }, [theme]);

  const menu = routes.map((route, index) => {
    if (Object.prototype.hasOwnProperty.call(route, 'props') && route.props?.menu) {
      return <MenuLink key={route.name} route={route} index={index} />;
    }
    return <Fragment key={route.name} />;
  });

  return (
    <>
      <aside className={cn(s.sidebar_wrapper, { [s.active]: sidebar.isOpen })}>
        <ConnectButton showOnMobile />
        <div className={s.sidebar_menu}>
          <div className={s.menu}>{menu}</div>
        </div>
        <div className={s.theme_toggle_wrapper}>
          <Switch value={theme.value === ThemeEnum.dark} setValue={handleChangeTheme} />
          {sidebar.isOpen && <span className={s.theme_value}>{theme.value}</span>}
        </div>
      </aside>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        onClick={sidebar.toggleSidebar}
        className={cn(s.sidebar_cover, { [s.active]: sidebar.isOpen })}
      />
    </>
  );
});

export default Sidebar;
