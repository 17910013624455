import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import style from '../Input.module.scss';

export interface IPicker {
  value?: any;
  onChange: (value: any) => void;
  onBlur?: (e: any) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  ref?: any;
  minDate?: Date;
}

const Picker: React.FC<IPicker> = React.forwardRef<DatePicker, IPicker>(
  ({ value, onChange, onBlur, minDate }, ref) => {
    return (
      <div onBlur={onBlur}>
        <DatePicker
          selected={value}
          ref={ref}
          isClearable={false}
          onChange={onChange}
          minDate={minDate}
          className={style.input}
          timeInputLabel="Time:"
          timeIntervals={1}
          dateFormat="Pp"
          showTimeSelect
          timeFormat="p"
          timeCaption="time"
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      </div>
    );
  },
);

export default Picker;
