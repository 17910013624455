import { createContext, useContext } from 'react';

import { Instance, types } from 'mobx-state-tree';

import { Modals, QuackTokenModal, SidebarModel, ThemeModel, UserModel } from './Models';

export enum ThemeEnum {
  dark = 'dark',
  light = 'light',
}

const RootModel = types.model({
  theme: ThemeModel,
  sidebar: SidebarModel,
  user: UserModel,
  quack: QuackTokenModal,
  modals: Modals,
});

export const Store = RootModel.create({
  theme: {
    value: localStorage.quack_theme ?? ThemeEnum.dark,
  },
  sidebar: {
    activeMenuIndex: null,
    isOpen: window.innerWidth >= 1024,
  },
  user: {
    address: null,
    quackBalance: '0',
    usdtBalance: '0',
    level: 0,
    totalStaked: '0',
    lockUpItems: [],
    refreshUserData: false,
  },
  quack: {
    decimals: 9,
    data: {
      circulatingSupply: 0,
      marketCap: 0,
      maxSupply: 0,
      price: 0,
      totalSupply: 0,
    },
  },
  modals: {
    walletConnect: { isOpen: false },
    userModal: { isOpen: false },
  },
});

const rootStore = Store;

export type RootInstance = Instance<typeof RootModel>;

const RootStoreContext = createContext<RootInstance | null>(null);

export const { Provider } = RootStoreContext;

// onSnapshot(rootStore, (snapshot) => {
//   // eslint-disable-next-line no-console
//   console.log('Snapshot', snapshot);
// });

export const useMst = (): RootInstance => {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw Error('Store cannot be null, please add a context provider');
  }
  return store;
};

export default rootStore;
