import {
  BitmartImg,
  DigfinexImg,
  GateImg,
  HotbitImg,
  LatokenImg,
  LBankImg,
  MexcImg,
  PancakeImg,
  XTImg,
  ZTImg,
} from 'assets/img';

export const exchanges = [
  {
    name: 'Pancakeswap',
    link: 'https://pancakeswap.finance/swap?inputCurrency=0xd74b782e05aa25c50e7330af541d46e18f36661c&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    icon: PancakeImg,
  },
  {
    name: 'Gate.io',
    link: 'https://www.gate.io/ru/trade/QUACK_USDT',
    icon: GateImg,
  },
  {
    name: 'MEXC',
    link: 'https://www.mexc.com/exchange/QUACK_USDT',
    icon: MexcImg,
  },
  {
    name: 'LBank',
    link: 'https://www.lbank.info/exchange/quack/usdt',
    icon: LBankImg,
  },
  {
    name: 'Hotbit',
    link: 'https://www.hotbit.io/exchange?symbol=QUACK_nUSD',
    icon: HotbitImg,
  },
  {
    name: 'Bitmart',
    link: 'https://www.bitmart.com/trade/en?symbol=QUACK_USDT&layout=basic',
    icon: BitmartImg,
  },
  {
    name: 'Digifinex',
    link: 'https://www.digifinex.com/en-ww/trade/USDT/QUACK',
    icon: DigfinexImg,
  },
  {
    name: 'ZT',
    link: 'https://www.ztb.im/exchange?coin=QUACK_USDT',
    icon: ZTImg,
  },
  {
    name: 'XT.com',
    link: 'https://www.xt.com/trade/1000kquack_usdt',
    icon: XTImg,
  },
  {
    name: 'Latoken',
    link: 'https://latoken.com/exchange/QUACK_USDT',
    icon: LatokenImg,
  },
];
