import { useCallback, useEffect, useState } from 'react';

const useProjectStatusTextSimple = (status: string, round: string, stage: number): string[] => {
  const [statusText, setStatusText] = useState(['', '']);

  const getStatusLabelText = useCallback(() => {
    if (status === 'completed success') {
      setStatusText(['completed', 'success']);
      return true;
    }
    if (status === 'completed fail') {
      setStatusText(['completed', 'fail']);
      return true;
    }
    if (status === 'voting failed') {
      setStatusText(['voting', 'failed']);
      return true;
    }
    if (status === 'open') {
      if (round === 'break') {
        setStatusText([status, 'Break (next round not started yet)']);
        return true;
      }
      if (round === 'diamond') {
        setStatusText([status, `${round} (Stage ${+stage + 1})`]);
        return true;
      }
      setStatusText([status, round]);
      return true;
    }
    setStatusText([status, 'Progress']);
    return true;
  }, [round, stage, status]);

  useEffect(() => {
    if (status) {
      getStatusLabelText();
    }
  }, [getStatusLabelText, status]);

  return statusText;
};

export default useProjectStatusTextSimple;
