import { FC } from 'react';

import cn from 'classnames';

import { LoadingIcon } from 'assets/img';

import style from './Loading.module.scss';

interface ILoading {
  className?: string;
}
const Loading: FC<ILoading> = ({ className }) => {
  return (
    <div className={cn(style.loading, className)}>
      <img src={LoadingIcon} alt="loading" />
    </div>
  );
};

export default Loading;
