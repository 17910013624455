import { FC } from 'react';

import { Button, Modal } from 'components';

import { IModalProps } from 'types';

import s from './ValidationModal.module.scss';

interface IValidationErrorsModalProps extends IModalProps {
  errors: string[];
}

const ValidationModal: FC<IValidationErrorsModalProps> = ({ visible, onClose, errors }) => {
  return (
    <Modal className={s.modal_wrapper} visible={visible} onClose={onClose}>
      <div className={s.validation_modal_wrapper}>
        <div className={s.title}>Validation failed</div>
        {errors.map((error) => {
          const result = error.split(':');
          return (
            <div key={result[1]} className={s.text_wrapper}>
              <span>{result[0]}:</span>
              <span>{result[1]}</span>
            </div>
          );
        })}
        <Button color="filled" isFullWidth onClick={onClose} className={s.btn}>
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default ValidationModal;
