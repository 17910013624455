/* eslint-disable no-await-in-loop */
import { useCallback, useEffect, useState } from 'react';

import BigNumber from 'bignumber.js';
import { addMinutes, getTime, millisecondsToSeconds, toDate } from 'date-fns';

import { contracts, is_production } from 'config';
import { projectRounds } from 'utils/constants';

import { presaleApi, useWalletConnectorContext, WalletService } from 'services';
import { IApiPresale, IFilters, IPresale, statusEnum } from 'types';

export const REGISTRATION_DURATION = is_production ? 2880 : -15;
export const REGISTRATION_CLOSE_DURATION = is_production ? 720 : -5;
export const REGISTRATION_DURATION_INQUBATOR = is_production ? 2880 : -15;
export const BREAK_DURATION = is_production ? 120 : 5;

const usePresale = (
  userAddress: string | null,
  getExtendedData = false,
  query?: string | unknown,
  presaleId?: string | unknown,
  filters?: IFilters | {},
): [IPresale[], string[], () => void, boolean] => {
  const { walletService } = useWalletConnectorContext();

  const [presalesData, setPresalesData] = useState<IPresale[]>([]);
  const [pagiLinks, setPagiLinks] = useState<string[]>([]);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const getPresales = useCallback(async () => {
    return presaleApi.get({ ...{ search: query, id: presaleId }, ...filters });
  }, [filters, presaleId, query]);

  const handleRefreshData = useCallback(() => {
    console.log('refreshing...');
    setRefreshData(true);
  }, []);

  const getPresaleStatus = useCallback(
    async (presaleType: string, presaleAddress: string) => {
      const currentDate = Date.now();

      const web3 = walletService.Web3();
      const contractName = `PRESALE_${presaleType.toUpperCase()}`;
      const contract = new web3.eth.Contract(
        contracts.params[contractName][contracts.type].abi,
        presaleAddress,
      );

      const generalInfoPromise = contract.methods.generalInfo().call();
      const intermediatePromise = contract.methods.intermediate().call();
      const [generalInfo, intermediate] = await Promise.all([
        generalInfoPromise,
        intermediatePromise,
      ]);
      const tokenContract = new web3.eth.Contract(
        contracts.params.QUACK[contracts.type].abi,
        generalInfo.tokenAddress,
      );
      const tokenPresaleBalance = await tokenContract.methods.balanceOf(presaleAddress).call();

      const isCreatorReturnTokens = +tokenPresaleBalance === 0;
      const isCreatorWithdrawFunds = intermediate.withdrawedFunds;

      let isCreatorClaimedLp = false;
      if (intermediate.lpAddress !== '0x0000000000000000000000000000000000000000') {
        const lpContract = new web3.eth.Contract(
          contracts.params.USDT[contracts.type].abi,
          intermediate.lpAddress,
        );
        const liquidityBalance = await lpContract.methods.balanceOf(presaleAddress).call();
        isCreatorClaimedLp = +liquidityBalance === 0;
      }

      const totalRaised = await contract.methods.getRaisedAmount().call();
      let stageIndex = 4;

      if (presaleType === 'private') {
        if (
          currentDate > +generalInfo.openTime * 1000 &&
          currentDate <= +generalInfo.closeTime * 1000
        ) {
          return [
            statusEnum.open,
            'Private round',
            stageIndex,
            isCreatorWithdrawFunds,
            isCreatorReturnTokens,
            isCreatorClaimedLp,
          ];
        }
        if (+totalRaised >= +generalInfo.softCap) {
          return [
            statusEnum.completedSuccess,
            '-',
            stageIndex,
            isCreatorWithdrawFunds,
            isCreatorReturnTokens,
            isCreatorClaimedLp,
          ];
        }
        if (currentDate > generalInfo.closeTime * 1000) {
          return [
            statusEnum.completedFail,
            '-',
            stageIndex,
            isCreatorWithdrawFunds,
            isCreatorReturnTokens,
            isCreatorClaimedLp,
          ];
        }
        return [
          statusEnum.upcoming,
          '-',
          stageIndex,
          isCreatorWithdrawFunds,
          isCreatorReturnTokens,
          isCreatorClaimedLp,
        ];
      }

      if (presaleType === 'public') {
        const votingParams = await contract.methods.votingParams().call();
        if (currentDate <= intermediate.closeTimeVoting * 1000) {
          return [
            statusEnum.voting,
            '-',
            stageIndex,
            isCreatorWithdrawFunds,
            isCreatorReturnTokens,
            isCreatorClaimedLp,
          ];
        }
        if (
          currentDate > intermediate.closeTimeVoting * 1000 &&
          +intermediate.votes.yes < +intermediate.votes.no + +votingParams.threshold
        ) {
          return [
            statusEnum.votingFailed,
            '-',
            stageIndex,
            isCreatorWithdrawFunds,
            isCreatorReturnTokens,
            isCreatorClaimedLp,
          ];
        }
      }

      if (
        currentDate > generalInfo.openTime * 1000 &&
        currentDate <= generalInfo.closeTime * 1000
      ) {
        const roundIndex = await contract.methods.getRound().call();
        stageIndex = await contract.methods.getStage().call();
        return [
          statusEnum.open,
          projectRounds[roundIndex],
          stageIndex,
          isCreatorWithdrawFunds,
          isCreatorReturnTokens,
          isCreatorClaimedLp,
        ];
      }
      if (currentDate > generalInfo.closeTime * 1000 && +totalRaised >= +generalInfo.softCap) {
        return [
          statusEnum.completedSuccess,
          '-',
          stageIndex,
          isCreatorWithdrawFunds,
          isCreatorReturnTokens,
          isCreatorClaimedLp,
        ];
      }
      if (currentDate > generalInfo.closeTime * 1000) {
        return [
          statusEnum.completedFail,
          '-',
          stageIndex,
          isCreatorWithdrawFunds,
          isCreatorReturnTokens,
          isCreatorClaimedLp,
        ];
      }
      if (
        currentDate >
        getTime(addMinutes(toDate(generalInfo.openTime * 1000), REGISTRATION_CLOSE_DURATION))
      ) {
        return [
          statusEnum.registerClosed,
          'Ignition',
          stageIndex,
          isCreatorWithdrawFunds,
          isCreatorReturnTokens,
          isCreatorClaimedLp,
        ];
      }
      if (
        presaleType === 'inqubator' &&
        currentDate >
          getTime(addMinutes(toDate(generalInfo.openTime * 1000), REGISTRATION_DURATION_INQUBATOR)) // TODO add production duration
      ) {
        return [
          statusEnum.register,
          'Registration',
          stageIndex,
          isCreatorWithdrawFunds,
          isCreatorReturnTokens,
          isCreatorClaimedLp,
        ];
      }
      if (
        presaleType === 'public' &&
        currentDate >
          getTime(addMinutes(toDate(generalInfo.openTime * 1000), REGISTRATION_DURATION))
      ) {
        return [
          statusEnum.register,
          'Registration',
          stageIndex,
          isCreatorWithdrawFunds,
          isCreatorReturnTokens,
          isCreatorClaimedLp,
        ];
      }

      return [
        statusEnum.upcoming,
        '-',
        stageIndex,
        isCreatorWithdrawFunds,
        isCreatorReturnTokens,
        isCreatorClaimedLp,
      ];
    },
    [walletService],
  );

  const getPresaleUserData = useCallback(
    async (presaleType: string, presaleAddress: string) => {
      const web3 = walletService.Web3();
      const contractName = `PRESALE_${presaleType.toUpperCase()}`;
      const contract = new web3.eth.Contract(
        contracts.params[contractName][contracts.type].abi,
        presaleAddress,
      );

      const lotteryWhitelistPromise = contract.methods.lotteryWhitelist(userAddress).call();
      const registerLevelsPromise = contract.methods.registerLevels(userAddress).call();
      let isUserVoted = true;
      if (presaleType === 'public') {
        isUserVoted = await contract.methods.isUserVoted(userAddress).call();
      }
      const promises = [lotteryWhitelistPromise, registerLevelsPromise];
      const result = await Promise.all(promises);
      const [lotteryWhitelist, registerLevels] = result;
      let roundTokenAllocation = 0;

      const roundIndex = await contract.methods.getRound().call();
      if (roundIndex < 3) {
        roundTokenAllocation = await contract.methods
          .roundTokenAllocation(+roundIndex, +registerLevels.level)
          .call();
      }

      return [
        lotteryWhitelist,
        +registerLevels.level > 0,
        +registerLevels.level,
        isUserVoted,
        roundTokenAllocation,
      ];
    },
    [userAddress, walletService],
  );

  const getUserInvestments = useCallback(
    async (
      presaleType: string,
      presaleAddress: string,
      tokenDecimals: number,
      lpTokensLockDurationInDays: number,
    ) => {
      const web3 = walletService.Web3();
      const contractName = `PRESALE_${presaleType.toUpperCase()}`;
      const contract = new web3.eth.Contract(
        contracts.params[contractName][contracts.type].abi,
        presaleAddress,
      );

      const intermediatePromise = contract.methods.intermediate().call();
      const investmentsPromise = contract.methods.investments(userAddress).call();
      const vestingPromise = contract.methods.vestingInfo().call();
      let promises = [intermediatePromise, investmentsPromise, vestingPromise];
      const result = await Promise.all(promises);
      const [intermediate, investments, vesting] = result;

      const currentDate = millisecondsToSeconds(Date.now());
      let getMaxInvestment = '0';
      let canUserInvest = false;
      let canUserClaim = false;
      let readyToClaim = new BigNumber(0);

      if (presaleType === 'private') {
        getMaxInvestment = new BigNumber(intermediate.tokensForSaleLeft).toString();
        canUserInvest = await contract.methods.whitelist(userAddress).call();
      }
      if (presaleType !== 'private') {
        const registerLevelsPromise = contract.methods.registerLevels(userAddress).call();
        const getRoundPromise = contract.methods.getRound().call();
        promises = [registerLevelsPromise, getRoundPromise];
        const [registerLevels, round] = await Promise.all(promises);

        if (+registerLevels.level > 0 || +round === 2) {
          getMaxInvestment = await contract.methods.getMaxInvestment(userAddress).call();
          if (+getMaxInvestment > 0) {
            canUserInvest = true;
          }
        }
      }

      let liquidityAdded = +intermediate.lpUnlockTime;
      let totalPerc = new BigNumber(1);
      const perc1 = new BigNumber(vesting.vestingPerc1).dividedBy(100);
      const perc2 = new BigNumber(vesting.vestingPerc2).dividedBy(100);
      const total = new BigNumber(investments.amountTokens);
      const claimed = new BigNumber(investments.amountClaimed);
      const percFromTotal1 = total.multipliedBy(perc1);
      const percFromTotal2 = total.multipliedBy(perc2);
      const vestingPeriod = +vesting.vestingPeriod;

      if (liquidityAdded !== 0 && investments.amountEth > 0) {
        liquidityAdded =
          +intermediate.lpUnlockTime - lpTokensLockDurationInDays * (is_production ? 86400 : 60);
        canUserClaim = false;

        let maxI = 0;
        while (totalPerc.isGreaterThan(0)) {
          totalPerc = maxI === 0 ? totalPerc.minus(perc1) : totalPerc.minus(perc2);
          if (totalPerc.isLessThanOrEqualTo(0)) {
            break;
          }
          maxI += 1;
        }
        for (let i = 0; i <= maxI; i += 1) {
          if (i === maxI && +currentDate > +liquidityAdded + +vestingPeriod * (i + 1)) {
            if (claimed.isLessThan(total)) {
              canUserClaim = true;
              readyToClaim = total.minus(claimed);
              break;
            }
          }
          if (
            +currentDate > +liquidityAdded + +vestingPeriod * i &&
            +currentDate < +liquidityAdded + +vestingPeriod * (i + 1)
          ) {
            if (claimed.isEqualTo(0)) {
              canUserClaim = true;
              const claimable = percFromTotal1.plus(percFromTotal2.multipliedBy(i));
              readyToClaim = claimable;
              if (claimable.isGreaterThan(total)) {
                readyToClaim = total;
              }
              break;
            }
            if (
              claimed.isGreaterThan(0) &&
              claimed.isLessThanOrEqualTo(percFromTotal1.plus(percFromTotal2.multipliedBy(i - 1)))
            ) {
              canUserClaim = true;
              const claimedWithoutPerc1 = claimed.minus(percFromTotal1);
              readyToClaim = percFromTotal2.multipliedBy(i).minus(claimedWithoutPerc1);
              if (total.minus(claimed).isLessThan(percFromTotal2)) {
                canUserClaim = true;
                readyToClaim = total.minus(claimed);
                break;
              }
              break;
            }
          }
        }
      }
      return {
        amountEth: WalletService.weiToEth(
          investments.amountEth.toString(),
          tokenDecimals,
        ).toString(),
        amountTokens: WalletService.weiToEth(
          investments.amountTokens.toString(),
          tokenDecimals,
        ).toString(),
        amountClaimed: WalletService.weiToEth(
          investments.amountClaimed.toString(),
          tokenDecimals,
        ).toString(),
        readyToClaim: WalletService.weiToEth(readyToClaim.toString(), tokenDecimals).toString(),
        canUserClaim,
        canUserInvest,
        maxUserInvestment: WalletService.weiToEth(
          getMaxInvestment.toString(),
          tokenDecimals,
        ).toString(),
      };
    },
    [userAddress, walletService],
  );

  const preparePresaleData = useCallback(
    async (presales: IApiPresale[]) => {
      const result: any = [];
      for (let i = 0; i < presales.length; i += 1) {
        const presale = presales[i];
        const web3 = walletService.Web3();
        const contractName = `PRESALE_${presale.type.toUpperCase()}`;
        const contract = new web3.eth.Contract(
          contracts.params[contractName][contracts.type].abi,
          presale.address,
        );

        const fundingTokenDecimals = await walletService.getTokenDecimals(
          contracts.params.USDT[contracts.type].address,
        );
        const tokenDecimals = await walletService.getTokenDecimals(presale.tokenContractAddress);
        const tokenSymbol = await walletService.getTokenSymbol(presale.tokenContractAddress);
        const currentDate = millisecondsToSeconds(Date.now());

        const generalInfoPromise = contract.methods.generalInfo().call();
        const intermediatePromise = contract.methods.intermediate().call();
        const raisedAmountPromise = contract.methods.getRaisedAmount().call();
        const [generalInfo, intermediate, raisedAmount] = await Promise.all([
          generalInfoPromise,
          intermediatePromise,
          raisedAmountPromise,
        ]);
        let getStagesCloseTime;
        let getBreakNumber;
        if (presale.type !== 'private') {
          try {
            getStagesCloseTime = await contract.methods.getCloseTimestamps().call();
            getBreakNumber = await contract.methods.getBreakNumber().call();
          } catch (err) {
            console.log(err);
          }
        }

        const finalPresale = {
          id: presale.id,
          isInit: intermediate.initialized,
          status: '',
          round: '',
          stage: 4,
          type: presale.type,
          creator: generalInfo.creator,
          tokenAddress: generalInfo.tokenAddress,
          title: presale.title,
          description: presale.description,
          story: presale.story,
          img: presale.presaleImage,
          logo: presale.logo,
          website: presale.website,
          socials: {
            web: presale.website,
            twitter: presale.twitter,
            telegram: presale.telegram,
            discord: presale.discord,
            medium: presale.medium,
          },
          address: presale.address,
          tokenPrice: WalletService.weiToEth(
            generalInfo.tokenPrice.toString(),
            fundingTokenDecimals,
          ).toString(),
          tokenSymbol,
          tokenDecimals,
          totalVoters: 0,
          voters: [+presale.votes[0], +presale.votes[1]],
          registrationOpens: getTime(
            addMinutes(toDate(generalInfo.openTime * 1000), REGISTRATION_DURATION),
          ),
          registrationClosed: getTime(
            addMinutes(toDate(generalInfo.openTime * 1000), REGISTRATION_CLOSE_DURATION),
          ),
          totalRegistered: presale.totalRegistered,
          salesStart: generalInfo.openTime * 1000,
          salesEnd: generalInfo.closeTime * 1000,
          closeTimeVoting: intermediate.closeTimeVoting * 1000,
          moonEnd: getStagesCloseTime ? getStagesCloseTime.moonEnd * 1000 : 0,
          diamondEnd: getStagesCloseTime ? getStagesCloseTime.diamondEnd * 1000 : 0,
          paperEnd: getStagesCloseTime ? getStagesCloseTime.paperEnd * 1000 : 0,
          stagesEnd: getStagesCloseTime
            ? getStagesCloseTime.stagesEnd.map((stage: string | number) => +stage * 1000)
            : [0, 0, 0, 0],
          roundBreak: +getBreakNumber || 0,
          totalRaised: WalletService.weiToEth(raisedAmount.toString(), fundingTokenDecimals),
          hardCap: WalletService.weiToEth(
            generalInfo.hardCap.toString(),
            fundingTokenDecimals,
          ).toString(),
          softCap: WalletService.weiToEth(
            generalInfo.softCap.toString(),
            fundingTokenDecimals,
          ).toString(),
          tokensForSaleLeft: +intermediate.tokensForSaleLeft,
          liquidityAdded: +intermediate.lpUnlockTime,
          addLiquidityLocked: !(currentDate > presale.liquidityAllocationTime),
          isCreatorWithdrawFunds: false,
          isCreatorReturnTokens: false,
          isCreatorClaimedLp: false,
          user: {
            isUserWinLottery: false,
            isUserRegistered: true,
            isUserVoted: true,
            level: 0,
            investments: {
              amountEth: '0',
              amountClaimed: '0',
              amountTokens: '0',
              readyToClaim: '0',
              canUserClaim: false,
              canUserInvest: false,
              maxUserInvestment: '0',
            },
          },
        };

        if (presale.type === 'public') {
          finalPresale.voters = [
            parseInt(intermediate.votes.yes, 10),
            parseInt(intermediate.votes.no, 10),
          ];
          finalPresale.totalVoters =
            parseInt(intermediate.votes.yes, 10) + parseInt(intermediate.votes.no, 10);
        }
        if (presale.type === 'inqubator') {
          finalPresale.registrationOpens = getTime(
            addMinutes(toDate(generalInfo.openTime * 1000), REGISTRATION_DURATION_INQUBATOR),
          );
        }
        if (presale.type !== 'private') {
          finalPresale.salesStart = generalInfo.openTime * 1000;
          finalPresale.salesEnd = generalInfo.closeTime * 1000;

          if (userAddress) {
            [
              finalPresale.user.isUserWinLottery,
              finalPresale.user.isUserRegistered,
              finalPresale.user.level,
              finalPresale.user.isUserVoted,
            ] = await getPresaleUserData(presale.type, presale.address);
          }
        }

        if (userAddress && getExtendedData) {
          finalPresale.user.investments = await getUserInvestments(
            presale.type,
            presale.address,
            tokenDecimals,
            presale.lpTokensLockDurationInDays,
          );
        }
        [
          finalPresale.status,
          finalPresale.round,
          finalPresale.stage,
          finalPresale.isCreatorWithdrawFunds,
          finalPresale.isCreatorReturnTokens,
          finalPresale.isCreatorClaimedLp,
        ] = await getPresaleStatus(presale.type, presale.address);

        // there is a delay between reg-close and open, that returns round === 'break, this KOSTIL is to prevent refresh loop
        if (
          presale.type !== 'private' &&
          +currentDate * 1000 >= +finalPresale.salesStart &&
          +currentDate * 1000 < +finalPresale.salesStart + 120000
        ) {
          finalPresale.round = 'moon';
        }

        result.push(finalPresale);
      }

      return result;
    },
    [
      getExtendedData,
      getPresaleStatus,
      getPresaleUserData,
      getUserInvestments,
      userAddress,
      walletService,
    ],
  );

  useEffect(() => {
    setLoading(true);
    getPresales().then(async (result) => {
      const presales = await preparePresaleData(result.data.results);
      setPresalesData(presales);
      setPagiLinks([result.data.links.previous, result.data.links.next]);
      setRefreshData(false);
      setLoading(false);
    });
  }, [getPresales, preparePresaleData]);

  useEffect(() => {
    if (refreshData && presaleId) {
      setLoading(true);
      getPresales().then(async (result) => {
        const presales = await preparePresaleData(result.data.results);
        setPresalesData(presales);
        setPagiLinks([result.data.links.previous, result.data.links.next]);
        setRefreshData(false);
        setLoading(false);
      });
    }
  }, [getPresales, preparePresaleData, presaleId, refreshData]);

  return [presalesData, pagiLinks, handleRefreshData, isLoading];
};
export default usePresale;
