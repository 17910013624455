import React from 'react';

import cn from 'classnames';
import { addDays } from 'date-fns';
import { Field, Form, FormikProps } from 'formik';

import { Button, Input, Selector } from 'components';

import { ISwapPlanForm, IValues } from '../container';

import style from './SwapPlan.module.scss';

const allocationPercents = [
  {
    value: 40,
    text: '40%',
  },
  {
    value: 60,
    text: '60%',
  },
  {
    value: 80,
    text: '80%',
  },
];

// const liquidityProviders = [
//   {
//     value: 'Team.Finance',
//   },
// ];

const SwapPlanForm: React.FC<FormikProps<IValues> & ISwapPlanForm> = ({
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  touched,
  values,
  handleBack,
  setFieldValue,
}) => {
  const activeAllocationPercent = React.useMemo(() => {
    return (
      allocationPercents.find((percent) => percent.value === values.allocationPercent) ||
      allocationPercents[0]
    );
  }, [values.allocationPercent]);
  // const activeLockingProvider = React.useMemo(() => {
  //   return (
  //     liquidityProviders.find((provider) => provider.value === values.lockingProvider) ||
  //     liquidityProviders[0]
  //   );
  // }, [values.lockingProvider]);

  return (
    <Form name="SwapPlan-form" onSubmit={handleSubmit}>
      <div className={cn(style.sp_form__row, 'form__create_presale__row')}>
        <Field id="listingPrice" name="listingPrice">
          {() => (
            <Input
              name="listingPrice"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="e.g. 0.0068 BNB per QUACK"
              value={values.listingPrice || ''}
              isNumber
              positiveOnly
              title={<div className={cn('text-600 text-sm')}>PancakeSwap Listing Price</div>}
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The listing price of your token in BNB when it is available to trade on
                  PancakeSwap.
                </div>
              }
              error={touched.listingPrice && errors.listingPrice ? errors.listingPrice : ''}
            />
          )}
        </Field>
        <Field id="listingTime" name="listingTime">
          {() => (
            <Input
              component="date"
              minDate={addDays(new Date(), 16)}
              value={values.listingTime || new Date()}
              onBlur={handleBlur('listingTime')}
              onChange={(value) => setFieldValue('listingTime', value)}
              title={<div className={cn('text-600 text-sm')}>PancakeSwap Listing Time</div>}
              color="dark"
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The date and time when your LP on PancakeSwap will be available for trading.
                </div>
              }
              error={touched.listingTime && errors.listingTime ? errors.listingTime : ''}
            />
          )}
        </Field>
      </div>

      <div className={cn(style.sp_form__row, 'form__create_presale__row')}>
        <Field id="allocationPercent" name="allocationPercent">
          {() => (
            <Selector
              color="dark"
              title={
                <div className={cn('text-600 text-sm')}>
                  PancakeSwap Liquidity Pool (LP) Allocation Percent{' '}
                </div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The percentage of the raised funds that will be used for the LP on PancakeSwap.
                </div>
              }
              size="big"
              defaultOption={activeAllocationPercent}
              options={allocationPercents}
              onSelect={(option) => setFieldValue('allocationPercent', option.value)}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.sp_form__row_sm, 'form__create_presale__row')}>
        <div className="form__create_presale__row__box form__create_presale__row__box__top">
          {/* <Field id="lockingProvider" name="lockingProvider"> */}
          {/*  {() => ( */}
          {/*    <Selector */}
          {/*      color="dark" */}
          {/*      subtitle={ */}
          {/*        <div className="text-gray text-sm text-italic">Liquidity Locking Provider </div> */}
          {/*      } */}
          {/*      size="big" */}
          {/*      defaultOption={activeLockingProvider} */}
          {/*      options={liquidityProviders} */}
          {/*      onSelect={(option) => setFieldValue('lockingProvider', option.value)} */}
          {/*    /> */}
          {/*  )} */}
          {/* </Field> */}
          <Field id="lockedDays" name="lockedDays">
            {() => (
              <Input
                name="lockedDays"
                color="dark"
                onChange={handleChange}
                onBlur={handleBlur}
                isNumber
                positiveOnly
                integer
                placeholder="0"
                value={values.lockedDays || ''}
                postfix={<span className="form__create_presale__postfix">Days</span>}
                subtitle={
                  <div className="text-gray text-sm text-italic">
                    The number of days that your liquidity will be locked in the LP on PancakeSwap.
                  </div>
                }
                error={touched.lockedDays && errors.lockedDays ? errors.lockedDays : ''}
              />
            )}
          </Field>
        </div>
      </div>

      <div className={cn(style.sp_form__btns, 'form__create_presale__box')}>
        <div className="text-gray form__create_presale__text" />
        <div className="form__create_presale__btns">
          <Button
            className="form__create_presale__btns__item back"
            onClick={handleBack}
            size="medium"
          >
            Back
          </Button>
          <Button
            color={Object.values(errors).length ? 'disabled' : 'filled'}
            size="medium"
            type="submit"
            className="form__create_presale__btns__item"
          >
            Next
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default SwapPlanForm;
