import React, { FC, memo, useCallback, useRef, useState } from 'react';

import cn from 'classnames';

import s from './Search.module.scss';

interface ISearch {
  onChange: (str: string) => void;
  placeholder?: string;
  realtime?: boolean;
  type?: 'header';
  handleChangeVisible?: (isVisible: boolean) => void;
  onKeyPress?: () => void;
}

const Search: FC<ISearch> = memo(
  ({ onChange, placeholder, realtime = true, type, handleChangeVisible, onKeyPress }) => {
    const inputNumberRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState('');

    const handleOnChange = useCallback(
      (str: string) => {
        setInputValue(str);
        if (realtime && onChange) {
          onChange(str);
        }
      },
      [onChange, realtime],
    );

    const handleFocus = useCallback(() => {
      if (handleChangeVisible) {
        handleChangeVisible(true);
      }
    }, [handleChangeVisible]);

    const handleBlur = useCallback(() => {
      if (handleChangeVisible) {
        handleChangeVisible(false);
      }
    }, [handleChangeVisible]);

    const handleImgClick = useCallback(() => {
      if (inputValue) {
        onChange(inputValue);
      }
      if (inputNumberRef.current) {
        inputNumberRef.current.focus();
        if (inputNumberRef.current.value !== '' && onKeyPress && handleChangeVisible) {
          handleOnChange('');
          onKeyPress();
        }
      }
      return undefined;
    }, [handleChangeVisible, handleOnChange, inputValue, onChange, onKeyPress]);

    const handleKeyPress = useCallback(
      (e: React.KeyboardEvent<any>) => {
        if (e.key === 'Enter' && onKeyPress && inputValue !== '') {
          onKeyPress();
          handleOnChange('');
        }
      },
      [handleOnChange, inputValue, onKeyPress],
    );

    return (
      <div
        className={cn(s.input, {
          [s.input__header]: type === 'header',
        })}
      >
        <div
          onClick={handleImgClick}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          className={s.input_img}
        />
        <input
          ref={inputNumberRef}
          value={inputValue}
          onChange={(e) => handleOnChange(e.target.value)}
          type="text"
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          className={cn(s.input__item, {
            [s.input__item__header]: type === 'header',
          })}
        />
      </div>
    );
  },
);

export default Search;
