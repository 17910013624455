import { FC } from 'react';

import AuditItem from './AuditItem';
import { data } from './mock';

import s from './Audit.module.scss';

const Audit: FC = () => {
  return (
    <div className={s.audit_wrapper}>
      {data.map((item) => (
        <AuditItem
          key={item.id}
          status={item.status}
          title={item.title}
          icon={item.icon}
          auditedBy={item.auditedBy}
        >
          {Object.prototype.hasOwnProperty.call(item, 'content') && item?.content}
        </AuditItem>
      ))}
    </div>
  );
};

export default Audit;
