import React from 'react';

import cn from 'classnames';

import style from './Steps.module.scss';

interface ISteps {
  allSteps: string[];
  currentStep: number;
}

const Steps: React.FC<ISteps> = ({ allSteps, currentStep }) => {
  return (
    <div className={style.steps}>
      {new Array(allSteps.length).fill(0).map((item, index) => (
        <div
          key={`${item + index}`}
          className={cn(style.item, {
            [style.item_active]: index + 1 <= currentStep,
          })}
        >
          <div className={style.item__circle} />
          <span className={style.item__label}>{allSteps[index]}</span>
        </div>
      ))}
    </div>
  );
};

export default Steps;
