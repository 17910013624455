import presaleImg from '../../assets/img/mock/project-preview.png';

export const tableHead = [
  {
    text: 'Name',
    isTabletHidden: false,
    isMobileHidden: false,
  },
  {
    text: 'Price (USDT)',
    isTabletHidden: false,
    isMobileHidden: false,
  },
  {
    text: 'Softcap',
    isTabletHidden: true,
    isMobileHidden: true,
  },
  {
    text: 'Hardcap',
    isTabletHidden: true,
    isMobileHidden: true,
  },
  {
    text: 'Opens in',
    isTabletHidden: false,
    isMobileHidden: false,
  },
  {
    text: 'Voting',
    isTabletHidden: false,
    isMobileHidden: true,
  },
];

export const items = [
  {
    img: presaleImg,
    name: 'Earthling : Invest, Protect and Revive Nature',
    price: 0.0005,
    softcap: 50000,
    hardcap: 100000,
    startDate: 1643633300000,
    voteFor: 150,
    voteAgainst: 50,
    currency: 'USDT',
  },
];
