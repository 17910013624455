/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';

import BigNumber from 'bignumber.js/bignumber';
import { addMinutes, getTime, millisecondsToSeconds, toDate } from 'date-fns';

import { contracts } from 'config';
import { projectRounds } from 'utils/constants';

import { useWalletConnectorContext, WalletService } from 'services';

type TProjectPresale = {
  tokenPrice: string;
  tokenSymbol: string;
  tokenDecimals: number;
  voters: number[];
  totalVoters: number;
  round: string;
  status: string;
  registrationOpens: number;
  registrationClosed: number;
  totalRegistered: number;
  salesStart: number;
  salesEnd: number;
  totalRaised: string;
  softCap: string;
  hardCap: string;
  closeTimeVoting: number;
  creator: string;
  tokensForSaleLeft: number;
};

type TInvestments = {
  amountEth: string;
  amountTokens: string;
  amountClaimed: string;
  readyToClaim: string;
};
export type TUserPresale = {
  isUserWinLottery: boolean;
  isUserRegistered: boolean;
  isUserVoted: boolean;
  canUserInvest: boolean;
  investments: TInvestments;
  liquidityAdded: number;
  canUserClaim: boolean;
  addLiquidityLocked: boolean;
  maxUserInvestment: string;
};

const usePresaleData = (
  projectId: number,
  userAddress: string | null,
  userLevel: number,
): [TProjectPresale, TUserPresale, boolean, () => void] => {
  const { walletService } = useWalletConnectorContext();
  const [projectData, setProjectData] = useState<TProjectPresale>({
    tokenPrice: '0',
    tokenSymbol: '',
    tokenDecimals: 18,
    voters: [0, 0],
    totalVoters: 0,
    round: '-',
    status: 'voting',
    registrationOpens: 0,
    registrationClosed: 0,
    totalRegistered: 0,
    salesStart: 0,
    salesEnd: 0,
    totalRaised: '0',
    softCap: '0',
    hardCap: '0',
    closeTimeVoting: 0,
    creator: '',
    tokensForSaleLeft: 0,
  });
  const [userData, setUserData] = useState<TUserPresale>({
    isUserWinLottery: false,
    isUserRegistered: false,
    isUserVoted: true,
    canUserInvest: false,
    investments: {
      amountEth: '0',
      amountTokens: '0',
      amountClaimed: '0',
      readyToClaim: '0',
    },
    liquidityAdded: 0,
    canUserClaim: false,
    addLiquidityLocked: true,
    maxUserInvestment: '0',
  });
  const [isDataLoading, setDataLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);

  const handleRefreshData = useCallback(() => {
    setRefreshData(true);
  }, []);

  const getIdoData = useCallback(async () => {
    try {
      // Create promises for getting contract data
      const generalInfoPromise = walletService.callContractMethod({
        contractName: 'PRESALE_PUBLIC_TEST',
        methodName: 'generalInfo',
        contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
        contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
      });
      const intermediatePromise = walletService.callContractMethod({
        contractName: 'PRESALE_PUBLIC_TEST',
        methodName: 'intermediate',
        contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
        contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
      });
      const votingParamsPromise = walletService.callContractMethod({
        contractName: 'PRESALE_PUBLIC_TEST',
        methodName: 'votingParams',
        contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
        contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
      });
      const totalRegisteredPromise = walletService.callContractMethod({
        contractName: 'PRESALE_PUBLIC_TEST',
        methodName: 'getTotalRegistered',
        contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
        contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
      });
      const totalRaisedPromise = walletService.callContractMethod({
        contractName: 'PRESALE_PUBLIC_TEST',
        methodName: 'getRaisedAmount',
        contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
        contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
      });
      // Wait for promises to complete and convert to usable data;
      const promises = [
        generalInfoPromise,
        intermediatePromise,
        votingParamsPromise,
        totalRegisteredPromise,
        totalRaisedPromise,
      ];
      const result = await Promise.all(promises);
      const [generalInfo, intermediate, votingParams, totalRegistered, totalRaised] = result;

      const tokenDecimals = await walletService.getTokenDecimals(generalInfo.tokenAddress);
      const tokenSymbol = await walletService.getTokenSymbol(generalInfo.tokenAddress);

      const fundingTokenDecimals = await walletService.getTokenDecimals(
        contracts.params.USDT[contracts.type].address,
      );

      // Start preparing final data
      let status: string;
      let round = '-';
      const currentDate = Date.now();

      // Check for status voting

      if (currentDate <= intermediate.closeTimeVoting * 1000) {
        status = 'voting';
      } else if (
        currentDate > intermediate.closeTimeVoting * 1000 &&
        +intermediate.votes.yes >= +intermediate.votes.no + +votingParams.threshold
      ) {
        status = 'upcoming';

        if (currentDate > getTime(addMinutes(toDate(generalInfo.openTime * 1000), -10))) {
          status = 'register';
          round = 'Registration';
        }
        if (currentDate > getTime(addMinutes(toDate(generalInfo.openTime * 1000), -5))) {
          status = 'register-closed';
          round = 'Ignition';
        }

        if (
          currentDate > generalInfo.openTime * 1000 &&
          currentDate <= generalInfo.closeTime * 1000
        ) {
          status = 'open';
          const roundIndex = await walletService.callContractMethod({
            contractName: 'PRESALE_PUBLIC_TEST',
            methodName: 'getRound',
            contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
            contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
          });
          round = projectRounds[roundIndex];
        }

        if (currentDate > generalInfo.closeTime * 1000) {
          status = 'completed fail';
          if (+totalRaised >= +generalInfo.softCap) {
            status = 'completed success';
          }
        }
      } else {
        status = 'voting failed';
      }

      // Prepare dates
      const registrationOpens = getTime(addMinutes(toDate(generalInfo.openTime * 1000), -10));
      const registrationClosed = getTime(addMinutes(toDate(generalInfo.openTime * 1000), -5));
      const salesStart = generalInfo.openTime * 1000;
      const salesEnd = generalInfo.closeTime * 1000;

      // Set final data
      setProjectData({
        tokenPrice: WalletService.weiToEth(
          generalInfo.tokenPrice.toString(),
          fundingTokenDecimals,
        ).toString(),
        tokenSymbol: tokenSymbol.toString(),
        tokenDecimals,
        voters: [parseInt(intermediate.votes.yes, 10), parseInt(intermediate.votes.no, 10)],
        totalVoters: parseInt(intermediate.votes.yes, 10) + parseInt(intermediate.votes.no, 10),
        round,
        status,
        registrationOpens,
        registrationClosed,
        totalRegistered,
        salesStart,
        salesEnd,
        totalRaised: WalletService.weiToEth(totalRaised.toString(), fundingTokenDecimals),
        softCap: WalletService.weiToEth(
          generalInfo.softCap.toString(),
          fundingTokenDecimals,
        ).toString(),
        hardCap: WalletService.weiToEth(
          generalInfo.hardCap.toString(),
          fundingTokenDecimals,
        ).toString(),
        closeTimeVoting: intermediate.closeTimeVoting * 1000,
        creator: generalInfo.creator,
        tokensForSaleLeft: intermediate.tokensForSaleLeft,
      });
      setDataLoading(false);
      setRefreshData(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }, [walletService]);

  const getIdoUserData = useCallback(async () => {
    try {
      const generalInfoPromise = walletService.callContractMethod({
        contractName: 'PRESALE_PUBLIC_TEST',
        methodName: 'generalInfo',
        contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
        contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
      });
      const intermediatePromise = walletService.callContractMethod({
        contractName: 'PRESALE_PUBLIC_TEST',
        methodName: 'intermediate',
        contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
        contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
      });
      const lotteryWhitelistPromise = walletService.callContractMethod({
        contractName: 'PRESALE_PUBLIC_TEST',
        methodName: 'lotteryWhitelist',
        contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
        contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
        data: [userAddress],
      });
      const registerLevelsPromise = walletService.callContractMethod({
        contractName: 'PRESALE_PUBLIC_TEST',
        methodName: 'registerLevels',
        contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
        contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
        data: [userAddress],
      });
      const isUserVotedPromise = walletService.callContractMethod({
        contractName: 'PRESALE_PUBLIC_TEST',
        methodName: 'isUserVoted',
        contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
        contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
        data: [userAddress],
      });
      const investmentsPromise = walletService.callContractMethod({
        contractName: 'PRESALE_PUBLIC_TEST',
        methodName: 'investments',
        contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
        contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
        data: [userAddress],
      });
      const vestingPromise = walletService.callContractMethod({
        contractName: 'PRESALE_PUBLIC_TEST',
        methodName: 'vestingInfo',
        contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
        contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
      });
      const dexInfoPromise = walletService.callContractMethod({
        contractName: 'PRESALE_PUBLIC_TEST',
        methodName: 'dexInfo',
        contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
        contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
      });
      const promises = [
        generalInfoPromise,
        intermediatePromise,
        lotteryWhitelistPromise,
        registerLevelsPromise,
        isUserVotedPromise,
        investmentsPromise,
        vestingPromise,
        dexInfoPromise,
      ];
      const result = await Promise.all(promises);
      const [
        generalInfo,
        intermediate,
        lotteryWhitelist,
        registerLevels,
        isUserVoted,
        investments,
        vesting,
        dexInfo,
      ] = result;

      const tokenDecimals = await walletService.getTokenDecimals(generalInfo.tokenAddress);

      console.log('lotteryWhitelist', lotteryWhitelist);
      console.log('--------------------------');

      // Calculate Invest Max Amount for user
      let getMaxInvestment = 0;
      let canUserInvest = false;
      if (+registerLevels.level > 0) {
        getMaxInvestment = await walletService.callContractMethod({
          contractName: 'PRESALE_PUBLIC_TEST',
          methodName: 'getMaxInvestment',
          contractAddress: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address,
          contractAbi: contracts.params.PRESALE_PUBLIC_TEST[contracts.type].abi,
          data: [userAddress],
        });
        if (+getMaxInvestment > 0) {
          canUserInvest = true;
        }
      }

      // Calculate vesting
      const currentDate = millisecondsToSeconds(Date.now());
      let liquidityAdded = +intermediate.lpUnlockTime;
      let canUserClaim = false;
      let readyToClaim = new BigNumber(0);
      const perc1 = new BigNumber(vesting.vestingPerc1).dividedBy(100);
      const perc2 = new BigNumber(vesting.vestingPerc2).dividedBy(100);
      const total = new BigNumber(investments.amountTokens);
      const claimed = new BigNumber(investments.amountClaimed);
      const percFromTotal1 = total.multipliedBy(perc1);
      const percFromTotal2 = total.multipliedBy(perc2);
      let addLiquidityLocked = true;
      const vestingPeriod = +vesting.vestingPeriod;

      if (currentDate > dexInfo.liquidityAllocationTime) {
        addLiquidityLocked = false;
      }

      if (liquidityAdded !== 0 && investments.amountEth > 0) {
        liquidityAdded = +intermediate.lpUnlockTime - 900;
        canUserClaim = false;

        if (currentDate < liquidityAdded + vestingPeriod) {
          // first claim, 10%,
          if (claimed.isEqualTo(0)) {
            canUserClaim = true;
            readyToClaim = percFromTotal1;
          }
        }
        if (
          currentDate > liquidityAdded + vestingPeriod &&
          currentDate < liquidityAdded + vestingPeriod * 2
        ) {
          // second claim, 20%
          if (claimed.isLessThanOrEqualTo(percFromTotal1)) {
            canUserClaim = true;
            readyToClaim = percFromTotal2;
          }
          if (claimed.isEqualTo(0)) {
            readyToClaim = percFromTotal1.plus(percFromTotal2);
            canUserClaim = true;
          }
        }
        if (
          currentDate > liquidityAdded + vestingPeriod * 2 &&
          currentDate < liquidityAdded + vestingPeriod * 3
        ) {
          // third claim, 20%
          if (claimed.isLessThanOrEqualTo(percFromTotal1.plus(percFromTotal2))) {
            canUserClaim = true;
            readyToClaim = percFromTotal2;
          }
          if (claimed.isLessThanOrEqualTo(percFromTotal1)) {
            canUserClaim = true;
            readyToClaim = percFromTotal2.multipliedBy(2);
          }
          if (claimed.isEqualTo(0)) {
            canUserClaim = true;
            readyToClaim = percFromTotal1.plus(percFromTotal2.multipliedBy(2));
          }
        }
        if (
          currentDate > liquidityAdded + vestingPeriod * 3 &&
          currentDate < liquidityAdded + vestingPeriod * 4
        ) {
          // forth claim, 20%
          if (claimed.isLessThanOrEqualTo(percFromTotal1.plus(percFromTotal2.multipliedBy(2)))) {
            canUserClaim = true;
            readyToClaim = percFromTotal2;
          }
          if (claimed.isLessThanOrEqualTo(percFromTotal1.plus(percFromTotal2))) {
            canUserClaim = true;
            readyToClaim = percFromTotal2.multipliedBy(2);
          }
          if (claimed.isLessThanOrEqualTo(percFromTotal1)) {
            canUserClaim = true;
            readyToClaim = percFromTotal2;
          }
          if (claimed.isEqualTo(0)) {
            canUserClaim = true;
            readyToClaim = percFromTotal1.plus(percFromTotal2.multipliedBy(3));
          }
        }
        if (
          currentDate > liquidityAdded + vestingPeriod * 4 &&
          currentDate < liquidityAdded + vestingPeriod * 5
        ) {
          // fifth claim, 20%
          if (claimed.isLessThanOrEqualTo(percFromTotal1.plus(percFromTotal2.multipliedBy(3)))) {
            canUserClaim = true;
            readyToClaim = percFromTotal2;
          }
          if (claimed.isLessThanOrEqualTo(percFromTotal1.plus(percFromTotal2.multipliedBy(2)))) {
            canUserClaim = true;
            readyToClaim = percFromTotal2.multipliedBy(2);
          }
          if (claimed.isLessThanOrEqualTo(percFromTotal1.plus(percFromTotal2))) {
            canUserClaim = true;
            readyToClaim = percFromTotal2.multipliedBy(3);
          }
          if (claimed.isLessThanOrEqualTo(percFromTotal1)) {
            canUserClaim = true;
            readyToClaim = percFromTotal2.multipliedBy(4);
          }
          if (claimed.isEqualTo(0)) {
            canUserClaim = true;
            readyToClaim = percFromTotal1.plus(percFromTotal2.multipliedBy(4));
          }
        }
        if (currentDate > liquidityAdded + vestingPeriod * 5) {
          // last claim 10%
          if (claimed.isLessThan(total)) {
            canUserClaim = true;
            readyToClaim = total.minus(claimed);
          }
        }
      }

      setUserData({
        isUserWinLottery: lotteryWhitelist,
        isUserRegistered: +registerLevels.level > 0,
        isUserVoted,
        canUserInvest,
        investments: {
          amountEth: WalletService.weiToEth(
            investments.amountEth.toString(),
            tokenDecimals,
          ).toString(),
          amountTokens: WalletService.weiToEth(
            investments.amountTokens.toString(),
            tokenDecimals,
          ).toString(),
          amountClaimed: WalletService.weiToEth(
            investments.amountClaimed.toString(),
            tokenDecimals,
          ).toString(),
          readyToClaim: WalletService.weiToEth(readyToClaim.toString(), tokenDecimals).toString(),
        },
        liquidityAdded,
        canUserClaim,
        addLiquidityLocked,
        maxUserInvestment: WalletService.weiToEth(
          getMaxInvestment.toString(),
          tokenDecimals,
        ).toString(),
      });
      setRefreshData(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }, [userAddress, walletService]);

  useEffect(() => {
    if (projectId === 0 && refreshData) {
      getIdoData();
    }
  }, [getIdoData, projectData.creator, projectId, refreshData]);

  useEffect(() => {
    if (userAddress && refreshData && +userLevel >= 0) {
      getIdoUserData();
    }
  }, [getIdoUserData, refreshData, userAddress, userLevel]);

  useEffect(() => {
    setRefreshData(true);
  }, []);

  return [projectData, userData, isDataLoading, handleRefreshData];
};

export default usePresaleData;
