import { createContext, FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { observer } from 'mobx-react-lite';
import rootStore from 'store';

import { chains } from 'config';

import { WalletService } from 'services/WalletService';
import { chainsEnum } from 'types';

declare global {
  interface Window {
    ethereum: any;
  }
}

const WalletConnectContext = createContext<{
  connect: (
    chainName: chainsEnum,
    providerName: 'Metamask' | 'WalletConnect' | string,
  ) => Promise<void>;
  disconnect: () => void;
  walletService: WalletService;
}>({
  connect: async (): Promise<void> => {},
  disconnect: (): void => {},
  walletService: new WalletService(),
});

const Connect: FC = observer(({ children }) => {
  const provider = useRef<WalletService>(
    new WalletService(chains['Binance-Smart-Chain'].network.rpc as string),
  );

  const disconnect = useCallback(() => {
    delete localStorage.quack_logged;
    delete localStorage.quack_wallet;
    provider.current.connectWallet.resetConect();
    rootStore.user.disconnect();
  }, []);

  const connect = useCallback(
    async (chainName: chainsEnum, providerName: 'Metamask' | 'WalletConnect' | string) => {
      try {
        const isConnected = await provider.current.initWalletConnect(
          chainName,
          providerName as any,
        );

        if (isConnected) {
          try {
            const { address, type }: any = await provider.current.getAccount();
            localStorage.quack_wallet = type;
            provider.current.setAccountAddress(address);
            rootStore.user.setAddress(address);
            localStorage.quack_logged = true;

            const eventSubs = provider.current.connectWallet.eventSubscriber().subscribe(
              (res: any) => {
                if (res.name === 'accountsChanged' && rootStore.user.address !== res.address) {
                  disconnect();
                }
              },
              (err: any) => {
                console.error(err);
                eventSubs.unsubscribe();
                disconnect();
              },
            );
          } catch (err: any) {
            console.error('getAccount wallet connect - get user account err: ', err);
            if (!(err.code && err.code === 6)) {
              disconnect();
            }
          }
        }
      } catch (err) {
        console.error('connect: provider.initWalletConnect', err);
        disconnect();
      }
    },
    [disconnect],
  );

  const walletConnectValue = useMemo(() => {
    return { connect, disconnect, walletService: provider.current };
  }, [connect, disconnect, provider]);

  useEffect(() => {
    if (window.ethereum && localStorage.quack_logged) {
      connect(chainsEnum['Binance-Smart-Chain'], 'MetaMask').then();
    }
  }, [connect]);

  return (
    <WalletConnectContext.Provider value={walletConnectValue}>
      {children}
    </WalletConnectContext.Provider>
  );
});
export default Connect;

export const useWalletConnectorContext = (): {
  connect: (chainName: chainsEnum, providerName: string) => Promise<any>;
  disconnect: () => void;
  walletService: WalletService;
} => {
  return useContext(WalletConnectContext);
};
