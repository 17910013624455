import { FC, useCallback } from 'react';

import { filterItems } from 'utils/constants';

import Selector from '../../components/Selector';

import s from './FilterBar.module.scss';

interface IFilterProps {
  onFilter: (item: any, value: any) => void;
}

const FilterBar: FC<IFilterProps> = ({ onFilter }) => {
  const handleSelect = useCallback(
    (item, value) => {
      onFilter(item, value);
    },
    [onFilter],
  );
  return (
    <div className={s.filter_wrapper}>
      {filterItems.map((item) => {
        return (
          <div key={item.label} className={s.filter_item}>
            <span className={s.label}>{item.label}</span>
            <Selector
              className={s.select_inner_wrapper}
              defaultOption={item.defaultOption}
              options={item.othersOptions}
              onSelect={(value) => handleSelect(item, value)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default FilterBar;
