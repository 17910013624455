import { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import cn from 'classnames';

import { Button, Progress, StatusLabel } from 'components';
import { convertToDaysLeft, getRegisterDate } from 'utils';

import { usePresaleData, useProjectStatusTextSimple } from 'hooks';
import { TUserPresale } from 'hooks/usePresaleData';
import { IProjectCard } from 'types';

import projectImg from 'assets/img/mock/project-img.png';

import s from './ProjectCard.module.scss';

interface IProjectCardProps {
  project: IProjectCard;
  className?: string;
}

interface IBtnWrapperProps {
  status: string;
  userData: TUserPresale;
}

const BtnWrapper: FC<IBtnWrapperProps> = observer(({ status, userData }) => {
  const {
    user,
    modals: { walletConnect },
  } = useMst();
  const handleConnect = useCallback(() => {
    walletConnect.toggle();
  }, [walletConnect]);

  if (!user.address) {
    return (
      <Button className={s.btn} color="filled" onClick={handleConnect} size="medium" isFullWidth>
        Connect wallet
      </Button>
    );
  }

  if ((status === 'upcoming' || status === 'register') && user.level === 0) {
    return (
      <Button className={s.btn} color="filled" href="/levels" size="medium" isFullWidth>
        Stake for Levels
      </Button>
    );
  }
  if ((status === 'upcoming' || status === 'register') && !userData.isUserRegistered) {
    return (
      <Button
        className={s.btn}
        color="filled"
        href="/launchpad/earthling"
        size="medium"
        isFullWidth
      >
        Register now
      </Button>
    );
  }
  if (status === 'open' && user.level > 0 && user.level < 4 && userData.isUserWinLottery) {
    return (
      <Button
        className={s.btn}
        color="filled"
        href="/launchpad/earthling"
        size="medium"
        isFullWidth
      >
        Invest
      </Button>
    );
  }

  return (
    <Button className={s.btn} color="filled" href="/launchpad/earthling" size="medium" isFullWidth>
      DYOR
    </Button>
  );
});

const ProjectCard: FC<IProjectCardProps> = observer(({ project, className }) => {
  const { user } = useMst();
  const [projectData, userData] = usePresaleData(project.id, user.address, user.level);
  const statusText = useProjectStatusTextSimple(projectData.status, projectData.round, 4);
  if (projectData.status === 'voting' || projectData.status === 'voting failed') {
    return <></>;
  }
  return (
    <div className={cn(s.project_card_wrapper, className)}>
      <div className={s.img_wrapper}>
        <img
          className={s.project_img}
          src={project.image ? project.image : projectImg}
          alt="projectImage"
        />
        <div className={s.status_wrapper}>
          <StatusLabel text={statusText[0]} />
        </div>
      </div>
      <div className={s.project_content_wrapper}>
        <div className={s.logos_wrapper}>
          <div className={s.avatar_wrapper}>
            <img className={s.user_avatar} src={project.userAvatar} alt="userAvatar" />
            <img className={s.currency} src={project.currencyLogo} alt="currency" />
          </div>
        </div>
        <div className={s.access}>{project.access}</div>
        <div className={s.name}>{project.name}</div>
        <div className={s.description}>{project.description}</div>
        {projectData.status === 'upcoming' && (
          <>
            <div className={s.subtitle}>Registration Opens</div>
            <div className={s.raised}>{getRegisterDate(projectData.registrationOpens)}</div>
          </>
        )}
        {projectData.status === 'register' && (
          <>
            <div className={s.subtitle}>Registration Closed</div>
            <div className={s.raised}>{getRegisterDate(projectData.registrationClosed)}</div>
          </>
        )}
        {projectData.status === 'register-closed' && (
          <>
            <div className={s.subtitle}>Sales Start</div>
            <div className={s.raised}>{getRegisterDate(projectData.salesStart, false)}</div>
          </>
        )}
        {(projectData.status === 'open' ||
          projectData.status === 'completed success' ||
          projectData.status === 'completed fail') && (
          <>
            <div className={s.subtitle}>Total Raised</div>
            <div className={s.raised}>{projectData.totalRaised}</div>
          </>
        )}
        <div className={s.progress_wrapper}>
          <Progress
            totalRaised={+projectData.totalRaised}
            all={+projectData.hardCap}
            className={s.progress}
            name="Progress"
            titleClass={s.header}
          />
          <div className={s.progress_numbers}>
            {projectData.totalRaised}/{projectData.hardCap}
          </div>
          {projectData.status === 'open' && (
            <div className={s.progress_numbers}>{convertToDaysLeft(projectData.salesEnd)}</div>
          )}
        </div>
        <div className={s.container}>
          <div className={s.item}>
            {projectData.status === 'upcoming' && (
              <>
                <div className={s.item_title}>Voters</div>
                <div className={s.item_content}>{projectData.totalVoters}</div>
              </>
            )}
            {projectData.status === ('registered' || 'register-closed') ? (
              <>
                <div className={s.item_title}>Registered</div>
                <div className={s.item_content}>{projectData.totalRegistered}</div>
              </>
            ) : (
              <>
                <div className={s.item_title}>Bakers</div>
                <div className={s.item_content}>{projectData.totalRegistered}</div>
              </>
            )}
          </div>
          <div className={s.item}>
            <div className={s.item_title}>Price per Token</div>
            <div className={s.item_content}>{projectData.tokenPrice} USDT</div>
          </div>
          <div className={s.item}>
            <div className={s.item_title}>Access</div>
            <div className={s.item_content}>{project.access}</div>
          </div>
          <div className={s.item}>
            {projectData.status === 'completed' ? (
              <>
                <div className={s.item_title}>ATH</div>
                <div className={s.item_content}>-</div>
              </>
            ) : (
              <>
                <div className={s.item_title}>Round</div>
                <div className={s.item_content}>{projectData.round}</div>
              </>
            )}
          </div>
        </div>
        <BtnWrapper status={projectData.status} userData={userData} />
      </div>
    </div>
  );
});

export default ProjectCard;
