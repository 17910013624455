import { ChangeEvent, FC, useCallback, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import BigNumber from 'bignumber.js/bignumber';
import cn from 'classnames';

import { Button, Input, Modal } from 'components';
import { contracts } from 'config';
import checkValueDecimals from 'utils/checkValueDecimals';

import useApprove from '../../../hooks/useApprove';
import { useWalletConnectorContext } from 'services';
import { IModalProps } from 'types';

import BscImg from 'assets/img/icons/chains/bsc.svg';

// import firstCircleImg from 'assets/img/mock/staking/Ellipse297.png';
import s from './InvestModal.module.scss';

interface IInvestModal extends IModalProps {
  handleOpenCongratulationModal: () => void;
  tokenPrice: string;
  tokenSymbol: string;
  tokenDecimals: number;
  maxInvestment: string;
  presaleType?: string;
  presaleAddress?: string;
}

const InvestModal: FC<IInvestModal> = observer(
  ({
    visible,
    onClose,
    handleOpenCongratulationModal,
    tokenPrice,
    tokenSymbol,
    tokenDecimals,
    maxInvestment,
    presaleType,
    presaleAddress,
  }) => {
    const { walletService } = useWalletConnectorContext();
    const { user, quack } = useMst();
    const [usdtAmount, setUsdtAmount] = useState('');
    const [quackAmount, setQuackAmount] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [investInputError, setInvestInputError] = useState(false);
    const [getInputError, setGetInputError] = useState(false);

    const [isApproved, isApproving, handleApprove, approveError] = useApprove({
      tokenName: 'USDT',
      tokenAddress: contracts.params.USDT[contracts.type].address,
      approvedContractName: presaleType
        ? `PRESALE_${presaleType.toUpperCase()}`
        : 'PRESALE_PUBLIC_TEST',
      amount: usdtAmount,
      walletAddress: user.address,
      approvedContractAddress: presaleAddress || undefined,
    });

    const handleMax = useCallback(() => {
      const value = checkValueDecimals(maxInvestment, 18);
      setQuackAmount(value);
      const getAmount = new BigNumber(tokenPrice).dividedBy(1).multipliedBy(value);
      setUsdtAmount(getAmount.toString());
      if (getAmount.isGreaterThan(+maxInvestment)) {
        setGetInputError(true);
      } else {
        setGetInputError(false);
      }
    }, [maxInvestment, tokenPrice]);

    const handleChangeUsdtAmount = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = checkValueDecimals(e.target.value, 18);
        setUsdtAmount(value);
        const quackValue = new BigNumber(1)
          .dividedBy(tokenPrice)
          .multipliedBy(value)
          .toFixed(10)
          .toString();
        setQuackAmount(quackValue);
        if (value === '') {
          setQuackAmount('');
        }
        if (value === '0') {
          setQuackAmount('0');
        }
        if (+quackValue > +maxInvestment) {
          setGetInputError(true);
          return;
        }
        if (+value > +user.usdtBalance) {
          setInvestInputError(true);
          return;
        }
        setGetInputError(false);
        setInvestInputError(false);
      },
      [maxInvestment, tokenPrice, user.usdtBalance],
    );
    const handleChangeQuackAmount = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = checkValueDecimals(e.target.value, tokenDecimals);
        setQuackAmount(value);
        setUsdtAmount(
          new BigNumber(tokenPrice).dividedBy(1).multipliedBy(value).toFixed(10).toString(),
        );
        if (value === '') {
          setUsdtAmount('');
        }
        if (value === '0') {
          setUsdtAmount('0');
        }
        if (+value > +maxInvestment) {
          setGetInputError(true);
        } else {
          setGetInputError(false);
        }
      },
      [maxInvestment, tokenDecimals, tokenPrice],
    );

    const handleInvest = useCallback(async () => {
      try {
        setLoading(true);
        const finalAmount = await walletService.calcTransactionAmount(
          contracts.params.USDT[contracts.type].address,
          usdtAmount,
        );
        await walletService.createTransaction({
          method: 'invest',
          contract: presaleType ? `PRESALE_${presaleType.toUpperCase()}` : 'PRESALE_PUBLIC_TEST',
          contractAddress: presaleAddress || undefined,
          data: [finalAmount],
        });
        setLoading(false);
        setUsdtAmount('');
        setQuackAmount('');
        onClose();
        handleOpenCongratulationModal();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setLoading(false);
      }
    }, [
      usdtAmount,
      handleOpenCongratulationModal,
      onClose,
      presaleAddress,
      presaleType,
      walletService,
    ]);

    return (
      <Modal className={s.modal_wrapper} visible={visible} onClose={onClose}>
        <div className={s.invest_wrapper}>
          <div className={s.title}>Invest</div>

          <div className={s.input_wrapper}>
            <Input
              onChange={handleChangeUsdtAmount}
              value={usdtAmount}
              positiveOnly
              className={s.invest_input}
              isNumber
              // max={+user.usdtBalance}
              color="dark"
              error={
                investInputError && approveError === ''
                  ? 'The value exceeds the number of tokens in your wallet.'
                  : approveError
              }
              title={
                <div className={s.invest_input__title}>
                  <div className="text-600 text-sm text-gray text-capitalize">You invest</div>
                  <div className={s.invest_input__title__wrapper}>
                    <div className="text-sm text-gray">
                      Balance {new BigNumber(user.usdtBalance).toFixed(7)}
                    </div>
                  </div>
                </div>
              }
              postfix={
                <div className={s.invest_input__prefix}>
                  {/* <Button size="small" className={s.invest_input__prefix__btn} onClick={handleMax}> */}
                  {/*  <span className={s.btn_text}>MAX</span> */}
                  {/* </Button> */}
                  <div className={s.invest_input__prefix__img}>
                    <img src={BscImg} alt="" />
                  </div>
                  <div className={cn(s.invest_input__prefix__text, 'text-gray')}>USDT</div>
                </div>
              }
            />
            <Input
              onChange={handleChangeQuackAmount}
              value={quackAmount}
              positiveOnly
              className={s.invest_input}
              isNumber
              max={+user.usdtBalance / quack.data.price}
              color="dark"
              error={
                getInputError ? 'The value exceeds the number of max investments on your level' : ''
              }
              title={
                <div className={s.invest_input__title}>
                  <div className="text-600 text-sm text-gray text-capitalize">You will get</div>
                  <div className={s.invest_input__title__wrapper}>
                    <div className="text-sm text-gray">Max Investment {maxInvestment}</div>
                  </div>
                </div>
              }
              postfix={
                <div className={s.invest_input__prefix}>
                  <Button size="small" className={s.invest_input__prefix__btn} onClick={handleMax}>
                    <span className={s.btn_text}>MAX</span>
                  </Button>
                  {/* <div className={s.invest_input__prefix__img}> */}
                  {/* <img src={firstCircleImg} alt="" /> */}
                  {/* </div> */}
                  <div className={cn(s.invest_input__prefix__text, 'text-gray')}>{tokenSymbol}</div>
                </div>
              }
            />
          </div>
          <div className={s.invest_btns}>
            <Button size="medium" onClick={onClose}>
              Cancel
            </Button>
            {!isApproved ? (
              <Button
                color="filled"
                size="medium"
                onClick={handleApprove}
                disabled={
                  usdtAmount === '' || usdtAmount === '0' || investInputError || getInputError
                }
                loading={isApproving}
              >
                Approve
              </Button>
            ) : (
              <Button
                color="filled"
                size="medium"
                onClick={handleInvest}
                disabled={
                  usdtAmount === '' || usdtAmount === '0' || investInputError || getInputError
                }
                loading={isLoading}
              >
                Confirm
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  },
);

export default InvestModal;
