import { FC, PropsWithChildren } from 'react';

type ITabProps = {
  label: string;
};
const Tab: FC<PropsWithChildren<ITabProps>> = ({ label, children }) => {
  return <div id={label}>{children}</div>;
};

export default Tab;
