import { useCallback, useEffect, useRef, useState } from 'react';

import {
  addMinutes,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  getTime,
  toDate,
} from 'date-fns';

import { BROKEN_PRESALE_ADDRESS } from 'config';

import { BREAK_DURATION } from './usePresale';

const FAULT_DURATION = 11500;

const useProjectStatusText = (
  type: string,
  status: string,
  round: string,
  stage: number,
  roundBreak: number,
  stageMultipliers: number[],
  moonEnd: number,
  diamondEnd: number,
  paperEnd: number,
  stagesEnd: number[],
  handleRefresh: () => void,
  presaleAddress?: string,
): [string[], boolean] => {
  const [statusText, setStatusText] = useState(['', '']);
  const timer = useRef<any>(null);
  const [deadline, setDeadline] = useState(0);
  const [countdown, setCountdown] = useState({
    hours: '00',
    minutes: '00',
    // seconds: '00',
  });
  const [isRefreshing, setIsRefreshing] = useState(false);

  const getStatusLabelText = useCallback(() => {
    // broken presale
    if (presaleAddress === BROKEN_PRESALE_ADDRESS) {
      setStatusText(['completed', 'success']);
      return true;
    }
    if (status === 'completed success') {
      setStatusText(['completed', 'success']);
      return true;
    }
    if (status === 'completed fail') {
      setStatusText(['completed', 'fail']);
      return true;
    }
    if (status === 'voting failed') {
      setStatusText(['voting', 'failed']);
      return true;
    }
    if (status === 'open') {
      if (round === 'break') {
        setStatusText([status, `Break Time left ${countdown.hours}h:${countdown.minutes}m`]);
        return true;
      }
      if (round === 'moon' || round === 'paper') {
        setStatusText([status, `${round} Time left ${countdown.hours}h:${countdown.minutes}m`]);
        return true;
      }
      if (round === 'diamond') {
        setStatusText([
          status,
          `${round} (${stageMultipliers[stage]}x) Time left ${countdown.hours}h:${countdown.minutes}m`,
        ]);
        return true;
      }
    }
    setStatusText([status, 'Progress']);
    return true;
  }, [countdown.hours, countdown.minutes, presaleAddress, round, stage, stageMultipliers, status]);

  const getCountdown = useCallback(() => {
    const now = new Date();
    const diff = differenceInMilliseconds(+deadline, now);
    let difHours: string | number = '00';
    let difMinutes: string | number = '00';
    // let difSeconds: string | number = '00';
    if (diff > 0) {
      difHours = differenceInHours(deadline, now);
      difMinutes = differenceInMinutes(deadline, now) % 60;
      // difSeconds = differenceInSeconds(deadline, now) % 60;

      setCountdown({
        hours: difHours < 10 ? `0${difHours}` : difHours.toString(),
        minutes: difMinutes < 10 ? `0${difMinutes}` : difMinutes.toString(),
        // seconds: difSeconds < 10 ? `0${difSeconds}` : difSeconds.toString(),
      });
      if (diff > 0 && diff < FAULT_DURATION) {
        setIsRefreshing(true);
      }
    } else {
      clearInterval(timer.current);
      handleRefresh();
      setIsRefreshing(false);
    }
  }, [deadline, handleRefresh]);

  useEffect(() => {
    if (status) {
      getStatusLabelText();
    }
  }, [getStatusLabelText, isRefreshing, status]);

  useEffect(() => {
    if (status === 'open' && type !== 'private') {
      if (round === 'break' && +roundBreak === 1) {
        const roundDeadline =
          getTime(addMinutes(toDate(moonEnd), +BREAK_DURATION)) + FAULT_DURATION;
        setDeadline(roundDeadline);
      }
      if (round === 'break' && +roundBreak === 2) {
        const roundDeadline =
          getTime(addMinutes(toDate(diamondEnd), +BREAK_DURATION)) + FAULT_DURATION;
        setDeadline(roundDeadline);
      }
      if (round === 'moon') {
        setDeadline(moonEnd + FAULT_DURATION);
      }
      if (round === 'diamond') {
        setDeadline(stagesEnd[stage] + FAULT_DURATION);
      }
      if (round === 'paper') {
        setDeadline(paperEnd + FAULT_DURATION);
      }
      timer.current = setInterval(() => {
        getCountdown();
      }, 1000);
    }
    return () => clearInterval(timer.current);
  }, [
    diamondEnd,
    getCountdown,
    moonEnd,
    paperEnd,
    round,
    roundBreak,
    stage,
    stagesEnd,
    status,
    type,
  ]);

  return [statusText, isRefreshing];
};

export default useProjectStatusText;
