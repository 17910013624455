import { FC, PropsWithChildren, ReactElement } from 'react';

import cn from 'classnames';

import s from './AuditItem.module.scss';

interface IAuditItemProps {
  status: string;
  title: string | ReactElement;
  icon: string;
  auditedBy: string;
}
const AuditItem: FC<PropsWithChildren<IAuditItemProps>> = ({
  status,
  title,
  icon,
  auditedBy,
  children,
}) => {
  if (status === 'error') {
    return (
      <div className={cn(s.audit, s[status])}>
        <div className={s.head}>
          <div className={cn(s.icon, s.error)}>
            <img src={icon} alt="icon" />
          </div>
          <div>
            <div className={s.title}>{title}</div>
            {children && <div className={cn(s.content, s.error)}>{children}</div>}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={cn(s.audit, s[status])}>
      <div className={s.head}>
        <div className={s.icon}>
          <img src={icon} alt="icon" />
        </div>
        <div className={s.title}>{title}</div>
        <div className={s.audited_by}>
          By <span>{auditedBy}</span>
        </div>
      </div>
      {children && <div className={s.content}>{children}</div>}
    </div>
  );
};

export default AuditItem;
