import React, { useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import {
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';

import { statusEnum } from 'types';

import s from './Timer.module.scss';

interface ITimer {
  deadline: number;
  inDays?: boolean;
  size?: 'default' | 'mini' | 'medium';
  presaleStatus?: string;
  customCallback: () => void;
}

const Timer: React.FC<ITimer> = ({
  deadline,
  inDays,
  size = 'default',
  presaleStatus,
  customCallback,
}) => {
  const timer = useRef<any>(null);
  const [timerRunning, setTimerRunning] = useState(true);
  const [countdown, setCountdown] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  const getCountdown = useCallback(() => {
    const now = new Date();
    const diff = differenceInMilliseconds(+deadline, now);
    let difDays: string | number = '00';
    let difHours: string | number = '00';
    let difMinutes: string | number = '00';
    let difSeconds: string | number = '00';
    if (diff > 0) {
      if (inDays) {
        difDays = differenceInDays(deadline, now);
        difHours = differenceInHours(deadline, now);
        difHours -= 24 * difDays;
      } else {
        difHours = differenceInHours(deadline, now);
      }
      difMinutes = differenceInMinutes(deadline, now) % 60;
      difSeconds = differenceInSeconds(deadline, now) % 60;

      setCountdown({
        days: difDays < 10 ? `0${difDays}` : difDays.toString(),
        hours: difHours < 10 ? `0${difHours}` : difHours.toString(),
        minutes: difMinutes < 10 ? `0${difMinutes}` : difMinutes.toString(),
        seconds: difSeconds < 10 ? `0${difSeconds}` : difSeconds.toString(),
      });
    } else {
      clearInterval(timer.current);
      setTimerRunning(false);
    }
  }, [deadline, inDays]);

  useEffect(() => {
    timer.current = setInterval(() => {
      getCountdown();
    }, 1000);
    return () => clearInterval(timer.current);
  }, [getCountdown]);

  useEffect(() => {
    if (
      !timerRunning &&
      presaleStatus &&
      presaleStatus !== statusEnum.votingFailed &&
      presaleStatus !== statusEnum.completedFail &&
      presaleStatus !== statusEnum.completedSuccess
    ) {
      return customCallback();
    }
    return () => {};
  }, [customCallback, presaleStatus, timerRunning]);

  return (
    <div className={cn(s.timer, s[size])}>
      {inDays ? (
        <div className={s.unit_wrapper}>
          {countdown.days}
          <span className={s.unit_type}>Days</span>
        </div>
      ) : null}
      <span>:</span>
      <div className={s.unit_wrapper}>
        {countdown.hours}
        <span className={s.unit_type}>Hours</span>
      </div>
      <span>:</span>
      <div className={s.unit_wrapper}>
        {countdown.minutes}
        <span className={s.unit_type}>Min</span>
      </div>
      <span>:</span>
      <div className={s.unit_wrapper}>
        {countdown.seconds}
        <span className={s.unit_type}>Sec</span>
      </div>
    </div>
  );
};

export default Timer;
