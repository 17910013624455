/* eslint-disable no-console */
import React from 'react';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import BigNumber from 'bignumber.js/bignumber';
import cn from 'classnames';

import { Button, Input, Modal } from 'components';
// import { useMst } from 'store';
import { contracts } from 'config';

import { useTokenBalance } from 'hooks';
import { useWalletConnectorContext, WalletService } from 'services';
import { IModalProps } from 'types';

import firstCircleImg from 'assets/img/mock/staking/Ellipse297.png';

import style from './StakeUnstakeModal.module.scss';

interface IStakeUnstakeModal extends IModalProps {
  type: 'stake' | 'unstake';
  handleOpenCongratulationModal: () => void;
  fee: string;
  handleGetTotalStaked: () => void;
}

const StakeUnstakeModal: React.FC<IStakeUnstakeModal> = observer(
  ({ visible, onClose, type, handleOpenCongratulationModal, fee, handleGetTotalStaked }) => {
    const { walletService } = useWalletConnectorContext();
    const { user } = useMst();
    const [balance] = useTokenBalance(user.address, contracts.params.QUACK[contracts.type].address);
    const [amount, setAmount] = React.useState('');
    const [isApproved, setApproved] = React.useState(true);
    const [isLoading, setLoading] = React.useState(false);
    const [userStakedAmount, setUserStakedAmount] = React.useState('0');

    const hanldeChangeAmount = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const decimals = value.toString().split('.')[1] ? value.toString().split('.')[1].length : 0;
      if (decimals > 9) {
        setAmount(new BigNumber(value).toFixed(9, 1));
      } else {
        setAmount(value);
      }
    }, []);

    const handleApprove = React.useCallback(() => {
      setLoading(true);
      walletService
        .approveTokenOld({
          contractName: 'QUACK',
          approvedAddress: contracts.params.STAKING_OLD[contracts.type].address,
        })
        .then(() => {
          setApproved(true);
        })
        .catch((err) => {
          console.log('err approve stake', err);
          setApproved(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [walletService]);

    const handleStakeUnstake = React.useCallback(() => {
      setLoading(true);
      walletService
        .createTransaction({
          method: type === 'stake' ? 'deposit' : 'withdraw',
          data: [WalletService.calcTransactionAmount(amount, 9)],
          contract: 'STAKING_OLD',
        })
        .then(() => {
          handleGetTotalStaked();
          onClose();
          handleOpenCongratulationModal();
        })
        .catch((err) => {
          console.log('err stake', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [handleOpenCongratulationModal, onClose, walletService, amount, type, handleGetTotalStaked]);

    const available = React.useMemo(() => {
      return type === 'stake' ? balance : userStakedAmount;
    }, [type, balance, userStakedAmount]);

    const handleMax = React.useCallback(() => {
      setAmount(available);
    }, [available]);

    const handleClose = React.useCallback(() => {
      setAmount('');
      onClose();
    }, [onClose]);

    React.useEffect(() => {
      if (amount && type === 'stake') {
        walletService
          .checkTokenAllowance({
            contractName: 'QUACK',
            contractAddress: contracts.params.QUACK[contracts.type].address,
            approvedAddress: contracts.params.STAKING_OLD[contracts.type].address,
            amount,
          })
          .then((res) => {
            console.log(res, 'allowance');
            setApproved(res);
          })
          .catch((err) => {
            setApproved(false);
            console.log('check approve stake modal', err);
          });
      }
    }, [walletService, amount, type]);

    React.useEffect(() => {
      walletService
        .callContractMethod({
          contractName: 'STAKING_OLD',
          methodName: 'stakeInfo',
          data: [user.address],
          contractAddress: contracts.params.STAKING_OLD[contracts.type].address,
          contractAbi: contracts.params.STAKING_OLD[contracts.type].abi,
        })
        .then((res) => {
          setUserStakedAmount(WalletService.weiToEth(res.amount, 9));
        })
        .catch((err) => {
          console.log(err);
        });
    }, [walletService, user.address]);

    return (
      <>
        <Modal visible={visible} onClose={handleClose} className={style.su_modal}>
          <div className={style.su_modal__content}>
            <div className={style.su_modal__title}>
              <span className="text-capitalize">{type}</span> QUACK{' '}
              {type === 'stake' ? 'to' : 'from'} Pool
            </div>
            <div className={cn(style.su_modal__subtitle, 'text-gray')}>
              The Pool is still in beta, please use at your own risk.
            </div>
            <Input
              onChange={hanldeChangeAmount}
              value={amount}
              positiveOnly
              className={style.su_modal__input}
              isNumber
              max={+available}
              color="dark"
              title={
                <div className={style.su_modal__input__title}>
                  <div className="text-600 text-sm text-capitalize">{type}</div>
                  <div className={style.su_modal__input__title__wrapper}>
                    <div className="text-sm">Avail {available}</div>
                    <div className="text-600 text-sm text-upper"> quack</div>
                  </div>
                </div>
              }
              subtitle={
                <div className={style.su_modal__input__title}>
                  <div className="" />
                  <div className="text-sm">*charge {fee}% for fee</div>
                </div>
              }
              postfix={
                <div className={style.su_modal__input__prefix}>
                  <Button size="small" onClick={handleMax}>
                    <span className={style.su_modal__input__btn}>MAX</span>
                  </Button>
                  <div className={style.su_modal__input__prefix__img}>
                    <img src={firstCircleImg} alt="" />
                  </div>
                  <div className={cn(style.su_modal__input__prefix__text, 'text-gray')}>QUACK</div>
                </div>
              }
            />
            <div className={style.su_modal__btns}>
              <Button size="medium" className={style.su_modal__btns__item} onClick={handleClose}>
                Cancel
              </Button>
              {isApproved || type === 'unstake' ? (
                <Button
                  color="filled"
                  size="medium"
                  className={style.su_modal__btns__item}
                  onClick={handleStakeUnstake}
                  disabled={!+amount}
                  loading={isLoading}
                >
                  <div className="text-upper">{type}</div>
                </Button>
              ) : (
                <Button
                  color="filled"
                  size="medium"
                  className={style.su_modal__btns__item}
                  onClick={handleApprove}
                  disabled={!+amount}
                  loading={isLoading}
                >
                  <div className="text-upper">APPROVE COIN</div>
                </Button>
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  },
);

export default StakeUnstakeModal;
