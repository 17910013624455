import { INetwork } from '@amfi/connect-wallet/dist/interface';

import { chainsEnum, IConnectWallet, IContracts } from 'types';

import {
  bep20Abi,
  factoryAbi,
  pancakeFactoryAbi,
  presaleInqubatorAbi,
  presalePrivateAbi,
  presalePublicAbi,
  stakingAbi,
  stakingOldAbi,
  votingAbi,
} from './abi';

export const is_production = true;

export const levelsV1Link = 'https://oldlevels.richquack.com/levels';

export const chains: {
  [key: string]: {
    name: chainsEnum;
    network: INetwork;
    provider: {
      [key: string]: any;
    };
    explorer: string;
  };
} = {
  [chainsEnum['Binance-Smart-Chain']]: {
    name: chainsEnum['Binance-Smart-Chain'],
    network: {
      chainID: is_production ? 56 : 97,
      chainName: is_production ? 'Binance Smart Chain' : 'Binance Smart Chain Testnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpc: is_production
        ? 'https://bsc-dataseed.binance.org/'
        : 'https://bsc-testnet.public.blastapi.io',
      blockExplorerUrl: is_production ? 'https://bscscan.com' : 'https://testnet.bscscan.com',
    },
    provider: {
      MetaMask: { name: 'MetaMask' },
      WalletConnect: {
        img: '',
        name: 'WalletConnect',
        useProvider: 'rpc',
        provider: {
          rpc: {
            rpc: {
              [is_production ? 56 : 97]: is_production
                ? 'https://bsc-dataseed.binance.org/'
                : 'https://data-seed-prebsc-2-s1.binance.org:8545/',
            },
            chainId: is_production ? 56 : 97,
            wcConfig: {
              chains: [is_production ? 56 : 97],
              methods: ['eth_sendTransaction', 'eth_signTypedData_v4', 'eth_sign', 'personal_sign'],
              showQrModal: true,
              projectId: process.env.REACT_APP_WC_PROJECT_ID,
              qrModalOptions: {
                themeVariables: {
                  '--wcm-z-index': '9999',
                },
              },
            },
          },
        },
      },
    },
    explorer: is_production ? 'https://bscscan.com' : 'https://testnet.bscscan.com',
  },
};

export const connectWallet = (chainName: chainsEnum): IConnectWallet => {
  const chain = chains[chainName];

  return {
    wallets: ['MetaMask'],
    network: chain.network,
    provider: chain.provider,
    settings: { providerType: true },
  };
};

export const contracts: IContracts = {
  type: is_production ? 'mainnet' : 'testnet',
  names: [
    'USDT',
    'QUACK',
    'STAKING',
    'PRESALE_PUBLIC_TEST',
    'PRESALE_PUBLIC',
    'PRESALE_PRIVATE',
    'PRESALE_INQUBATOR',
    'FACTORY',
    'STAKING_OLD',
    'VOTING',
    'PANCAKE_FACTORY',
  ],
  params: {
    USDT: {
      mainnet: {
        address: '0x55d398326f99059fF775485246999027B3197955',
        abi: bep20Abi,
      },
      testnet: {
        address: '0xf2789009eBE8b8d4b390233dE21465219a856Bc4',
        abi: bep20Abi,
      },
    },
    LINK: {
      mainnet: {
        address: '0x404460C6A5EdE2D891e8297795264fDe62ADBB75',
        abi: bep20Abi,
      },
      testnet: {
        address: '0x84b9B910527Ad5C03A9Ca831909E21e236EA7b06',
        abi: bep20Abi,
      },
    },
    QUACK: {
      mainnet: {
        address: '0xD74b782E05AA25c50e7330Af541d46E18f36661C',
        abi: bep20Abi,
      },
      testnet: {
        address: '0x452d9e4341519811cc4c25e940f477f53ba0dea1',
        abi: bep20Abi,
      },
    },
    STAKING: {
      mainnet: {
        address: '0x3696C547f5749c3a1C8A68dD5B1Be6929591f778',
        abi: stakingAbi,
      },
      testnet: {
        address: '0x6aF5C291c7f5Caf26E92A4e98DF7Cd1EeC4F3E23',
        abi: stakingAbi,
      },
    },
    PRESALE_PUBLIC_TEST: {
      mainnet: {
        address: '0xc4C546cC2A6D577c8B180A8A071ED96050CcE2DF', // temp,
        abi: presalePublicAbi,
      },
      testnet: {
        address: '0x455472e44245aE9c762dA3748D423908a8d71580',
        abi: presalePublicAbi,
      },
    },
    PRESALE_PUBLIC: {
      mainnet: {
        address: '0x4Eef4272622bfAebEbc4db1D75c41858f686b896',
        abi: presalePublicAbi,
      },
      testnet: {
        address: '0xc4D67392BAa59fE4a7d295D2116949F374D9ddBd',
        abi: presalePublicAbi,
      },
    },
    PRESALE_PRIVATE: {
      mainnet: {
        address: '0x836420290225f68Edc34D84Ec96df4910A394B4A', // temp
        abi: presalePrivateAbi,
      },
      testnet: {
        address: '0xFa622C86E499cC872f4C4c8Bf106E7b19D51F2ab',
        abi: presalePrivateAbi,
      },
    },
    PRESALE_INQUBATOR: {
      mainnet: {
        address: '0xE1466e2E288389de767da98bc556568b8D969e8d', // temp
        abi: presaleInqubatorAbi,
      },
      testnet: {
        address: '0x7f53FA04E7cF7A313Bc8392Bb0692d7f0B81F702',
        abi: presaleInqubatorAbi,
      },
    },
    FACTORY: {
      mainnet: {
        address: '0x4a410df9B9161815F1497A70042e23B038883cEa', // temp
        abi: factoryAbi,
      },
      testnet: {
        address: '0x0EDe8aABa6550B5bB9116186183C6bDa4682e2F0',
        abi: factoryAbi,
      },
    },
    STAKING_OLD: {
      mainnet: {
        address: '0xfab0fd2586e287746aaec8397109b5fe6d2ff053',
        abi: stakingOldAbi,
      },
      testnet: {
        address: '0x3bEeA65fdf4C0C51055675800B142045Ed4c76A2',
        abi: stakingOldAbi,
      },
    },
    VOTING: {
      mainnet: {
        address: '0x4c86b4d36ecf2a92da3d7590c7259acb5c1c0c02',
        abi: votingAbi,
      },
      testnet: {
        address: '0xC290cB0e7f29D19CEfc1Ea62B6A9a5cCe3741e69',
        abi: votingAbi,
      },
    },
    PANCAKE_FACTORY: {
      mainnet: {
        address: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
        abi: pancakeFactoryAbi,
      },
      testnet: {
        address: '0x6725F303b657a9451d8BA641348b6761A6CC7a17',
        abi: pancakeFactoryAbi,
      },
    },
  },
};

export const BROKEN_PRESALE_ADDRESS = '0xa47F367a619DB0c55B9Fa45350c741AA80D52EFd';
