import { PChart, PLock, PProfile } from 'assets/img';

export const progresses = [
  {
    icon: PLock,
    progress: 60,
    label: 'Lock 11 Month',
  },
  {
    icon: PProfile,
    progress: 25,
    label: 'Participants',
  },
  {
    icon: PChart,
    progress: 84,
    label: 'Tokenomics',
  },
];
