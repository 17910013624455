import { ConnectWallet } from '@amfi/connect-wallet';
import { IConnect, IError } from '@amfi/connect-wallet/dist/interface';
import BigNumber from 'bignumber.js/bignumber';
import Web3 from 'web3';
import { PromiEvent, TransactionReceipt } from 'web3-core';
import { AbiItem } from 'web3-utils';

import { connectWallet as connectWalletConfig, contracts } from 'config';
import { bep20Abi } from 'config/abi';

import { chainsEnum } from 'types';

// type TokenAbiType = {
//   [key in chainsEnum]: Array<AbiItem>;
// };

// const tokenAbis: TokenAbiType = {
//   Ethereum: erc20Abi as Array<AbiItem>,
// };

export class WalletService {
  public connectWallet: ConnectWallet;

  public walletAddress = '';

  public web3: any = '';

  public contracts: any = {};

  // private currentChain: chainsEnum = chainsEnum.Ethereum;

  constructor(initProvider?: string) {
    this.connectWallet = new ConnectWallet(initProvider);
    this.web3 = this.connectWallet.currentWeb3();
  }

  public async initWalletConnect(
    chainName: chainsEnum,
    providerName: 'MetaMask' | 'WalletConnect' | string,
  ): Promise<boolean> {
    return new Promise((resolve) => {
      const { provider, network, settings } = connectWalletConfig(chainName);

      const connecting = this.connectWallet
        .connect(provider[providerName], network, settings)
        .then((connected: boolean | {}) => {
          // this.currentChain = chainName;
          return connected;
        })
        .catch(() => {});

      Promise.all([connecting]).then((connect: any) => {
        resolve(connect[0]);
      });
    });
  }

  public logOut(): void {
    this.connectWallet.resetConect();
  }

  public Web3(): Web3 {
    return this.connectWallet.currentWeb3();
  }

  public async getTokenBalance(address: string): Promise<number> {
    const contract = this.connectWallet.getContract({
      address,
      abi: bep20Abi as any[],
    });

    return contract.methods.balanceOf(this.walletAddress).call();
  }

  public async getTokenDecimals(address: string): Promise<number> {
    const contract = this.connectWallet.getContract({
      address,
      abi: bep20Abi as any[],
    });

    return contract.methods.decimals().call();
  }

  public async getTokenSymbol(address: string): Promise<number> {
    const contract = this.connectWallet.getContract({
      address,
      abi: bep20Abi as any[],
    });

    return contract.methods.symbol().call();
  }

  public setAccountAddress(address: string): void {
    this.walletAddress = address;
  }

  public getAccount(): Promise<
    | IConnect
    | IError
    | {
        address: string;
      }
  > {
    return this.connectWallet.getAccounts();
  }

  static getMethodInterface(abi: Array<any>, methodName: string): AbiItem {
    return abi.filter((m) => {
      return m.name === methodName;
    })[0];
  }

  encodeFunctionCall(abi: AbiItem, data: Array<any>): string {
    return this.Web3().eth.abi.encodeFunctionCall(abi, data);
  }

  createTransaction({
    method,
    data,
    contract,
    contractAddress,
    tx,
    walletAddress,
    value,
  }: {
    method: string;
    data: Array<any>;
    contract:
      | 'STAKING'
      | 'STAKING_OLD'
      | 'VOTING'
      | 'PRESALE_PUBLIC_TEST'
      | 'PRESALE_PUBLIC'
      | 'PRESALE_PRIVATE'
      | 'PRESALE_INQUBATOR'
      | 'FACTORY'
      | string;
    contractAddress?: string;
    tx?: any;
    walletAddress?: string;
    value?: any;
  }): PromiEvent<TransactionReceipt> {
    const transactionMethod = WalletService.getMethodInterface(
      contracts.params[contract][contracts.type].abi,
      method,
    );

    let signature;
    if (transactionMethod) {
      signature = this.encodeFunctionCall(transactionMethod, data);
    }

    if (tx) {
      tx.from = walletAddress || this.walletAddress;
      tx.data = signature;

      return this.sendTransaction(tx);
    }
    return this.sendTransaction({
      from: walletAddress || this.walletAddress,
      to: contractAddress || contracts.params[contract][contracts.type].address,
      data: signature || '',
      value: value || '',
    });
  }

  sendTransaction(transactionConfig: {
    from: string;
    to: string;
    data: any;
    value?: any;
  }): PromiEvent<TransactionReceipt> {
    return this.Web3().eth.sendTransaction({
      ...transactionConfig,
      from: this.walletAddress,
    });
  }

  async totalSupply(tokenAddress: string, abi: Array<any>, tokenDecimals: number): Promise<number> {
    const contract = this.connectWallet.getContract({ address: tokenAddress, abi });
    const totalSupply = await contract.methods.totalSupply().call();

    return +new BigNumber(totalSupply).dividedBy(new BigNumber(10).pow(tokenDecimals)).toString(10);
  }

  async checkTokenAllowance({
    contractName,
    contractAddress,
    approvedAddress,
    walletAddress,
    amount,
  }: {
    contractName: string;
    contractAddress: string;
    approvedAddress?: string;
    walletAddress?: string;
    amount?: string | number;
  }): Promise<boolean> {
    try {
      let contract;
      let tokenDecimals;
      if (contracts.params[contractName]) {
        contract = this.connectWallet.getContract({
          address: contracts.params[contractName][contracts.type].address,
          abi: contracts.params[contractName][contracts.type].abi,
        });
        tokenDecimals = await this.getTokenDecimals(
          contracts.params[contractName][contracts.type].address,
        );
      } else {
        contract = this.connectWallet.getContract({
          address: contractAddress,
          abi: contracts.params.USDT[contracts.type].abi,
        });
        tokenDecimals = await this.getTokenDecimals(contractAddress);
      }

      const walletAdr = walletAddress || this.walletAddress;

      let result = await contract.methods.allowance(walletAdr, approvedAddress).call();

      result =
        result === '0'
          ? null
          : +new BigNumber(result).dividedBy(new BigNumber(10).pow(tokenDecimals)).toString(10);
      return !!(result && new BigNumber(result).minus(amount || 0).isPositive());
    } catch (error) {
      return false;
    }
  }

  async approveTokenOld({
    contractName,
    approvedAddress,
    walletAddress,
  }: {
    contractName: string;
    approvedAddress?: string;
    walletAddress?: string;
  }): Promise<any> {
    try {
      const approveMethod = WalletService.getMethodInterface(
        contracts.params[contractName][contracts.type].abi,
        'approve',
      );
      const approveSignature = this.encodeFunctionCall(approveMethod, [
        approvedAddress || walletAddress || this.walletAddress,
        '115792089237316195423570985008687907853269984665640564039457584007913129639935',
      ]);

      return this.sendTransaction({
        from: walletAddress || this.walletAddress,
        to: contracts.params[contractName][contracts.type].address,
        data: approveSignature,
      });
    } catch (error) {
      return error;
    }
  }

  async approveToken({
    contractName,
    contractAddress,
    amountToApprove,
    approvedAddress,
    walletAddress,
  }: {
    contractName: string;
    contractAddress: string;
    amountToApprove: string;
    approvedAddress?: string;
    walletAddress?: string;
  }): Promise<any> {
    try {
      let approveMethod;
      if (contracts.params[contractName]) {
        approveMethod = WalletService.getMethodInterface(
          contracts.params[contractName][contracts.type].abi,
          'approve',
        );
      } else {
        approveMethod = WalletService.getMethodInterface(
          contracts.params.USDT[contracts.type].abi,
          'approve',
        );
      }

      const approveSignature = this.encodeFunctionCall(approveMethod, [
        approvedAddress || walletAddress || this.walletAddress,
        amountToApprove.toString(),
      ]);

      return this.sendTransaction({
        from: walletAddress || this.walletAddress,
        to: contractAddress,
        data: approveSignature,
      });
    } catch (error) {
      return error;
    }
  }

  static async addQuackTokenToMM(): Promise<void> {
    await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: contracts.params.QUACK[contracts.type].address, // The address that the token is at.
          symbol: 'QUACK', // A ticker symbol or shorthand, up to 5 chars.
          decimals: 9, // The number of decimals in the token
        },
      },
    });
  }

  public async calcTransactionAmount(
    tokenContract: string,
    amount: number | string,
  ): Promise<string> {
    if (amount === '0') {
      return amount;
    }
    const tokenDecimals = await this.getTokenDecimals(tokenContract);
    return new BigNumber(amount).times(new BigNumber(10).pow(tokenDecimals)).toString(10);
  }

  // DELETE AFTER OLD STAKING CONTRACT IS GONE
  static calcTransactionAmount(amount: number | string, tokenDecimal: number): string {
    return new BigNumber(amount).times(new BigNumber(10).pow(tokenDecimal)).toString(10);
  }

  static weiToEth(amount: number | string, decimals = 18, fixed?: number): string {
    if (fixed) {
      return new BigNumber(amount).dividedBy(new BigNumber(10).pow(decimals)).toFixed(fixed);
    }
    return new BigNumber(amount).dividedBy(new BigNumber(10).pow(decimals)).toString(10);
  }

  static ethToWei(amount: number | string, decimals = 18): string {
    if (amount === '0') {
      return amount;
    }
    return new BigNumber(amount).multipliedBy(new BigNumber(10).pow(decimals)).toFixed(0, 1);
  }

  static getAddress(contractName: string): string {
    return contracts.params[contractName][contracts.type].address;
  }

  createContract(contractName: string, tokenAddress: string, abi: Array<any>): void {
    if (!this.contracts[contractName]) {
      const contract = this.connectWallet.getContract({ address: tokenAddress, abi });
      this.contracts = {
        ...this.contracts,
        [contractName]: contract,
      };
    }
  }

  async callContractMethod({
    contractName,
    methodName,
    data = [],
    contractAddress,
    contractAbi,
  }: {
    contractName: string;
    methodName: string;
    data?: any[];
    contractAddress: string;
    contractAbi: Array<any>;
  }): Promise<any> {
    try {
      if (!this.contracts[contractName] && contractAddress && contractAbi) {
        this.createContract(contractName, contractAddress, contractAbi);
      }

      if (this.contracts[contractName]) {
        const method = await this.contracts[contractName].methods[methodName];
        return await method(...data).call();
      }
    } catch (err: any) {
      throw new Error(err);
    }
    return new Error(`contract ${contractName} didn't created`);
  }

  public isAddress(value: string): boolean {
    return this.web3().utils.isAddress(value);
  }
}
