import { types } from 'mobx-state-tree';

const WalletConnect = types
  .model({
    isOpen: types.optional(types.boolean, false),
  })
  .actions((self) => {
    return {
      toggle: () => {
        self.isOpen = !self.isOpen;
      },
    };
  });

const UserModal = types
  .model({
    isOpen: types.optional(types.boolean, false),
  })
  .actions((self) => {
    return {
      toggle: () => {
        self.isOpen = !self.isOpen;
      },
    };
  });

const Modals = types.model({
  walletConnect: WalletConnect,
  userModal: UserModal,
});
export default Modals;
