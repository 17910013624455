/* eslint-disable no-console */
import { FC, useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';
import { ILockUpItem } from 'store/Models/User';

import { contracts } from 'config';
import { periodsMock } from 'utils/constants';

import { quackApi, useWalletConnectorContext } from 'services';

import { WalletService } from '../WalletService';

const GetData: FC = ({ children }) => {
  const { walletService } = useWalletConnectorContext();
  const { user, quack } = useMst();
  const [isLoading, setLoading] = useState(false);

  // User Data
  const getUserTokenBalance = useCallback(async () => {
    try {
      const tokenDecimals = await walletService.getTokenDecimals(
        contracts.params.QUACK[contracts.type].address,
      );
      const tokenBalance = await walletService.getTokenBalance(
        contracts.params.QUACK[contracts.type].address,
      );

      quack.setDecimals(+tokenDecimals);
      user.setQuackBalance(WalletService.weiToEth(tokenBalance.toString(), tokenDecimals));
    } catch (err) {
      console.log(err);
    }
  }, [quack, user, walletService]);

  const getUserusdtBalance = useCallback(async () => {
    try {
      const tokenDecimals = await walletService.getTokenDecimals(
        contracts.params.USDT[contracts.type].address,
      );
      const tokenBalance = await walletService.getTokenBalance(
        contracts.params.USDT[contracts.type].address,
      );

      user.setusdtBalance(WalletService.weiToEth(tokenBalance.toString(), tokenDecimals));
    } catch (err) {
      console.log(err);
    }
  }, [user, walletService]);

  const getUserTotalStaked = useCallback(async () => {
    try {
      const tokenDecimals = await walletService.getTokenDecimals(
        contracts.params.QUACK[contracts.type].address,
      );
      const totalStaked = await walletService.callContractMethod({
        contractName: 'STAKING',
        methodName: 'stakeInfo',
        contractAddress: contracts.params.STAKING[contracts.type].address,
        contractAbi: contracts.params.STAKING[contracts.type].abi,
        data: [user.address],
      });
      user.setTotalStaked(WalletService.weiToEth(totalStaked.totalStakedForUser, tokenDecimals));
    } catch (err) {
      console.log(err);
    }
  }, [user, walletService]);

  const getUserTotalStakedByLockUp = useCallback(async () => {
    try {
      const tokenDecimals = await walletService.getTokenDecimals(
        contracts.params.QUACK[contracts.type].address,
      );

      const stakePromises: Array<Promise<any>> = new Array(periodsMock.length)
        .fill(0)
        .map((_, index) =>
          walletService.callContractMethod({
            contractName: 'STAKING',
            methodName: 'stakeForUser',
            contractAddress: contracts.params.STAKING[contracts.type].address,
            contractAbi: contracts.params.STAKING[contracts.type].abi,
            data: [user.address, periodsMock[index].id],
          }),
        );

      Promise.all(stakePromises).then((res) => {
        const lockUpItems = res.map(
          (item: any) =>
            ({
              amountLock: WalletService.weiToEth(item.amountLock, tokenDecimals),
              enteredAt: item.enteredAt,
              // eslint-disable-next-line no-underscore-dangle
              lockEnd: +item.lockUpTime_ || 0,
            } as ILockUpItem),
        );
        user.setLockUps(lockUpItems);
        user.setLevel(+res[0].level);
      });
    } catch (err) {
      console.log(err);
    }
  }, [user, walletService]);

  useEffect(() => {
    if (user.address) {
      setLoading(true);
      getUserTotalStaked().then();
      getUserTokenBalance().then();
      getUserusdtBalance().then();
      getUserTotalStakedByLockUp().then(() => {
        setLoading(false);
      });
      setLoading(false);
    }
  }, [
    getUserusdtBalance,
    getUserTokenBalance,
    getUserTotalStaked,
    getUserTotalStakedByLockUp,
    user.address,
  ]);

  useEffect(() => {
    if (user.refreshUserData) {
      console.log('user refresh data');
      getUserTotalStaked().then();
      getUserTokenBalance().then();
      getUserTotalStakedByLockUp().then();
      user.setRefreshUserData(false);
    }
  }, [
    getUserTokenBalance,
    getUserTotalStaked,
    getUserTotalStakedByLockUp,
    user,
    user.refreshUserData,
  ]);

  // Quack Token Data
  const getQuackData = useCallback(async () => {
    quackApi
      .getQuackData()
      .then(({ data }) => {
        quack.setData({
          circulatingSupply: +data.circulatingSupply,
          marketCap: +data.marketCap,
          maxSupply: +data.maxSupply,
          price: +data.price,
          totalSupply: +data.totalSupply,
        });
      })
      .catch((err) => console.log(err));
  }, [quack]);

  useEffect(() => {
    getQuackData();
  }, [getQuackData]);

  if (isLoading) {
    return <></>;
  }

  return <>{children}</>;
};

export default observer(GetData);
