import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import BigNumber from 'bignumber.js/bignumber';
import { ValidationModal } from 'containers';
import { addDays, addMinutes, getTime, hoursToSeconds } from 'date-fns';
import {
  ProjectCreatorInfoForm,
  ProjectDeepDiveForm,
  ProjectInfoForm,
  SwapPlanForm,
  TokenLockingReportsForm,
  TokenPoolInfoForm,
  TokenVestingForm,
  WhitelistForm,
} from 'forms';
import { IValues as IProjectCreatorInfoValues } from 'forms/ProjectCreatorInfo/container';
import { IValues as IProjectDeepDiveValues } from 'forms/ProjectDeepDive/container';
import { IValues as IProjectInfoValues } from 'forms/ProjectInfo/container';
import { IValues as ISwapPlanValues } from 'forms/SwapPlan/container';
import { IValues as ITokenLockingReportsValues } from 'forms/TokenLockingReports/container';
import { IValues as ITokenPoolInfoValues } from 'forms/TokenPoolInfo/container';
import { IValues as ITokenVestingValues } from 'forms/TokenVesting/container';
import { IValues as IWhitelistValues } from 'forms/WhiteList/container';
import * as Yup from 'yup';

import { CongratulationModal, Steps } from 'components';
import { contracts, is_production } from 'config';

import { useApprove, useCheck, useModal } from 'hooks';
import { presaleApi, useWalletConnectorContext, WalletService } from 'services';

import style from './CreateProject.module.scss';

export interface IForm {
  tokenPoolInfo: ITokenPoolInfoValues;
  projectInfo: IProjectInfoValues;
  swapPlan: ISwapPlanValues;
  whitelist: IWhitelistValues;
  // keyMetrics: IKeyMetricsValue;
  projectDeepDive: IProjectDeepDiveValues;
  tokenLockingReports: ITokenLockingReportsValues;
  tokenVesting: ITokenVestingValues;
  projectCreatorInfo: IProjectCreatorInfoValues;
}

const initialValues: IForm = {
  // tokenPoolInfo: {
  //   tokenContractAddress: '0x452d9e4341519811cc4c25e940f477f53ba0dea1',
  //   ticker: 'TEST',
  //   tokenSupply: 100000000,
  //   tokenPool: 250000,
  //   fundingToken: '',
  //   tokenIdoPrice: 0.00005,
  //   // marketCap: 4200000,
  //   // tokenCirculation: 5000000000000,
  //   softCap: 0.005,
  //   hardCap: 0.01,
  //   saleStartTime: addDays(new Date(), 15), // TODO for tests, change later
  //   saleEndTime: addDays(new Date(), 16),
  //   // saleStartTime: addMinutes(addDays(new Date(), 16), 4),
  //   // saleEndTime: addMinutes(addHours(addDays(new Date(), 16), 9), 6),
  //   // minContribution: 200,
  //   // maxContribution: 400,
  //   // tokenDecimals: 18,
  //   unsoldTokensAddress: '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7',
  // },
  // projectInfo: {
  //   presaleImage: undefined,
  //   presalePreview: '',
  //   title: 'Quack Public Presale',
  //   description: 'description',
  //   logo: 'https://www.richquack.com/',
  //   telegram: 'https://www.richquack.com/',
  //   github: 'https://www.richquack.com/',
  //   twitter: 'https://www.richquack.com/',
  //   website: 'https://www.richquack.com/',
  //   customer: 'https://www.richquack.com/',
  //   whitepaper: 'https://www.richquack.com/',
  //   medium: 'https://www.richquack.com/',
  //   discord: 'https://www.richquack.com/',
  // },
  // swapPlan: {
  //   listingPrice: 0.00008,
  //   listingTime: addMinutes(addDays(new Date(), 16), 5),
  //   // listingTime: addMinutes(addHours(addDays(new Date(), 16), 9), 8),
  //   allocationPercent: 30,
  //   lockedDays: 2,
  //   lockingProvider: 'Team.Finance',
  // },
  // whitelist: {
  //   whitelist: '',
  // },
  // // keyMetrics: {
  // //   privateSale: 11,
  // //   publicSale: 11,
  // //   ecoRewards: 11,
  // //   liquidity: 11,
  // //   marketing: 11,
  // //   team: 11,
  // //   advisor: 11,
  // //   treasury: 11,
  // //   addFillBlank: 12,
  // //   fundraised: 100,
  // //   price: 200,
  // //   lockup: 300,
  // // },
  // projectDeepDive: {
  //   story: '11',
  //   features: '22',
  //   howDo: '33',
  //   marketingPlan: '44',
  //   revenueGeneration: '55',
  //   teamAndAdvisors: '66',
  //   techStack: '77',
  //   investors: '88',
  //   tokenReleaseSchedule: '99',
  // },
  // tokenLockingReports: {
  //   lockingProvider: 'Team.Finance',
  //   lockPurpose: 'Ecosystem Rewards',
  //   expiryDate: addMinutes(new Date(), 5),
  //   proofLink: 'https://www.richquack.com/',
  // },
  // tokenVesting: {
  //   releaseIdo: 33,
  //   vestingSchedule: 'Test',
  //   vestingPercentage: 50,
  // },
  // projectCreatorInfo: {
  //   name: 'test',
  //   email: 'test@test.com',
  //   telegramUsername: 'test',
  // },

  tokenPoolInfo: {
    tokenContractAddress: '',
    ticker: '',
    tokenSupply: undefined,
    tokenPool: undefined,
    fundingToken: '',
    tokenIdoPrice: undefined,
    // marketCap: undefined,
    // tokenCirculation: undefined,
    softCap: undefined,
    hardCap: undefined,
    saleStartTime: addDays(new Date(), 15),
    saleEndTime: addDays(new Date(), 16),
    // minContribution: undefined,
    // maxContribution: undefined,
    // tokenDecimals: undefined,
    unsoldTokensAddress: '',
  },
  projectInfo: {
    presaleImage: undefined,
    presalePreview: '',
    title: '',
    description: '',
    logo: '',
    telegram: '',
    github: '',
    twitter: '',
    website: '',
    customer: '',
    whitepaper: '',
    medium: '',
    discord: '',
  },
  swapPlan: {
    listingPrice: undefined,
    listingTime: addDays(new Date(), 16),
    allocationPercent: 40,
    lockedDays: undefined,
    lockingProvider: 'Team.Finance',
  },
  whitelist: {
    whitelist: '',
  },
  // keyMetrics: {
  //   privateSale: undefined,
  //   publicSale: undefined,
  //   ecoRewards: undefined,
  //   liquidity: undefined,
  //   marketing: undefined,
  //   team: undefined,
  //   advisor: undefined,
  //   treasury: undefined,
  //   addFillBlank: undefined,
  //   fundraised: undefined,
  //   price: undefined,
  //   lockup: undefined,
  // },
  projectDeepDive: {
    story: '',
    features: '',
    howDo: '',
    marketingPlan: '',
    revenueGeneration: '',
    teamAndAdvisors: '',
    techStack: '',
    investors: '',
    tokenReleaseSchedule: '',
  },
  tokenLockingReports: {
    lockingProvider: '',
    lockPurpose: '',
    expiryDate: addMinutes(addDays(new Date(), 16), 10),
    proofLink: '',
  },
  tokenVesting: {
    releaseIdo: undefined,
    vestingSchedule: 'Week',
    vestingPercentage: undefined,
  },
  projectCreatorInfo: {
    name: '',
    email: '',
    telegramUsername: '',
  },
};

const CreateProject: React.FC = observer(() => {
  const { type } = useParams();
  const { user } = useMst();
  const { walletService } = useWalletConnectorContext();

  const stepsWithType = React.useMemo(() => {
    const steps = [
      'Token Pool Info',
      // 'Key Metrics',
      'Project Info & Socials',
      'Project Deep Dive',
      'Token Locking Reports',
      'Token Vesting',
      'PancakeSwap Plan',
      'Project Creator Contract Information',
    ];
    if (type === 'private') {
      steps.push('Whitelisting');
    }
    return steps;
  }, [type]);

  const navigator = useNavigate();
  const [currentStep, setCurrentStep] = useCheck(1);
  const [formsData, setFormsData] = React.useState<IForm>(initialValues);
  const [isVisibleModal, handleOpenModal, handleCloseModal] = useModal(false);
  const [isVisibleValidationModal, handleOpenValidationModal, handleCloseValidationModal] =
    useModal(false);
  const [createPresaleButton, setCreatePresaleButton] = React.useState('Approve');
  const [createPresaleState, setCreatePresaleState] = React.useState('approveToken');
  const [approveTokenAmount, setApproveTokenAmount] = React.useState('0');
  const [isLoading, setLoading] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);

  const [isApprovedToken, isApprovingToken, handleApproveToken] = useApprove({
    tokenName: formsData.tokenPoolInfo.ticker.toUpperCase(),
    tokenAddress: formsData.tokenPoolInfo.tokenContractAddress,
    approvedContractName: 'FACTORY',
    amount: approveTokenAmount,
    walletAddress: user.address,
  });
  const [isApprovedLink, isApprovingLink, handleApproveLink] = useApprove({
    tokenName: 'LINK',
    tokenAddress: contracts.params.LINK[contracts.type].address,
    approvedContractName: 'FACTORY',
    amount: '0.1',
    walletAddress: user.address,
  });

  const handleNext = React.useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep, setCurrentStep]);

  const handleCreatePresale = React.useCallback(
    async (key: keyof IForm, lastFormData: IForm[typeof key]) => {
      // FORM DATA
      let data: any = {};
      Object.keys(formsData).forEach((initKey) => {
        data = {
          ...data,
          ...formsData[initKey as keyof IForm],
          ...lastFormData,
        };
      });
      data.whitelist = data.whitelist === '' ? [] : data.whitelist.split('\n');
      const apiData: any = new FormData();
      Object.keys(data).forEach((initKey) => {
        apiData.append(initKey, data[initKey as keyof IForm]);
      });
      apiData.set('whitelist', data.whitelist.join(' '));
      // Convert date to timestamps
      apiData.set('expiryDate', Math.ceil(getTime(data.expiryDate || 0) / 1000));
      apiData.set('listingTime', Math.ceil(getTime(data.listingTime || 0) / 1000));
      apiData.set('saleStartTime', Math.ceil(getTime(data.saleStartTime || 0) / 1000));
      apiData.set('saleEndTime', Math.ceil(getTime(data.saleEndTime || 0) / 1000));
      // Add project type and network name
      apiData.append('type', type?.toLowerCase());
      apiData.append('network', 'bsc');

      const method = `createPresale${type?.charAt(0).toUpperCase()}${type?.slice(1)}`;
      // const tokenDecimals = await walletService.getTokenDecimals(data.tokenContractAddress);
      const fundingTokenDecimals = await walletService.getTokenDecimals(
        contracts.params.USDT[contracts.type].address,
      );
      const info = {
        creator: user.address,
        tokenAddress: data.tokenContractAddress,
        tokenPrice: WalletService.ethToWei(data.tokenIdoPrice, fundingTokenDecimals).toString(),
        hardCap: WalletService.ethToWei(data.hardCap, fundingTokenDecimals).toString(),
        softCap: WalletService.ethToWei(data.softCap, fundingTokenDecimals).toString(),
        openTime: Math.ceil(getTime(data.saleStartTime || 0) / 1000),
        closeTime: Math.ceil(getTime(data.saleEndTime || 0) / 1000),
        unsoldTokenToAddress: data.unsoldTokensAddress,
      };
      const dexInfo = {
        listingPrice: WalletService.ethToWei(data.listingPrice, fundingTokenDecimals).toString(),
        lpTokensLockDurationInDays: data.lockedDays,
        liquidityPercentageAllocation: data.allocationPercent,
        liquidityAllocationTime: Math.ceil(getTime(data.listingTime || 0) / 1000),
      };
      const vestingInfo = {
        vestingPerc1: data.releaseIdo,
        vestingPerc2: data.vestingPercentage,
        vestingPeriod: 0,
      };
      if (data.vestingSchedule === 'Week') {
        vestingInfo.vestingPeriod = hoursToSeconds(7 * 24);
      }
      if (data.vestingSchedule === 'Month') {
        vestingInfo.vestingPeriod = hoursToSeconds(30 * 24);
      }
      // todo delete in prod
      if (data.vestingSchedule === 'Test') {
        vestingInfo.vestingPeriod = 300;
      }

      const contractData: any[] = [info, dexInfo, vestingInfo];
      if (type === 'private') {
        contractData.push(data.whitelist);
      }
      await walletService
        .createTransaction({
          method,
          data: contractData,
          contract: 'FACTORY',
        })
        .on('transactionHash', async (hash) => {
          apiData.append('transactionHash', hash);
          await presaleApi.create(apiData);
        })
        .then(() => {
          handleOpenModal();
        });
    },
    [formsData, handleOpenModal, type, user.address, walletService],
  );

  const calculateMinSaleStartTime = React.useCallback(
    (currentValue): boolean => {
      // return true;
      if (type === 'public' || type === 'inqubator') {
        // return addDays(new Date(), 16);
        return getTime(currentValue) > getTime(addMinutes(new Date(), is_production ? 21600 : 15));
      }
      return getTime(currentValue) > getTime(new Date());
    },
    [type],
  );
  const calculateMinSaleEndTime = React.useCallback(
    (checkValue: any, currentValue: any): boolean => {
      // return true;
      if (type === 'public' || type === 'inqubator') {
        // return addHours(checkValue, 9);
        return getTime(currentValue) > getTime(addMinutes(new Date(), is_production ? 540 : 40));
      }
      return getTime(checkValue) > getTime(new Date());
    },
    [type],
  );
  const calculateMinListingTime = React.useCallback(
    (checkValue: any, currentValue: any): boolean => {
      // return true;
      return getTime(currentValue) > getTime(checkValue);
    },
    [],
  );
  const validateDates = React.useCallback(async () => {
    const schema = Yup.object({
      saleStartTime: Yup.date().test(
        'is-greater',
        type === 'private'
          ? 'Token Pool Info: Sale Start Time should be more than current day'
          : 'Token Pool Info: Sale Start Time must be at least 16 days later than the current date',
        () => calculateMinSaleStartTime(formsData.tokenPoolInfo.saleStartTime),
      ),
      saleEndTime: Yup.date().test(
        'is-greater',
        type === 'private'
          ? 'Token Pool Info: Sale Start Time should be more than current day'
          : 'Token Pool Info: Sale End Time must be at least 9 hours later than the Sale Start Time',
        () =>
          calculateMinSaleEndTime(
            formsData.tokenPoolInfo.saleStartTime,
            formsData.tokenPoolInfo.saleEndTime,
          ),
      ),
      listingTime: Yup.date().test(
        'is-greater',
        'PancakeSwap Plan: Listing Time is should be greater Sale End Time',
        () =>
          calculateMinListingTime(
            formsData.tokenPoolInfo.saleEndTime,
            formsData.swapPlan.listingTime,
          ),
      ),
    });
    return schema.validateSync(
      {
        saleStartTime: formsData.tokenPoolInfo.saleStartTime,
        saleEndTime: formsData.tokenPoolInfo.saleEndTime,
        listingTime: formsData.swapPlan.listingTime,
      },
      { abortEarly: false },
    );
  }, [
    calculateMinListingTime,
    calculateMinSaleEndTime,
    calculateMinSaleStartTime,
    formsData.swapPlan.listingTime,
    formsData.tokenPoolInfo.saleEndTime,
    formsData.tokenPoolInfo.saleStartTime,
    type,
  ]);

  const handleManageCreateStates = React.useCallback(
    async (key: keyof IForm, data: IForm[typeof key]) => {
      // if (type === 'inqubator') {
      //   // formsData.tokenPoolInfo.saleStartTime = addMinutes(new Date(), 20);
      //   formsData.tokenPoolInfo.saleEndTime = addMinutes(new Date(), 80);
      //   formsData.swapPlan.listingTime = addMinutes(new Date(), 85);
      // }
      validateDates()
        .then(async () => {
          try {
            setLoading(true);
            // Approve Token
            if (createPresaleState === 'approveToken') {
              handleApproveToken();
              return;
            }
            // Approve Link
            if (createPresaleState === 'approveLink') {
              handleApproveLink();
              return;
            }
            // Create Presale on contract
            if (createPresaleState === 'createPresale') {
              await handleCreatePresale(key, data);
              return;
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
          } finally {
            setLoading(false);
          }
        })
        .catch((err) => {
          setValidationErrors(err.errors);
          handleOpenValidationModal();
        });
    },
    [
      createPresaleState,
      handleApproveLink,
      handleApproveToken,
      handleCreatePresale,
      handleOpenValidationModal,
      validateDates,
    ],
  );

  const handleUpdateFormsData = React.useCallback(
    (key: keyof IForm, data: IForm[typeof key]) => {
      setFormsData(() => ({
        ...formsData,
        [key]: data,
      }));
      if (stepsWithType.length !== currentStep || (type === 'private' && currentStep === 7)) {
        handleNext();
      } else {
        handleManageCreateStates(key, data).then();
      }
    },
    [currentStep, formsData, handleManageCreateStates, handleNext, stepsWithType.length, type],
  );

  const handleBack = React.useCallback(() => {
    if (currentStep === 1) {
      navigator('/start/type');
    } else {
      setCurrentStep(currentStep - 1);
    }
  }, [currentStep, setCurrentStep, navigator]);

  const handleFinish = React.useCallback(() => {
    handleCloseModal();
    navigator('/');
  }, [navigator, handleCloseModal]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [handleNext]);
  React.useEffect(() => {
    if (isApprovedToken && !isApprovingToken) {
      if (type === 'private') {
        setCreatePresaleButton('Create presale');
        setCreatePresaleState('createPresale');
      } else {
        setCreatePresaleButton('Approve LINK');
        setCreatePresaleState('approveLink');
        if (isApprovedLink && !isApprovingLink) {
          setCreatePresaleButton('Create presale');
          setCreatePresaleState('createPresale');
        }
      }
    }
  }, [isApprovedLink, isApprovedToken, isApprovingLink, isApprovingToken, type]);
  React.useEffect(() => {
    if (
      formsData.tokenPoolInfo.hardCap &&
      formsData.tokenPoolInfo.tokenIdoPrice &&
      formsData.swapPlan.listingPrice
    ) {
      const approveAmount = new BigNumber(
        new BigNumber(formsData.tokenPoolInfo.hardCap).dividedBy(
          formsData.tokenPoolInfo.tokenIdoPrice || 1,
        ),
      )
        .plus(
          new BigNumber(formsData.tokenPoolInfo.hardCap)
            .multipliedBy(formsData.swapPlan.allocationPercent)
            .dividedBy(formsData.swapPlan.listingPrice || 1)
            .dividedBy(100),
        )
        .toString();
      setApproveTokenAmount(approveAmount);
    }
  }, [
    formsData.swapPlan.allocationPercent,
    formsData.swapPlan.listingPrice,
    formsData.tokenPoolInfo.hardCap,
    formsData.tokenPoolInfo.tokenIdoPrice,
  ]);

  return (
    <>
      <div className={style.cp_form}>
        <div className={style.cp_form__container}>
          <div className={style.cp_form__preview}>
            <div className={style.cp_form__preview__text}>{`${currentStep}. ${
              stepsWithType[currentStep - 1]
            }`}</div>
          </div>
          <div className={style.cp_form__content}>
            <Steps currentStep={currentStep} allSteps={stepsWithType} />
            <div className={style.cp_form__form}>
              {currentStep === 1 ? (
                <TokenPoolInfoForm
                  initialValues={formsData.tokenPoolInfo}
                  handleBack={handleBack}
                  handleUpdateData={handleUpdateFormsData}
                  projectType={type}
                />
              ) : null}
              {/* {currentStep === 2 ? ( */}
              {/*  <KeyMetricsForm */}
              {/*    initialValues={formsData.keyMetrics} */}
              {/*    handleBack={handleBack} */}
              {/*    handleUpdateData={handleUpdateFormsData} */}
              {/*  /> */}
              {/* ) : null} */}
              {currentStep === 2 ? (
                <ProjectInfoForm
                  initialValues={formsData.projectInfo}
                  handleBack={handleBack}
                  handleUpdateData={handleUpdateFormsData}
                />
              ) : null}
              {currentStep === 3 ? (
                <ProjectDeepDiveForm
                  initialValues={formsData.projectDeepDive}
                  handleBack={handleBack}
                  handleUpdateData={handleUpdateFormsData}
                  projectName={formsData.projectInfo.title}
                />
              ) : null}
              {currentStep === 4 ? (
                <TokenLockingReportsForm
                  initialValues={formsData.tokenLockingReports}
                  handleBack={handleBack}
                  handleUpdateData={handleUpdateFormsData}
                />
              ) : null}
              {currentStep === 5 ? (
                <TokenVestingForm
                  initialValues={formsData.tokenVesting}
                  handleBack={handleBack}
                  handleUpdateData={handleUpdateFormsData}
                />
              ) : null}
              {currentStep === 6 ? (
                <SwapPlanForm
                  initialValues={formsData.swapPlan}
                  handleBack={handleBack}
                  handleUpdateData={handleUpdateFormsData}
                  saleEndTime={formsData.tokenPoolInfo.saleEndTime}
                />
              ) : null}
              {currentStep === 7 ? (
                <ProjectCreatorInfoForm
                  initialValues={formsData.projectCreatorInfo}
                  handleBack={handleBack}
                  handleUpdateData={handleUpdateFormsData}
                  isEndStep={type !== 'private'}
                  btnText={createPresaleButton}
                  isLoading={isLoading}
                  isApprovingToken={isApprovingToken}
                  isApprovingLink={isApprovingLink}
                />
              ) : null}
              {currentStep === 8 ? (
                <WhitelistForm
                  initialValues={formsData.whitelist}
                  handleBack={handleBack}
                  handleUpdateData={handleUpdateFormsData}
                  btnText={createPresaleButton}
                  isLoading={isLoading}
                  isApprovingToken={isApprovingToken}
                  isApprovingLink={isApprovingLink}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ValidationModal
        visible={isVisibleValidationModal}
        onClose={handleCloseValidationModal}
        errors={validationErrors}
      />
      <CongratulationModal
        visible={isVisibleModal}
        onClose={handleFinish}
        text="The campaign has been successfully created."
        btnAction={handleFinish}
        btnText="SEE CAMPAIGN"
      />
    </>
  );
});

export default CreateProject;
