import { FC } from 'react';

import cn from 'classnames';

import { Button, Modal } from 'components';

import { IModalProps } from 'types';

import s from './UnableToVoteModal.module.scss';

const UnableToVoteModal: FC<IModalProps> = ({ visible, onClose }) => {
  return (
    <Modal className={s.modal_wrapper} visible={visible} onClose={onClose}>
      <div className={s.unable_to_vote_wrapper}>
        <div className={s.title}>Unable to Vote</div>
        <div className={cn(s.text, 'text-gray')}>
          To be able to vote, you need to have a total of at least 100.000.000.000 QUACK staked into
          QUACK Staking pool
        </div>
        <Button color="filled" isFullWidth onClick={onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default UnableToVoteModal;
