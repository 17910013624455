import { FC, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import { FilterBar, SubscribeModal } from 'containers';

import { Button, Loading, PagePreview, Pagination, ProjectCard } from 'components';
import { filterItems } from 'utils/constants';

import { useModal, usePresaleSimple } from 'hooks';
import { IFilters } from 'types';

import s from './Discover.module.scss';

const Discover: FC = observer(() => {
  const [isVisibleSubscribeModal, handleOpenSubscribeModal, handleCloseSubscribeModal] =
    useModal(false);
  const { user } = useMst();
  const [filters, setFilters] = useState<IFilters>({
    status: filterItems[0].defaultOption.value,
    network: filterItems[1].defaultOption.value,
    type: filterItems[2].defaultOption.value,
    ordering: filterItems[3].defaultOption.value,
    page: 1,
  });
  const { state } = useLocation();
  const [presales, [prevLink, nextLink], , presalesLoading] = usePresaleSimple(
    user.address,
    state,
    '',
    filters,
  );

  const updateFilters = useCallback(
    (item, filter) => {
      setFilters({ ...filters, ...{ [item.id]: filter.value } });
    },
    [filters],
  );

  return (
    <div className={s.discover_wrapper}>
      <PagePreview subtitle="Start launch" title="Bring your creative project to life">
        <div className={s.btn_wrapper}>
          <Button color="filled" className={s.btn_start} href="/start">
            Start a project
          </Button>
          <Button className={s.btn_subscribe} onClick={handleOpenSubscribeModal}>
            Subscribe to upcoming launch
          </Button>
        </div>
      </PagePreview>
      <FilterBar onFilter={updateFilters} />
      {/* <div className="projects_wrapper"> */}
      {/*  {projects.map((project) => { */}
      {/*    if (project.id === 0) { */}
      {/*      return <ProjectCardTemp key={project.id} project={project} />; */}
      {/*    } */}
      {/*    return <Fragment key={project.id} />; */}
      {/*  })} */}
      {/* </div> */}
      {presalesLoading ? (
        <Loading className={s.loader} />
      ) : (
        <div className="projects_wrapper">
          {presales.map((presale) => (
            <ProjectCard key={presale.id} presale={presale} />
          ))}
        </div>
      )}
      <Pagination filters={filters} setFilters={setFilters} prev={prevLink} next={nextLink} />
      <SubscribeModal visible={isVisibleSubscribeModal} onClose={handleCloseSubscribeModal} />
    </div>
  );
});

export default Discover;
