/* eslint-disable no-await-in-loop */
import { useCallback, useEffect, useState } from 'react';

import { addMinutes, getTime, toDate } from 'date-fns';

import { BROKEN_PRESALE_ADDRESS, contracts } from 'config';
import { projectRounds } from 'utils/constants';

import { presaleApi, useWalletConnectorContext, WalletService } from 'services';
import { IApiPresale, IFilters, IPresale, statusEnum } from 'types';

import {
  REGISTRATION_CLOSE_DURATION,
  REGISTRATION_DURATION,
  REGISTRATION_DURATION_INQUBATOR,
} from './usePresale';

const usePresaleSimple = (
  userAddress: string | null,
  query?: string | unknown,
  presaleId?: string | unknown,
  filters?: IFilters | {},
): [IPresale[], string[], () => void, boolean] => {
  const { walletService } = useWalletConnectorContext();
  const web3 = walletService.Web3();
  const [presalesData, setPresalesData] = useState<IPresale[]>([]);
  const [pagiLinks, setPagiLinks] = useState<string[]>([]);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const getPresales = useCallback(async () => {
    console.log('filters', filters);
    return presaleApi.get({ ...{ search: query, id: presaleId }, ...filters });
  }, [filters, presaleId, query]);

  const handleRefreshData = useCallback(() => {
    setRefreshData(true);
  }, []);

  const getPresaleStatus = useCallback(
    async (presaleType: string, presaleAddress: string) => {
      const currentDate = Date.now();

      const contractName = `PRESALE_${presaleType.toUpperCase()}`;
      const contract = new web3.eth.Contract(
        contracts.params[contractName][contracts.type].abi,
        presaleAddress,
      );

      const generalInfoPromise = contract.methods.generalInfo().call();
      const intermediatePromise = contract.methods.intermediate().call();
      const [generalInfo, intermediate] = await Promise.all([
        generalInfoPromise,
        intermediatePromise,
      ]);
      // if (!is_production) {
      //   console.log(typeof intermediate);
      // }

      const totalRaised = await contract.methods.getRaisedAmount().call();

      if (presaleType === 'private') {
        if (
          currentDate > +generalInfo.openTime * 1000 &&
          currentDate <= +generalInfo.closeTime * 1000
        ) {
          return [statusEnum.open, 'Private round'];
        }
        if (+totalRaised >= +generalInfo.softCap) {
          return [statusEnum.completedSuccess, '-'];
        }
        if (currentDate > generalInfo.closeTime * 1000) {
          return [statusEnum.completedFail, '-'];
        }
        return [statusEnum.upcoming, '-'];
      }

      if (presaleType === 'public') {
        const votingParams = await contract.methods.votingParams().call();
        // broken presale
        if (presaleAddress === BROKEN_PRESALE_ADDRESS) {
          return [statusEnum.completedSuccess, '-'];
        }
        if (currentDate <= intermediate.closeTimeVoting * 1000) {
          return [statusEnum.voting, '-'];
        }
        if (
          currentDate > intermediate.closeTimeVoting * 1000 &&
          +intermediate.votes.yes < +intermediate.votes.no + +votingParams.threshold
        ) {
          return [statusEnum.votingFailed, '-'];
        }
      }

      if (
        currentDate > generalInfo.openTime * 1000 &&
        currentDate <= generalInfo.closeTime * 1000
      ) {
        const roundIndex = await contract.methods.getRound().call();
        return [statusEnum.open, projectRounds[roundIndex]];
      }

      if (currentDate > generalInfo.closeTime * 1000 && +totalRaised >= +generalInfo.softCap) {
        return [statusEnum.completedSuccess, '-'];
      }
      if (currentDate > generalInfo.closeTime * 1000) {
        return [statusEnum.completedFail, '-'];
      }
      if (
        currentDate >
        getTime(addMinutes(toDate(generalInfo.openTime * 1000), REGISTRATION_CLOSE_DURATION))
      ) {
        return [statusEnum.registerClosed, 'Ignition'];
      }
      if (
        presaleType === 'inqubator' &&
        currentDate >
          getTime(addMinutes(toDate(generalInfo.openTime * 1000), REGISTRATION_DURATION_INQUBATOR))
      ) {
        return [statusEnum.register, 'Registration'];
      }
      if (
        presaleType === 'public' &&
        currentDate >
          getTime(addMinutes(toDate(generalInfo.openTime * 1000), REGISTRATION_DURATION))
      ) {
        return [statusEnum.register, 'Registration'];
      }

      return [statusEnum.upcoming, '-'];
    },
    [web3.eth.Contract],
  );

  const getPresaleUserData = useCallback(
    async (presaleType: string, presaleAddress: string) => {
      const contractName = `PRESALE_${presaleType.toUpperCase()}`;
      const contract = new web3.eth.Contract(
        contracts.params[contractName][contracts.type].abi,
        presaleAddress,
      );

      const lotteryWhitelistPromise = contract.methods.lotteryWhitelist(userAddress).call();
      const registerLevelsPromise = contract.methods.registerLevels(userAddress).call();
      const promises = [lotteryWhitelistPromise, registerLevelsPromise];
      const result = await Promise.all(promises);
      const [lotteryWhitelist, registerLevels] = result;

      return [lotteryWhitelist, +registerLevels.level > 0];
    },
    [userAddress, web3.eth.Contract],
  );

  const preparePresaleData = useCallback(
    async (presales: IApiPresale[]) => {
      const result: any = [];
      // debugger;
      for (let i = 0; i < presales.length; i += 1) {
        const presale = presales[i];
        const contractName = `PRESALE_${presale.type.toUpperCase()}`;
        const contract = new web3.eth.Contract(
          contracts.params[contractName][contracts.type].abi,
          presale.address,
        );

        const fundingTokenDecimals = await walletService.getTokenDecimals(
          contracts.params.USDT[contracts.type].address,
        );

        const generalInfoPromise = await contract.methods.generalInfo().call();
        const intermediatePromise = await contract.methods.intermediate().call();
        const raisedAmountPromise = await contract.methods.getRaisedAmount().call();
        const [generalInfo, intermediate, raisedAmount] = await Promise.all([
          generalInfoPromise,
          intermediatePromise,
          raisedAmountPromise,
        ]);

        const finalPresale = {
          id: presale.id,
          status: '',
          round: '',
          type: presale.type,
          creator: presale.creator,
          title: presale.title,
          description: presale.description,
          img: presale.presaleImage,
          address: presale.address,
          tokenPrice: WalletService.weiToEth(
            generalInfo.tokenPrice.toString(),
            fundingTokenDecimals,
          ).toString(),
          voters: [+presale.votes[0], +presale.votes[1]],
          totalVoters: 0,
          registrationOpens: getTime(
            addMinutes(toDate(generalInfo.openTime * 1000), REGISTRATION_DURATION),
          ),
          registrationClosed: getTime(
            addMinutes(toDate(generalInfo.openTime * 1000), REGISTRATION_CLOSE_DURATION),
          ),
          totalRegistered: presale.totalRegistered,
          salesStart: presale.openTime * 1000,
          salesEnd: presale.closeTime * 1000,
          totalRaised: WalletService.weiToEth(raisedAmount.toString(), fundingTokenDecimals),
          hardCap: WalletService.weiToEth(
            generalInfo.hardCap.toString(),
            fundingTokenDecimals,
          ).toString(),
          softCap: WalletService.weiToEth(
            generalInfo.softCap.toString(),
            fundingTokenDecimals,
          ).toString(),
          user: {
            isUserWinLottery: false,
            isUserRegistered: true,
          },
        };

        if (presale.type === 'public') {
          finalPresale.voters = [
            parseInt(intermediate.votes.yes, 10),
            parseInt(intermediate.votes.no, 10),
          ];
          finalPresale.totalVoters =
            parseInt(intermediate.votes.yes, 10) + parseInt(intermediate.votes.no, 10);
        }

        if (presale.type === 'inqubator') {
          finalPresale.registrationOpens = getTime(
            addMinutes(toDate(generalInfo.openTime * 1000), REGISTRATION_DURATION_INQUBATOR),
          );
        }
        if (presale.type !== 'private') {
          finalPresale.registrationOpens = getTime(
            addMinutes(toDate(presale.openTime * 1000), REGISTRATION_DURATION),
          );
          finalPresale.registrationClosed = getTime(
            addMinutes(toDate(presale.openTime * 1000), REGISTRATION_CLOSE_DURATION),
          );
          finalPresale.salesStart = presale.openTime * 1000;
          finalPresale.salesEnd = presale.closeTime * 1000;

          if (userAddress) {
            [finalPresale.user.isUserWinLottery, finalPresale.user.isUserRegistered] =
              await getPresaleUserData(presale.type, presale.address);
          }
        }
        [finalPresale.status, finalPresale.round] = await getPresaleStatus(
          presale.type,
          presale.address,
        );
        result.push(finalPresale);
      }

      return result;
    },
    [getPresaleStatus, getPresaleUserData, userAddress, walletService, web3.eth.Contract],
  );

  useEffect(() => {
    setLoading(true);
    getPresales().then(async (result) => {
      const presales = await preparePresaleData(result.data.results);
      setPresalesData(presales);
      setPagiLinks([result.data.links.previous, result.data.links.next]);
      setRefreshData(false);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPresales]); // preparePresaleData is absent as deps, because it causes to call getPresales() again and iterate through all logic again

  useEffect(() => {
    if (refreshData && presaleId) {
      setLoading(true);
      getPresales().then(async (result) => {
        const presales = await preparePresaleData(result.data.results);
        setPresalesData(presales);
        setPagiLinks([result.data.links.previous, result.data.links.next]);
        setRefreshData(false);
        setLoading(false);
      });
    }
  }, [getPresales, preparePresaleData, presaleId, refreshData]);

  return [presalesData, pagiLinks, handleRefreshData, isLoading];
};
export default usePresaleSimple;
