import { IPeriod, socialsNameEnum } from 'types';

import {
  BnbSm,
  BtcSm,
  // CoinbaseWallet,
  Discord,
  Eth,
  EthSimple,
  EthSm,
  // Fortmatic,
  Gitbook,
  Github,
  MaticSm,
  Medium,
  MetaMask,
  // Portis,
  Telegram,
  Twitter,
  WalletConnect,
  Web,
} from 'assets/img';

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const socialsImgs: {
  [key in socialsNameEnum]: string;
} = {
  twitter: Twitter,
  telegram: Telegram,
  web: Web,
  discord: Discord,
  github: Github,
  gitbook: Gitbook,
  medium: Medium,
};

interface ICurrency {
  name: string;
  icon: string;
  iconSm: string;
  iconSimple: string;
}

export const currencies: { [key: string]: ICurrency } = {
  ETH: {
    name: 'Ethereum',
    icon: Eth,
    iconSm: EthSm,
    iconSimple: EthSimple,
  },
  BNB: {
    name: 'Binance Coin',
    icon: '',
    iconSm: BnbSm,
    iconSimple: '',
  },
  BTC: {
    name: 'Bitcoin',
    icon: '',
    iconSm: BtcSm,
    iconSimple: '',
  },
  MATIC: {
    name: 'Matic',
    icon: '',
    iconSm: MaticSm,
    iconSimple: '',
  },
};

export const wallets = [
  {
    name: 'Metamask',
    providerName: 'MetaMask',
    icon: MetaMask,
  },
  {
    name: 'Wallet Connect',
    providerName: 'WalletConnect',
    icon: WalletConnect,
  },
  // {
  //   name: 'Coinbase Wallet',
  //   providerName: '',
  //   icon: CoinbaseWallet,
  // },
  // {
  //   name: 'Fortmatic',
  //   providerName: '',
  //   icon: Fortmatic,
  // },
  // {
  //   name: 'Portis',
  //   providerName: '',
  //   icon: Portis,
  // },
];

export const periodsMock: IPeriod[] = [
  {
    id: 0,
    days: 7,
    relocks: true,
    notEligible: [4, 9],
    apr: 0,
  },
  {
    id: 1,
    days: 14,
    relocks: true,
    notEligible: [7, 9],
    apr: 8,
  },
  {
    id: 2,
    days: 30,
    relocks: true,
    notEligible: [],
    apr: 13,
  },
  {
    id: 3,
    days: 90,
    relocks: true,
    notEligible: [],
    apr: 28,
  },
];

export const presalePoolWeights = [0.5, 1, 2.5, 5.5, 12, 19, 26, 70, 150];

export const projectRounds = ['moon', 'diamond', 'paper', 'break'];

export const filterItems = [
  {
    id: 'status',
    label: 'Show Me',
    defaultOption: {
      text: 'All',
      value: 'upcoming,open,completed',
    },
    othersOptions: [
      {
        text: 'All',
        value: 'upcoming,open,completed',
      },
      {
        text: 'Upcoming',
        value: 'upcoming',
      },
      {
        text: 'Open',
        value: 'open',
      },
      {
        text: 'Completed',
        value: 'completed',
      },
    ],
  },
  {
    id: 'network',
    label: 'Projects On',
    defaultOption: {
      text: 'BSC',
      value: 'bsc',
    },
    othersOptions: [
      {
        text: 'BSC',
        value: 'bsc',
      },
    ],
  },
  {
    id: 'type',
    label: 'Thats Are',
    defaultOption: {
      text: 'All',
      value: '',
    },
    othersOptions: [
      {
        text: 'All',
        value: '',
      },
      {
        text: 'Public',
        value: 'public',
      },
      {
        text: 'Private',
        value: 'private',
      },
      {
        text: 'InQubator',
        value: 'inqubator',
      },
    ],
  },
  {
    id: 'ordering',
    label: 'Sorted By',
    defaultOption: {
      text: 'Newest',
      value: '-created_at',
    },
    othersOptions: [
      {
        text: 'Newest',
        value: '-created_at',
      },
      {
        text: 'Most Funded',
        value: '-raised_amount',
      },
      {
        text: 'Most Backed',
        value: '-total_registered',
      },
    ],
  },
];
