import { FC, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useMst } from '../../store';
import { observer } from 'mobx-react-lite';

import cn from 'classnames';

import { Search } from 'components';

import { Burger } from './components';

import { ConnectButton } from '..';

import logoIcon from 'assets/img/icons/logo.png';
import menuIcon from 'assets/img/icons/menu.svg';

import s from './Header.module.scss';

const Header: FC = observer(() => {
  const [search, setSearch] = useState<string>('');
  const [isHeaderScrolled, setHeaderScrolled] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const { sidebar } = useMst();
  const navigate = useNavigate();

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 50) {
      setHeaderScrolled(true);
    } else {
      setHeaderScrolled(false);
    }
  }, []);

  const handleUpdateSearch = useCallback((str: string) => {
    setSearch(str);
  }, []);

  const handleSearch = useCallback(() => {
    navigate('/', { state: search });
  }, [navigate, search]);

  const handleChangeIsSearchVisible = useCallback((isVisible: boolean) => {
    setIsSearchVisible(isVisible);
  }, []);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <header
      className={cn(s.header_wrapper, {
        [s.header_wrapper_active]: isHeaderScrolled,
      })}
    >
      <div className={s.container}>
        <div className={s.burger_wrapper}>
          <Burger
            className={s.burger}
            onClick={sidebar.toggleSidebar}
            isMenuOpen={sidebar.isOpen}
          />
        </div>
        <div
          className={cn(s.logo_wrapper, {
            [s.logo_wrapper_hidden]: isSearchVisible,
          })}
        >
          <div
            className={s.menu_button}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={sidebar.toggleSidebar}
          >
            <img src={menuIcon} alt="menu" />
          </div>
          <Link to="/" className={s.logo}>
            <img src={logoIcon} alt="logo" width="24" height="24" />
            <div className={s.logo_text}>Rich Quack</div>
          </Link>
        </div>
        <div className={s.search}>
          <Search
            onChange={handleUpdateSearch}
            type="header"
            placeholder="Search for projects, donations or categories"
            handleChangeVisible={handleChangeIsSearchVisible}
            onKeyPress={handleSearch}
          />
        </div>
        <ConnectButton showOnMobile={false} />
      </div>
    </header>
  );
});

export default Header;
