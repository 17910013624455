import React from 'react';

import cn from 'classnames';
import { VotingItem } from 'containers/Voting';

import { useWalletConnectorContext } from 'services';
import { chainsEnum, IPresale } from 'types';

import style from './VotingTable.module.scss';

interface IHead {
  text: string;
  isTabletHidden: boolean;
  isMobileHidden: boolean;
}

interface IVotingTable {
  head: Array<IHead>;
  presales: IPresale[];
}

const VotingTable: React.FC<IVotingTable> = React.memo(({ head, presales }) => {
  const { connect } = useWalletConnectorContext();

  const handleConnect = React.useCallback(() => {
    connect(chainsEnum['Binance-Smart-Chain'], 'MetaMask');
  }, [connect]);

  return (
    <div className={cn(style.table, 'table')}>
      <div className={cn(style.table__head, 'table__head')}>
        {head.map((item) => (
          <div
            key={item.text}
            className={cn(style.table__head__item, 'text-sm', {
              table__head__name: item.text.toLocaleLowerCase() === 'name',
              hidden__tablet: item.isTabletHidden,
              hidden__mobile: item.isMobileHidden,
            })}
          >
            {item.text}
          </div>
        ))}
      </div>
      <div className="table__body">
        {/* {items.map((item) => ( */}
        {/*  <VotingItemTemp */}
        {/*    key={item.name} */}
        {/*    {...item} */}
        {/*    handleConnect={handleConnect} */}
        {/*    contentClassName={style.table__item} */}
        {/*  /> */}
        {/* ))} */}
        {presales.map((presale) => (
          <VotingItem
            key={presale.id}
            presale={presale}
            handleConnect={handleConnect}
            contentClassName={style.table__item}
          />
        ))}
        {}
      </div>
    </div>
  );
});

export default VotingTable;
