import { CSSProperties, FC, ReactElement, useCallback, useState } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';

import cn from 'classnames';

import { OutsideClick } from '../index';

import arrow from '../../assets/img/icons/dropdown_arrow.svg';

import s from './Select.module.scss';

export interface IOption {
  img?: string;
  value: string | number;
  text?: string;
}

interface ISelectorProps {
  className?: string;
  boxClassName?: string;
  style?: CSSProperties;
  defaultOption: IOption;
  options: IOption[];
  onSelect?: (value: IOption) => void;
  color?: 'default' | 'gray' | 'dark';
  isCurrency?: boolean;
  size?: 'mini' | 'big';
  title?: ReactElement;
  subtitle?: ReactElement;
  error?: string;
}

const Selector: FC<ISelectorProps> = ({
  className,
  style,
  error,
  defaultOption,
  options,
  color = 'default',
  onSelect,
  boxClassName,
  isCurrency,
  size = 'mini',
  title,
  subtitle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState(defaultOption);

  const handleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleOutsideClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSelect = useCallback(
    (option: IOption) => {
      setIsOpen(false);
      setCurrentOption(option);
      if (onSelect) {
        onSelect(option);
      }
    },
    [onSelect],
  );

  return (
    <div className={s.wrapper}>
      {title ? <div className={s.title}>{title}</div> : null}
      <OutsideClick onClick={handleOutsideClick} className={boxClassName}>
        <div
          className={cn(
            s.selector,
            s[color],
            boxClassName,
            s[size],
            { [s.active]: isOpen },
            { [s.currency]: isCurrency },
          )}
        >
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={handleOpen}
            className={cn(s.currentOption, className, s[size])}
            style={style}
          >
            <div className={s.currentOption__wrapper}>
              {currentOption.img ? (
                <div
                  className={cn(s.img, {
                    [s.img_currency]: isCurrency,
                  })}
                >
                  <img src={currentOption.img} alt="" />
                </div>
              ) : null}
              {currentOption.text || currentOption.value}
            </div>
            <img src={arrow} alt="arrow" className={cn(s.arrow, { [s.active]: isOpen })} />
          </div>
          {isOpen && (
            <div className={s.option_wrapper}>
              <Scrollbar
                style={{
                  width: '100%',
                  height: options.length > 3 ? '150px' : `${options.length * 36 + 30}px`,
                }}
              >
                {options.map((option) => (
                  <div
                    key={option.value}
                    role="button"
                    tabIndex={-1}
                    onKeyDown={handleOpen}
                    onClick={() => handleSelect(option)}
                    className={cn(s.option, {
                      [s.option_active]: option.value === currentOption.value,
                    })}
                  >
                    {option.img ? (
                      <div
                        className={cn(s.img, {
                          [s.img_currency]: isCurrency,
                        })}
                      >
                        <img src={option.img} alt="" />
                      </div>
                    ) : null}
                    {option.text || option.value}
                  </div>
                ))}
              </Scrollbar>
            </div>
          )}
        </div>
      </OutsideClick>
      {subtitle ? <div className={s.subtitle}>{subtitle}</div> : null}
      {error ? <div className={s.error}>{error}</div> : null}
    </div>
  );
};

export default Selector;
