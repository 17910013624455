import React from 'react';

import cn from 'classnames';
import { Field, Form, FormikProps } from 'formik';

import { Button, Input, Selector } from 'components';

import { ITokenVestingForm, IValues } from '../container';

import style from './TokenVesting.module.scss';

const distributions = [
  // {
  //   value: 'Test',
  // },
  {
    value: 'Week',
  },
  {
    value: 'Month',
  },
];

const TokenVestingForm: React.FC<FormikProps<IValues> & ITokenVestingForm> = ({
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  touched,
  values,
  handleBack,
  setFieldValue,
}) => {
  const activeDistribution = React.useMemo(() => {
    return (
      distributions.find((distr) => distr.value === values.vestingSchedule) || distributions[0]
    );
  }, [values.vestingSchedule]);

  return (
    <Form name="TokenVesting-form" onSubmit={handleSubmit}>
      <div className={cn('form__create_presale__row')}>
        <Field id="releaseIdo" name="releaseIdo">
          {() => (
            <Input
              name="releaseIdo"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              min={0}
              max={100 - (values.vestingPercentage || 0)}
              isNumber
              integer
              placeholder="0"
              value={values.releaseIdo || ''}
              title={<div className={cn('text-600 text-sm')}>Token Release After IDO (%)</div>}
              postfix={<span className="form__create_presale__postfix">%</span>}
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The amount of token that will be distributed to investor right after IDO.
                </div>
              }
              error={touched.releaseIdo && errors.releaseIdo ? errors.releaseIdo : ''}
            />
          )}
        </Field>
        <div className="form__create_presale__row__box">
          <Field id="vestingSchedule" name="vestingSchedule">
            {() => (
              <Selector
                color="dark"
                title={<div className={cn('text-600 text-sm')}>Vesting Schedule</div>}
                subtitle={
                  <div className="text-gray text-sm text-italic">Token Distribution Per.</div>
                }
                size="big"
                defaultOption={activeDistribution}
                options={distributions}
                onSelect={(option) => setFieldValue('vestingSchedule', option.value)}
              />
            )}
          </Field>
          <Field id="vestingPercentage" name="vestingPercentage">
            {() => (
              <Input
                name="vestingPercentage"
                color="dark"
                onChange={handleChange}
                onBlur={handleBlur}
                isNumber
                positiveOnly
                integer
                max={100 - (values.releaseIdo || 0)}
                min={0}
                placeholder="0"
                value={values.vestingPercentage || ''}
                postfix={<span className="form__create_presale__postfix">%</span>}
                subtitle={<div className="text-gray text-sm text-italic">Vesting Percentage.</div>}
                error={
                  touched.vestingPercentage && errors.vestingPercentage
                    ? errors.vestingPercentage
                    : ''
                }
              />
            )}
          </Field>
        </div>
      </div>

      <div className={cn(style.tv_form__btns, 'form__create_presale__box')}>
        <div className="text-gray form__create_presale__text" />
        <div className="form__create_presale__btns">
          <Button
            className="form__create_presale__btns__item back"
            onClick={handleBack}
            size="medium"
          >
            Back
          </Button>
          <Button
            color={Object.values(errors).length ? 'disabled' : 'filled'}
            size="medium"
            type="submit"
            className="form__create_presale__btns__item"
          >
            Next
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default TokenVestingForm;
