import { Error, Magnifier, Success, Warning } from '../../../../assets/img';

export const data = [
  {
    id: 0,
    status: 'success',
    title: <span>Audited</span>,
    icon: Success,
    auditedBy: 'By Chav - Unicrypt',
    content: (
      <>
        <p>REMINDER: UniCrypt Network does not provide any auditing services.</p>
        <p>
          This tpken contract has been audited by Certik, the full report is available and can be
          found using the link below.
        </p>
        <p>
          Auditors are not financial advisors, please take chance to read and understand the
          aforementioned report.
        </p>
      </>
    ),
  },
  {
    id: 1,
    status: 'success',
    title: (
      <div>
        Team is <span>KYC verified</span>
      </div>
    ),
    icon: Magnifier,
    auditedBy: 'solidproof.io',
  },
  {
    id: 2,
    status: 'error',
    title: <span>Audited</span>,
    icon: Error,
    auditedBy: 'solidproof.io',
    content: <p>REMINDER: UniCrypt Network does not provide any auditing services.</p>,
  },
  {
    id: 3,
    status: 'warning',
    title: <span>Minor Warning</span>,
    icon: Warning,
    auditedBy: 'solidproof.io',
    content: (
      <>
        <p>
          # No issues were found The contract does not contain issues or high or medium critically.
        </p>
        <p># User funds can be locked Funds can be locked if fees are set too high.</p>
        <p>
          # Notice Tokens are burned an each transfer. Contract can be manipulated by owner
          functions. The ownership should be renounced.
        </p>
        <p>Deplayer can: set different fees without limitations</p>
        <p>
          # Unlocked tokens There is a large amount of tokens unlocked right after pre-sale. Full
          trust in the team is required.
        </p>
        <p>For details, please look in the audit report.</p>
        <p>Not a Financial afvice. Always DYOR.</p>
      </>
    ),
  },
];
