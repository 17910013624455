import React from 'react';

import BigNumber from 'bignumber.js';
import cn from 'classnames';
import SwiperCore, { Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { convertQuackTokens } from 'utils';

import { ILevel } from 'types';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import s from './LevelsSteps.module.scss';

interface ILevelsSteps {
  items: ILevel[];
  balance: string | number;
}

SwiperCore.use([Mousewheel, Navigation]);

const LevelsSteps: React.VFC<ILevelsSteps> = ({ items, balance }) => {
  return (
    <div className={s.l_steps}>
      <Swiper
        mousewheel
        direction="horizontal"
        allowTouchMove={false}
        slidesPerView="auto"
        navigation
        spaceBetween={0}
        className={s.l_steps__items}
      >
        <SwiperSlide>
          <div className={s.l_steps__wrapper}>
            <div
              className={cn(s.l_steps__balance, {
                [s.l_steps__balance_active]: new BigNumber(balance).isGreaterThanOrEqualTo(
                  items[0].value,
                ),
              })}
            >
              <div className="text-ellipsis">{convertQuackTokens(balance)}</div>
            </div>
          </div>
        </SwiperSlide>
        {items.map((step) => (
          <SwiperSlide key={step.name}>
            <div
              className={cn(s.l_steps__item, {
                [s.l_steps__item_active]: new BigNumber(balance).isGreaterThanOrEqualTo(step.value),
              })}
            >
              <div className={s.l_steps__item__circle} />
              <div className={s.l_steps__item__name}>{step.name}</div>
              <div className={cn(s.l_steps__item__value, 'text-orange')}>
                {new BigNumber(step.value).isGreaterThanOrEqualTo(1000000000000)
                  ? `${new BigNumber(step.value).dividedBy(1000000000000).toFixed(0, 1)}T QUACK`
                  : `${new BigNumber(step.value).dividedBy(1000000000).toFixed(0, 1)}B QUACK`}
              </div>
              <div className={cn(s.l_steps__item__descr, 'text-gray')}>
                {step.weight} pool weight (
                {step.lottery ? `${step.lottery}% Lottery` : 'Guaranteed'})
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default LevelsSteps;
