import { FC } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

import cn from 'classnames';

import s from '../MenuLink.module.scss';

interface ISubLinkProps {
  name: string;
  path: string;
}
const SubLink: FC<ISubLinkProps> = (props) => {
  const { name, path } = props;
  const resolved = useResolvedPath(path);
  const match = useMatch({ path: resolved.pathname, end: true });
  // setIsSubLinkActive(false);
  return (
    <div className={s.sublink_wrapper}>
      <Link className={cn(s.sublink, match ? s.active : '')} to={path}>
        <span className={s.sublink_name}>{name}</span>
      </Link>
    </div>
  );
};

export default SubLink;
