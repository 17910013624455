import React from 'react';

import { withFormik } from 'formik';
import * as Yup from 'yup';

import { IForm } from 'pages/CreateProject';

import Form from '../component';

export interface IValues {
  presaleImage: any;
  presalePreview: string;
  title: string;
  description: string;
  logo: string;
  telegram: string;
  github: string;
  twitter: string;
  website: string;
  customer: string;
  whitepaper: string;
  medium: string;
  discord: string;
}

export interface IProjectInfo {
  handleBack: () => void;
  handleUpdateData: (key: keyof IForm, data: IForm[typeof key]) => void;
  initialValues: IValues;
}

export type IProjectInfoForm = Pick<IProjectInfo, 'handleBack'>;

const ProjectInfo: React.FC<IProjectInfo> = ({ handleBack, handleUpdateData, initialValues }) => {
  const FormWithFormik = withFormik<IProjectInfoForm, IValues>({
    enableReinitialize: true,
    mapPropsToValues: () => initialValues,
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Title is required'),
      description: Yup.string().required('Description is required'),
      logo: Yup.string().url('Logo must be an url').required('Logo is required'),
      telegram: Yup.string().url('Telegram must be an url').required('Telegram is required'),
      github: Yup.string().url('Github must be an url'),
      twitter: Yup.string().url('Twitter must be an url').required('Twitter in required'),
      website: Yup.string().url('Website must be an url').required('Website is required'),
      customer: Yup.string().url('KYC must be an url'),
      medium: Yup.string().url('Medium must be an url'),
      whitepaper: Yup.string().url('Whitepaper must be an url'),
      discord: Yup.string().url('Discord must be an url').required('Discord is required'),
      presalePreview: Yup.string().required('Presale Image is required'),
    }),

    handleSubmit: (values) => {
      handleUpdateData('projectInfo', values);
    },

    displayName: 'ProjectInfo',
  })(Form);
  return <FormWithFormik handleBack={handleBack} />;
};

export default ProjectInfo;
