import { FC, useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import cn from 'classnames';

import { Button, CongratulationModal, Progress, StatusLabel, Timer } from 'components';
import { contracts } from 'config';
import { addressWithDots, calculatePercentOfVotes, calculatePoolSize } from 'utils';
import { socialsImgs } from 'utils/constants';

import { InvestModal, UnableToVoteModal } from '../index';

import { useModal, usePresaleData, useProjectStatusTextSimple } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { IProject, socialsNameEnum } from 'types';

import { ConfirmationModal } from '../../Voting';

// mock
import BscImg from 'assets/img/icons/chains/bsc.svg';
import copyLink from 'assets/img/icons/copy_link-gray.svg';
import { ReactComponent as DislikeImg } from 'assets/img/icons/dislike-gray.svg';
import { ReactComponent as LikeImg } from 'assets/img/icons/like-gray.svg';

import style from './ProjectControls.module.scss';

interface ISocial {
  items: {
    [key in socialsNameEnum]?: string;
  };
}

const Socials: FC<ISocial> = ({ items }) => {
  return (
    <div className={style.p_controls__socials}>
      <div className={cn(style.p_controls__socials__title, 'text-sm text-gray')}>Social</div>
      <div className={style.p_controls__socials__box}>
        {Object.keys(items).map((item) => (
          <a
            href={items[item as socialsNameEnum]}
            key={item}
            target="_blank"
            rel="noreferrer"
            className={style.p_controls__socials__item}
          >
            <img src={socialsImgs[item as socialsNameEnum]} alt="" />
          </a>
        ))}
      </div>
    </div>
  );
};

const ProjectControlsTemp: FC<IProject> = observer(({ img, access, name, socials }) => {
  const {
    user,
    modals: { walletConnect },
  } = useMst();
  const { walletService } = useWalletConnectorContext();

  const [isUnableToVoteModalOpen, handleOpenUnableToVoteModal, handleCloseUnableToVoteModal] =
    useModal(false);
  const [isConfirmationModalOpen, handleOpenConfirmationModal, handleCloseConfirmationModal] =
    useModal(false);
  const [
    isVisibleCongratulationModal,
    handleOpenCongratulationModal,
    handleCloseCongratulationModal,
  ] = useModal(false);
  const [isVisibleInvestModal, handleOpenInvestModal, handleCloseInvestModal] = useModal(false);

  const [projectData, userData, isDataLoading, handleRefreshData] = usePresaleData(
    0,
    user.address,
    user.level,
  );
  const statusText = useProjectStatusTextSimple(projectData.status, projectData.round, 4);
  const [isYesVote, setVote] = useState(false);
  const [congratsText, setCongratsText] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isVoteDisabled, setVoteDisabled] = useState(false);
  const [isRegisterDisabled, setRegisterDisabled] = useState(false);

  const handleConnect = useCallback(() => {
    walletConnect.toggle();
  }, [walletConnect]);

  const beforeCloseCongratulationModal = useCallback(() => {
    handleRefreshData();
    handleCloseCongratulationModal();
  }, [handleCloseCongratulationModal, handleRefreshData]);

  const beforeCloseInvestModal = useCallback(() => {
    handleRefreshData();
    handleCloseInvestModal();
  }, [handleCloseInvestModal, handleRefreshData]);

  const handleVoteClick = useCallback(
    (vote: boolean) => {
      if (user.level === 0) {
        handleOpenUnableToVoteModal();
      } else {
        setVote(vote);
        handleOpenConfirmationModal();
      }
    },
    [handleOpenConfirmationModal, handleOpenUnableToVoteModal, user.level],
  );

  const handleVote = useCallback(async () => {
    handleCloseConfirmationModal();
    try {
      setLoading(true);
      await walletService.createTransaction({
        method: 'vote',
        contract: 'PRESALE_PUBLIC_TEST',
        data: [isYesVote],
      });
      setVoteDisabled(true);
      handleRefreshData();
      setLoading(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setVoteDisabled(false);
    }
  }, [handleCloseConfirmationModal, handleRefreshData, isYesVote, walletService]);

  const handleRegister = useCallback(async () => {
    try {
      setLoading(true);
      await walletService.createTransaction({
        method: 'register',
        contract: 'PRESALE_PUBLIC_TEST',
        data: [],
      });
      setCongratsText('Congratulations! You’ve successfully registered for this IDO.');
      handleOpenCongratulationModal();
      setRegisterDisabled(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [handleOpenCongratulationModal, walletService]);

  const handleCreateTransaction = useCallback(
    async (contractMethod, successText) => {
      try {
        setLoading(true);
        await walletService.createTransaction({
          method: contractMethod,
          contract: 'PRESALE_PUBLIC_TEST',
          data: [],
        });
        handleRefreshData();
        setCongratsText(successText);
        handleOpenCongratulationModal();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [handleOpenCongratulationModal, handleRefreshData, walletService],
  );

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('projectData + userData', projectData, userData);
  }, [projectData, userData]);

  return (
    <div className={cn(style.p_controls)}>
      <div className={style.p_controls__head}>
        <div className={style.p_controls__head__img}>
          <img src={img} alt="" />
        </div>
        <div className={style.p_controls__head__status}>
          <StatusLabel text={access} />
        </div>
        <div className={style.p_controls__head__status}>
          <StatusLabel text={statusText[0]} />
        </div>
      </div>
      <div className={style.p_controls__head__wrapper}>
        <div className={cn(style.p_controls__head__name, 'text-md text-ellipsis text-md-tablet')}>
          {name}
        </div>
        <div className={cn(style.p_controls__head__tags, 'text-gray text-sm text-sm-mobile')}>
          <div className={style.p_controls__head__tags__item}>Binance Smart Chain</div>
          <CopyToClipboard text={contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address}>
            <div
              className={cn(
                style.p_controls__head__tags__item,
                style.p_controls__head__tags__item__address,
              )}
            >
              {addressWithDots(contracts.params.PRESALE_PUBLIC_TEST[contracts.type].address)}
              <img src={copyLink} alt="" />
            </div>
          </CopyToClipboard>
          <a
            href="https://www.earthling.eco"
            target="_blank"
            rel="noreferrer"
            className={style.p_controls__head__tags__item}
          >
            Website
          </a>
        </div>
      </div>
      <div className={style.p_controls__progress}>
        <Progress
          all={+projectData.hardCap}
          totalRaised={+projectData.totalRaised}
          name={statusText[1]}
          titleClass={cn(style.p_controls__progress__title, 'text-upper', style[statusText[1]])}
        />
        <div className={style.p_controls__progress__goal}>
          <div className={style.p_controls__progress__goal__raised}>
            <img src={BscImg} alt="" />
            <span className="text-slg text-slg-tablet text-orange text-upper">{`${projectData.totalRaised} USDT`}</span>
          </div>
          <div
            className={cn(style.p_controls__progress__goal__all, 'text-gray text-smd')}
          >{`Goals ${projectData.hardCap} USDT`}</div>
        </div>
      </div>
      <div className={style.p_controls__info}>
        <div className={style.p_controls__info__item}>
          <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
            Pool Size
          </div>
          <div
            className={cn(
              style.p_controls__info__item__content,
              'text-orange text-lmd text-600 text-upper',
            )}
          >
            {calculatePoolSize(projectData.hardCap, projectData.tokenPrice)}{' '}
            {projectData.tokenSymbol}
          </div>
        </div>
        <div className={style.p_controls__info__item}>
          <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
            Hard Cap
          </div>
          <div
            className={cn(
              style.p_controls__info__item__content,
              'text-orange text-lmd text-600 text-upper',
            )}
          >
            {projectData.hardCap} USDT
          </div>
        </div>
        <div className={style.p_controls__info__item}>
          <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
            Token Price
          </div>
          <div
            className={cn(
              style.p_controls__info__item__content,
              'text-orange text-lmd text-600 text-upper',
            )}
          >
            {projectData.tokenPrice} USDT
          </div>
        </div>
      </div>

      {socials ? <Socials items={socials} /> : null}

      {!isDataLoading && (
        <>
          {(projectData.status === 'voting' || projectData.status === 'voting failed') && (
            <div className={style.p_controls__info}>
              <div className={style.p_controls__info__item_lg}>
                <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
                  Votes will end in
                </div>
                <Timer
                  deadline={+projectData.closeTimeVoting}
                  inDays
                  size="medium"
                  customCallback={handleRefreshData}
                />
              </div>
              <div className={style.p_controls__info__item_lg}>
                <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
                  Total votes
                </div>
                <div className={style.p_controls__info__item_lg__content}>
                  {projectData.totalVoters}
                </div>
              </div>
            </div>
          )}

          <div className={style.p_controls__info}>
            <div className={style.p_controls__info__item_lg}>
              <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
                {projectData.status === 'upcoming' && 'Registration Opens in:'}
                {projectData.status === 'register' && 'Registration Closed in:'}
                {projectData.status === 'register-closed' && 'Sale Start in:'}
                {projectData.status === 'open' && 'Token sale will end in:'}
              </div>
              {projectData.status === 'upcoming' && (
                <Timer
                  deadline={projectData.registrationOpens}
                  inDays
                  size="medium"
                  customCallback={handleRefreshData}
                />
              )}
              {projectData.status === 'register' && (
                <Timer
                  deadline={projectData.registrationClosed}
                  inDays
                  size="medium"
                  customCallback={handleRefreshData}
                />
              )}
              {projectData.status === 'register-closed' && (
                <Timer
                  deadline={projectData.salesStart}
                  inDays
                  size="medium"
                  customCallback={handleRefreshData}
                />
              )}
              {projectData.status === 'open' && (
                <Timer
                  deadline={projectData.salesEnd}
                  inDays
                  size="medium"
                  customCallback={handleRefreshData}
                />
              )}
            </div>
            {projectData.status === 'upcoming' && (
              <div className={style.p_controls__info__item_lg}>
                <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
                  Total votes
                </div>
                <div className={style.p_controls__info__item_lg__content}>
                  {projectData.totalVoters}
                </div>
              </div>
            )}
          </div>

          {user.address &&
            projectData.status === 'completed success' &&
            user.address.toLowerCase() !== projectData.creator.toLowerCase() && (
              <div className={style.p_controls__info}>
                <div className={style.p_controls__info__item_full}>
                  <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
                    Claimed
                  </div>
                  <div className={style.p_controls__info__item_full__content}>
                    {userData.investments.amountClaimed} Token
                  </div>
                  <div className={cn(style.p_controls__info__item__title_mt, 'text-gray text-sm')}>
                    of
                  </div>
                  <div className={style.p_controls__info__item_full__content}>
                    {userData.investments.amountTokens} Token
                  </div>
                </div>
                <div className={style.p_controls__info__item_lg}>
                  <div className={cn(style.p_controls__info__item__title_mb, 'text-gray text-sm')}>
                    Claimed
                  </div>
                </div>
                <div className={style.p_controls__info__item_lg}>
                  <div
                    className={cn(style.p_controls__info__item__title_align, 'text-gray text-sm')}
                  >
                    {userData.investments.amountClaimed}
                  </div>
                </div>
                <div className={style.p_controls__info__item_lg}>
                  <div className={cn(style.p_controls__info__item__title_mb, 'text-gray text-sm')}>
                    Ready to claim
                  </div>
                </div>
                <div className={style.p_controls__info__item_lg}>
                  <div
                    className={cn(style.p_controls__info__item__title_align, 'text-gray text-sm')}
                  >
                    {userData.investments.readyToClaim}
                  </div>
                </div>
                <div className={style.p_controls__info__item_lg}>
                  <div className={cn(style.p_controls__info__item__title_mb, 'text-gray text-sm')}>
                    Locked Balance
                  </div>
                </div>
                <div className={style.p_controls__info__item_lg}>
                  <div
                    className={cn(style.p_controls__info__item__title_align, 'text-gray text-sm')}
                  >
                    {userData.investments.amountTokens}
                  </div>
                </div>
              </div>
            )}

          {user.address ? (
            <>
              {projectData.status === 'completed success' &&
                user.address.toLowerCase() !== projectData.creator.toLowerCase() && (
                  <div className={style.p_controls__info}>
                    <div className={style.p_controls__info__item_full}>
                      <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
                        Claimed
                      </div>
                      <div className={style.p_controls__info__item_full__content}>
                        {userData.investments.amountClaimed} Token
                      </div>
                      <div
                        className={cn(style.p_controls__info__item__title_mt, 'text-gray text-sm')}
                      >
                        of
                      </div>
                      <div className={style.p_controls__info__item_full__content}>
                        {userData.investments.amountTokens} Token
                      </div>
                    </div>
                    <div className={style.p_controls__info__item_lg}>
                      <div
                        className={cn(style.p_controls__info__item__title_mb, 'text-gray text-sm')}
                      >
                        Claimed
                      </div>
                    </div>
                    <div className={style.p_controls__info__item_lg}>
                      <div
                        className={cn(
                          style.p_controls__info__item__title_align,
                          'text-gray text-sm',
                        )}
                      >
                        {userData.investments.amountClaimed}
                      </div>
                    </div>
                    <div className={style.p_controls__info__item_lg}>
                      <div
                        className={cn(style.p_controls__info__item__title_mb, 'text-gray text-sm')}
                      >
                        Ready to claim
                      </div>
                    </div>
                    <div className={style.p_controls__info__item_lg}>
                      <div
                        className={cn(
                          style.p_controls__info__item__title_align,
                          'text-gray text-sm',
                        )}
                      >
                        {userData.investments.readyToClaim}
                      </div>
                    </div>
                    <div className={style.p_controls__info__item_lg}>
                      <div
                        className={cn(style.p_controls__info__item__title_mb, 'text-gray text-sm')}
                      >
                        Locked Balance
                      </div>
                    </div>
                    <div className={style.p_controls__info__item_lg}>
                      <div
                        className={cn(
                          style.p_controls__info__item__title_align,
                          'text-gray text-sm',
                        )}
                      >
                        {userData.investments.amountTokens}
                      </div>
                    </div>
                  </div>
                )}

              {projectData.status === 'voting' && (
                <div className={style.p_controls__btns}>
                  <Button
                    color="green"
                    className={cn(style.p_controls__btn)}
                    onClick={handleVoteClick}
                    size="medium"
                    loading={isLoading}
                    disabled={
                      userData.isUserVoted ||
                      isVoteDisabled ||
                      user.address.toLowerCase() === projectData.creator.toLowerCase()
                    }
                  >
                    <LikeImg />
                    {`${calculatePercentOfVotes(projectData.voters[0], projectData.totalVoters)}%`}
                  </Button>
                  <Button
                    color="red"
                    className={cn(style.p_controls__btn)}
                    onClick={handleVoteClick}
                    size="medium"
                    loading={isLoading}
                    disabled={
                      userData.isUserVoted ||
                      isVoteDisabled ||
                      user.address.toLowerCase() === projectData.creator.toLowerCase()
                    }
                  >
                    <DislikeImg />
                    {`${calculatePercentOfVotes(projectData.voters[1], projectData.totalVoters)}%`}
                  </Button>
                </div>
              )}

              {user.address.toLowerCase() !== projectData.creator.toLowerCase() && (
                <>
                  {projectData.status === 'upcoming' && (
                    <Button
                      color="filled"
                      className={cn(style.p_controls__btn)}
                      onClick={() => {}}
                      disabled
                    >
                      Register now
                    </Button>
                  )}

                  {projectData.status === 'register' && user.level > 0 && (
                    <Button
                      color="filled"
                      className={cn(style.p_controls__btn)}
                      disabled={userData.isUserRegistered || isRegisterDisabled}
                      onClick={handleRegister}
                      loading={isLoading}
                    >
                      {userData.isUserRegistered ? 'You already registered' : 'Register now'}
                    </Button>
                  )}
                </>
              )}

              {projectData.status === 'register' && user.level === 0 && (
                <Button color="filled" className={cn(style.p_controls__btn)} href="/levels">
                  Stake for levels
                </Button>
              )}

              {projectData.status === 'register-closed' && (
                <Button
                  color="filled"
                  className={cn(style.p_controls__btn)}
                  onClick={() => {}}
                  disabled
                >
                  Buy
                </Button>
              )}

              {projectData.status === 'open' && (
                <Button
                  color="filled"
                  className={cn(style.p_controls__btn)}
                  onClick={handleOpenInvestModal}
                  disabled={!userData.canUserInvest}
                >
                  Invest now
                </Button>
              )}

              {(projectData.status === 'completed fail' ||
                projectData.status === 'voting failed') &&
                user.address.toLowerCase() === projectData.creator.toLowerCase() &&
                +projectData.tokensForSaleLeft > 0 && (
                  <Button
                    color="filled"
                    className={cn(style.p_controls__btn)}
                    onClick={() =>
                      handleCreateTransaction(
                        'withdrawTokens',
                        'Your tokens are returned successfully.',
                      )
                    }
                    loading={isLoading}
                  >
                    Return tokens
                  </Button>
                )}

              {projectData.status === 'completed fail' &&
                user.address.toLowerCase() !== projectData.creator.toLowerCase() &&
                +userData.investments.amountEth > 0 && (
                  <Button
                    color="filled"
                    className={cn(style.p_controls__btn)}
                    onClick={() =>
                      handleCreateTransaction(
                        'withdrawInvestment',
                        'Congratulations! Your refund is successful.',
                      )
                    }
                    loading={isLoading}
                  >
                    Claim Refund
                  </Button>
                )}

              {/* For creator */}
              {projectData.status === 'completed success' &&
                user.address.toLowerCase() === projectData.creator.toLowerCase() && (
                  <>
                    {+userData.liquidityAdded !== 0 ? (
                      <Button
                        color="filled"
                        className={cn(style.p_controls__btn)}
                        onClick={() =>
                          handleCreateTransaction(
                            'claimRaisedFunds',
                            'Congratulations! You have collected the funds for your project development!',
                          )
                        }
                        loading={isLoading}
                      >
                        Collect funds
                      </Button>
                    ) : (
                      <Button
                        color="filled"
                        className={cn(style.p_controls__btn)}
                        disabled={userData.addLiquidityLocked}
                        onClick={() =>
                          handleCreateTransaction(
                            'addLiquidity',
                            'The liquidity is successfully locked.',
                          )
                        }
                        loading={isLoading}
                      >
                        Add Liquidity
                      </Button>
                    )}
                  </>
                )}
              {/* For user */}
              {projectData.status === 'completed success' &&
              user.address.toLowerCase() !== projectData.creator.toLowerCase() ? (
                <Button
                  color="filled"
                  className={cn(style.p_controls__btn)}
                  disabled={userData.liquidityAdded === 0 || !userData.canUserClaim}
                  onClick={() =>
                    handleCreateTransaction(
                      'claimTokens',
                      'Congratulations! You’ve successfully claimed your tokens.',
                    )
                  }
                  loading={isLoading}
                >
                  Claim Tokens
                </Button>
              ) : null}
            </>
          ) : (
            <Button color="filled" className={cn(style.p_controls__btn)} onClick={handleConnect}>
              Connect wallet
            </Button>
          )}
        </>
      )}

      <div className={cn(style.p_controls__verify, 'text-smd')}>
        This team has passed <span className="text-bold">KYC verification</span>
      </div>
      <ConfirmationModal
        visible={isConfirmationModalOpen}
        onClose={handleCloseConfirmationModal}
        handleVote={handleVote}
      />
      <UnableToVoteModal visible={isUnableToVoteModalOpen} onClose={handleCloseUnableToVoteModal} />
      <CongratulationModal
        text={congratsText}
        visible={isVisibleCongratulationModal}
        onClose={beforeCloseCongratulationModal}
        btnText="Confirm"
        btnAction={handleCloseCongratulationModal}
      />
      <InvestModal
        tokenPrice={projectData.tokenPrice}
        tokenSymbol={projectData.tokenSymbol}
        tokenDecimals={projectData.tokenDecimals}
        maxInvestment={userData.maxUserInvestment}
        visible={isVisibleInvestModal}
        onClose={beforeCloseInvestModal}
        handleOpenCongratulationModal={handleOpenCongratulationModal}
      />
    </div>
  );
});

export default ProjectControlsTemp;
