import { FC, ReactElement, useCallback, useState } from 'react';

import cn from 'classnames';

import s from './Tabs.module.scss';

interface ITabsProps {
  contentClassName?: string;
  children: ReactElement[];
}

const Tabs: FC<ITabsProps> = ({ contentClassName, children }) => {
  const [tab, setTab] = useState(0);
  const toggleTab = useCallback((value) => {
    setTab(value);
  }, []);
  return (
    <>
      <div className={s.tab_wrapper}>
        <div className={s.line} />
        {children.map((child, index) => (
          <div
            key={child.props.label}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={() => toggleTab(index)}
            className={cn(s.tab, {
              [s.active]: tab === index,
            })}
          >
            {child.props.label}
          </div>
        ))}
      </div>
      <div className={cn(s.tab_content_wrapper, contentClassName)}>{children[tab]}</div>
    </>
  );
};

export default Tabs;
