import { FC } from 'react';

// import cn from 'classnames';
//
// import { useModal } from '../../../hooks';
// import TierInfoModal from './TierInfoModal';
// import { ReactComponent as Info } from 'assets/img/icons/i.svg';
import style from './style.module.scss';

const StakingPreview: FC = () => {
  // const [launchpadTierInfoVisible, showLaunchpadTierInfo, hideLaunchpadTierInfo] = useModal(false);
  return (
    <>
      <div className={style.container}>
        <div className={style.textInfo}>
          <h1>Staking</h1>
          <p className={style.description}>
            Stake QUACK Token to Earn another moonshot token. High APR, Low Risk!
          </p>
        </div>
        {/* <div className={style.blockLaunchpad}> */}
        {/*  <div className={cn(style.blockTitle, 'text-lmd', 'text-600')}> */}
        {/*    <div>Launchpad Tier</div> */}
        {/*    <div */}
        {/*      role="button" */}
        {/*      tabIndex={0} */}
        {/*      onKeyDown={() => {}} */}
        {/*      className={style.info_icon} */}
        {/*      onClick={showLaunchpadTierInfo} */}
        {/*    > */}
        {/*      <Info /> */}
        {/*    </div> */}
        {/*  </div> */}
        {/*  <div className={style.table}> */}
        {/*    <div className={style.table__item}> */}
        {/*      <div className={cn(style.colName, 'text-sm', 'text-gray')}>Level</div> */}
        {/*      <div className={cn(style.colValue, 'text-lmd', 'text-600')}>Gold</div> */}
        {/*    </div> */}
        {/*    <div className={style.table__item}> */}
        {/*      <div className={cn(style.colName, 'text-sm', 'text-gray')}>Weight</div> */}
        {/*      <div className={cn(style.colValue, 'text-lmd', 'text-600')}>5.5</div> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </div> */}
      </div>
      {/* <TierInfoModal visible={launchpadTierInfoVisible} onClose={hideLaunchpadTierInfo} /> */}
    </>
  );
};

export default StakingPreview;
