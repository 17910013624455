import React from 'react';

import cn from 'classnames';

import style from '../Input.module.scss';

export interface ITextArea {
  value?: any;
  onChange: (value: any) => void;
  onBlur?: (e: any) => void;
  rows?: number;
  placeholder?: string;
  className?: string;
  max?: number;
}

const TextArea: React.FC<ITextArea> = ({ className, onChange, value, max, ...props }) => {
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (max) {
        if (e.target.value.length <= max) {
          onChange(e);
        }
      } else {
        onChange(e);
      }
    },
    [max, onChange],
  );

  return (
    <>
      <textarea
        {...props}
        className={cn(style.input, className)}
        onChange={handleChange}
        value={value}
      />
      {max ? <div className={style.textarea__counter}>{`${value.length || 0}/${max}`}</div> : null}
    </>
  );
};

export default TextArea;
