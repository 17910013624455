import React, { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import BigNumber from 'bignumber.js/bignumber';
import { StakeUnstakeModal } from 'containers';
import { differenceInDays } from 'date-fns';

import { CongratulationModal } from 'components';
import { contracts } from 'config';

import { useModal } from 'hooks';
import { useWalletConnectorContext, WalletService } from 'services';
import { chainsEnum } from 'types';

import { CoinCard } from '..';
import { arrayCardData } from './mock';

import style from './style.module.scss';

const StakingInfo: FC = observer(() => {
  const { user } = useMst();
  const { connect, walletService } = useWalletConnectorContext();
  const [userStakeInfo, setUserStakeInfo] = React.useState<{
    amount: string;
    rewardTaken: string;
    rewardTakenDay: string;
    enteredAt: string;
    pendingReward: string;
  }>({
    amount: '0',
    rewardTaken: '0',
    rewardTakenDay: '0',
    enteredAt: '0',
    pendingReward: '0',
  });
  const [currentDay, setCurrentDay] = React.useState(0);

  const [isVisibleStakeUnstakeModal, handleOpenStakeUnstakeModal, handleCloseStakeUnstakeModal] =
    useModal(false);
  const [
    isVisibleCongratulationModal,
    handleOpenCongratulationModal,
    handleCloseCongratulationModal,
  ] = useModal(false);

  const [actionType, setActionType] = React.useState<'stake' | 'unstake'>('stake');
  const [entryFee, setEntryFee] = React.useState('0');
  const [unstakeFee, setUnstakeFee] = React.useState('0');
  const [earlyUnstakeFee, setEarlyUnstakeFee] = React.useState('0');
  const [totalStaked, setTotalStaked] = React.useState('0');
  const [rewards, setRewards] = React.useState<{
    perSecond: string;
    denominator: string;
  }>();

  const handleGetTotalStaked = React.useCallback(() => {
    walletService
      .callContractMethod({
        contractName: 'STAKING_OLD',
        methodName: 'totalStaked',
        data: [],
        contractAddress: contracts.params.STAKING_OLD[contracts.type].address,
        contractAbi: contracts.params.STAKING_OLD[contracts.type].abi,
      })
      .then((res) => {
        setTotalStaked(WalletService.weiToEth(res, 9));
      });
  }, [walletService]);

  const handleStakeUnstakeStart = React.useCallback(
    (type: 'stake' | 'unstake') => {
      setActionType(type);
      handleOpenStakeUnstakeModal();
      // if (localStorage[`quack_${type}_confirm`]) {
      //   handleOpenStakeUnstakeModal();
      //   return;
      // }
      // handleOpenWarningModal();
    },
    [handleOpenStakeUnstakeModal],
  );

  const handleConnect = React.useCallback(() => {
    connect(chainsEnum['Binance-Smart-Chain'], 'MetaMask');
  }, [connect]);

  const handleGetUserInfo = React.useCallback(() => {
    Promise.all([
      walletService.callContractMethod({
        contractName: 'STAKING_OLD',
        methodName: 'stakeInfo',
        data: [user.address],
        contractAddress: contracts.params.STAKING_OLD[contracts.type].address,
        contractAbi: contracts.params.STAKING_OLD[contracts.type].abi,
      }),
      walletService.callContractMethod({
        contractName: 'STAKING_OLD',
        methodName: 'pendingReward',
        data: [user.address],
        contractAddress: contracts.params.STAKING_OLD[contracts.type].address,
        contractAbi: contracts.params.STAKING_OLD[contracts.type].abi,
      }),
    ]).then((res: any) => {
      setUserStakeInfo({
        amount: WalletService.weiToEth(res[0].amount, 9),
        enteredAt: res[0].enteredAt,
        rewardTaken: WalletService.weiToEth(res[0].rewardTaken, 9),
        rewardTakenDay: res[0].rewardTakenDay,
        pendingReward: WalletService.weiToEth(res[1], 9),
      });
    });
  }, [walletService, user.address]);

  const handleHarvest = React.useCallback(() => {
    walletService
      .createTransaction({
        method: 'withdraw',
        data: [0],
        contract: 'STAKING_OLD',
      })
      .then(() => {
        handleGetUserInfo();
      });
  }, [walletService, handleGetUserInfo]);

  React.useEffect(() => {
    Promise.all([
      walletService.callContractMethod({
        contractName: 'STAKING_OLD',
        methodName: 'FEE_DENOMINATOR',
        data: [],
        contractAddress: contracts.params.STAKING_OLD[contracts.type].address,
        contractAbi: contracts.params.STAKING_OLD[contracts.type].abi,
      }),
      walletService.callContractMethod({
        contractName: 'STAKING_OLD',
        methodName: 'fees',
        data: [0],
        contractAddress: contracts.params.STAKING_OLD[contracts.type].address,
        contractAbi: contracts.params.STAKING_OLD[contracts.type].abi,
      }),
      walletService.callContractMethod({
        contractName: 'STAKING_OLD',
        methodName: 'fees',
        data: [1],
        contractAddress: contracts.params.STAKING_OLD[contracts.type].address,
        contractAbi: contracts.params.STAKING_OLD[contracts.type].abi,
      }),
      walletService.callContractMethod({
        contractName: 'STAKING_OLD',
        methodName: 'fees',
        data: [2],
        contractAddress: contracts.params.STAKING_OLD[contracts.type].address,
        contractAbi: contracts.params.STAKING_OLD[contracts.type].abi,
      }),
    ]).then((res) => {
      setEntryFee(((res[1] / res[0]) * 100).toString());
      setUnstakeFee(((res[2] / res[0]) * 100).toString());
      setEarlyUnstakeFee(((res[3] / res[0]) * 100).toString());
    });
  }, [walletService]);

  const handleGetRewards = React.useCallback(() => {
    Promise.all([
      walletService.callContractMethod({
        contractName: 'STAKING_OLD',
        methodName: 'REWARD_PER_SECOND',
        data: [],
        contractAddress: contracts.params.STAKING_OLD[contracts.type].address,
        contractAbi: contracts.params.STAKING_OLD[contracts.type].abi,
      }),
      walletService.callContractMethod({
        contractName: 'STAKING_OLD',
        methodName: 'REWARD_PER_SECOND_DENOMINATOR',
        data: [],
        contractAddress: contracts.params.STAKING_OLD[contracts.type].address,
        contractAbi: contracts.params.STAKING_OLD[contracts.type].abi,
      }),
    ])
      .then((res) => {
        setRewards({
          perSecond: WalletService.weiToEth(res[0], 9),
          denominator: res[1],
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('get rewards', err);
      });
  }, [walletService]);

  const handleGetCurrentDay = React.useCallback(() => {
    walletService
      .callContractMethod({
        contractName: 'STAKING_OLD',
        methodName: 'determineDay',
        data: [Math.ceil(new Date().getTime() / 1000)],
        contractAddress: contracts.params.STAKING_OLD[contracts.type].address,
        contractAbi: contracts.params.STAKING_OLD[contracts.type].abi,
      })
      .then((res) => {
        setCurrentDay(res);
      });
  }, [walletService]);

  React.useEffect(() => {
    handleGetTotalStaked();
    handleGetRewards();
    handleGetCurrentDay();
  }, [handleGetTotalStaked, handleGetRewards, handleGetCurrentDay]);

  React.useEffect(() => {
    if (user.address) {
      handleGetUserInfo();
    }
  }, [user.address, handleGetUserInfo]);

  const isHarvestBtn = React.useMemo(() => {
    if (user.address && userStakeInfo?.rewardTakenDay && userStakeInfo.pendingReward) {
      if (currentDay > +userStakeInfo?.rewardTakenDay && +userStakeInfo.pendingReward) {
        return true;
      }
    }
    return false;
  }, [user.address, userStakeInfo.rewardTakenDay, userStakeInfo.pendingReward, currentDay]);

  const currentFee = React.useMemo(() => {
    if (actionType === 'stake') {
      return entryFee;
    }
    if (userStakeInfo?.enteredAt) {
      const diff = differenceInDays(new Date(), +userStakeInfo.enteredAt * 1000);
      // if (is_production) {
      //   diff = differenceInDays(new Date(), +userStakeInfo.enteredAt * 1000);
      // } else {
      //   diff = differenceInMinutes(new Date(), +userStakeInfo.enteredAt * 1000);
      // }

      if (diff < 14) {
        return earlyUnstakeFee;
      }
      return unstakeFee;
    }
    return entryFee;
  }, [actionType, earlyUnstakeFee, entryFee, unstakeFee, userStakeInfo.enteredAt]);

  const isUnstakeBtn = React.useMemo(() => {
    if (userStakeInfo?.amount) {
      if (!!user.address && +userStakeInfo?.amount && !isHarvestBtn) {
        return true;
      }
    }
    return false;
  }, [userStakeInfo.amount, user.address, isHarvestBtn]);

  const apr = React.useMemo(() => {
    if (rewards?.denominator && rewards?.perSecond && +totalStaked) {
      const rps = new BigNumber(rewards?.perSecond).dividedBy(rewards?.denominator);
      const rpy = rps.multipliedBy(31536000);

      return rpy.dividedBy(totalStaked).multipliedBy(100).toFixed(2, 1);
    }
    return '0';
  }, [rewards, totalStaked]);

  return (
    <>
      {/* {user.address && ( */}
      {/*  <div className={style.block}> */}
      {/*    <img className={style.blockImg} src={Ellipse394} alt="img" /> */}
      {/*    <div className={cn(style.title, 'text-smd')}> */}
      {/*      Connnected with {addressWithDots(user.address)} */}
      {/*    </div> */}
      {/*    <div className={style.smallBlock}> */}
      {/*      <div className={style.collect}> */}
      {/*        <div className={cn(style.collectValue, 'text-lmd', 'text-orange')}>$1,427</div> */}
      {/*        <div className={cn(style.collectText)}>To collect</div> */}
      {/*      </div> */}
      {/*      <Button className={style.harvestAllButton} color="filled" onClick={() => {}}> */}
      {/*        Harvest all */}
      {/*      </Button> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* )} */}
      <div className={style.cardContainer}>
        <CoinCard
          key={arrayCardData[0].quack}
          cardData={{
            ...arrayCardData[0],
            entryFee,
            staked: userStakeInfo.amount || 0,
            quackEarned: userStakeInfo.pendingReward || '0',
            APY: apr,
            total: new BigNumber(totalStaked).toFixed(2, 1),
          }}
          isConnectBtn={!user.address}
          isHarvestBtn={isHarvestBtn}
          isStakeBtn={!!user.address && !isHarvestBtn}
          isUnstakeBtn={isUnstakeBtn}
          handleStakeUnstakeStart={handleStakeUnstakeStart}
          handleConnect={handleConnect}
          handleHarvest={handleHarvest}
        />
      </div>
      {/* <StakingWarningModal
        onClose={handleCloseWarningModal}
        visible={isVisibleWarningModal}
        type={actionType}
      /> */}
      {isVisibleStakeUnstakeModal ? (
        <StakeUnstakeModal
          visible={isVisibleStakeUnstakeModal}
          onClose={handleCloseStakeUnstakeModal}
          type={actionType}
          handleOpenCongratulationModal={handleOpenCongratulationModal}
          fee={currentFee}
          handleGetTotalStaked={handleGetTotalStaked}
        />
      ) : null}
      <CongratulationModal
        text={`The ${
          actionType === 'stake' ? 'staking' : 'unstaking'
        } process is successful. please check your pool`}
        visible={isVisibleCongratulationModal}
        onClose={handleCloseCongratulationModal}
        btnText="Confirm"
        btnAction={handleCloseCongratulationModal}
      />
    </>
  );
});

export default StakingInfo;
