import React from 'react';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import BigNumber from 'bignumber.js';
import { LevelsHarvest, LevelsPreview, LevelsSlider, LevelsStake, LevelsSteps } from 'containers';
import { differenceInDays } from 'date-fns';

import { contracts } from 'config';
import { periodsMock } from 'utils/constants';

import { useWalletConnectorContext } from 'services';
import { ILevel, IPeriod } from 'types';

import { levelsMock } from './mock';

import s from './Levels.module.scss';

const Levels: React.FC = observer(() => {
  const { user } = useMst();
  const { walletService } = useWalletConnectorContext();

  const [levels, setLevels] = React.useState<ILevel[]>(levelsMock);
  const [periods, setPeriods] = React.useState<IPeriod[]>(periodsMock);
  const [isPeriodsLoading, setPeriodsLoading] = React.useState(true);

  React.useEffect(() => {
    setPeriodsLoading(true);
    Promise.all([
      walletService.callContractMethod({
        contractName: 'STAKING',
        methodName: 'levelsInfo',
        contractAddress: contracts.params.STAKING[contracts.type].address,
        contractAbi: contracts.params.STAKING[contracts.type].abi,
      }),
      walletService.callContractMethod({
        contractName: 'STAKING',
        methodName: 'lockTypeInfo',
        contractAddress: contracts.params.STAKING[contracts.type].address,
        contractAbi: contracts.params.STAKING[contracts.type].abi,
      }),
      walletService.callContractMethod({
        contractName: 'STAKING',
        methodName: 'aprInfo',
        contractAddress: contracts.params.STAKING[contracts.type].address,
        contractAbi: contracts.params.STAKING[contracts.type].abi,
      }),
    ]).then((res) => {
      const levelsWithValue = levelsMock.map((level, index) => ({
        ...level,
        value: res[0][index]
          ? new BigNumber(res[0][index]).dividedBy(new BigNumber(10).pow(9)).toFixed()
          : '0',
      }));
      const updatePeriods = (prevPeriods: IPeriod[]) =>
        prevPeriods.map((period, index) => ({
          ...period,
          days: differenceInDays(res[1][index] * 1000 + Date.now(), Date.now()),
          apr: res[2][index] / 100,
        }));

      setLevels(levelsWithValue);
      setPeriods((v) => updatePeriods(v));
      setPeriodsLoading(false);
    });
  }, [walletService]);

  return (
    <div className={s.levels}>
      <LevelsPreview />
      <LevelsSteps items={levels} balance={user.totalStaked} />
      <LevelsHarvest />
      <LevelsSlider items={levels} userLevel={user.level} />
      <LevelsStake items={levels} periods={periods} isPeriodsLoading={isPeriodsLoading} />
    </div>
  );
});

export default Levels;
