import { FC } from 'react';

// import { mode } from 'containers/Staking/StakingFilters';
import { StakingInfo, StakingPreview } from 'containers';

import style from './Staking.module.scss';

const Staking: FC = () => {
  // const [activeMode, setMode] = useState<mode>('liveStaking');
  // const [isStakedOnly, setStakedOnly] = useState(false);
  // const [isGridMode, setGridMode] = useState(false);
  // const [isVisibleWarningModal, handleOpenWarningModal, handleCloseWarningModal] = useModal(false);

  return (
    <div className={style.staking}>
      <StakingPreview />
      <StakingInfo />
      {/* <StakingFilters
        modeData={{ activeMode, setMode }}
        stakedOnly={{ isStakedOnly, setStakedOnly }}
        viewMode={{ isGridMode, setGridMode }}
      /> */}
    </div>
  );
};

export default Staking;
