import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import cn from 'classnames';
import { ProjectControls, ProjectInfo } from 'containers';

import { GoBackLink, Loading } from 'components';

import { usePresale } from 'hooks';

import style from './Project.module.scss';

const Project: FC = observer(() => {
  const { user } = useMst();
  const { presaleId } = useParams();
  const [presales, , handleRefreshData, presaleLoading] = usePresale(
    user.address,
    true,
    '',
    presaleId,
  );

  if (presales.length === 0 || presaleLoading) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${presales[0].title} | RichQUACK`}</title>
        <meta name="description" content={presales[0].description} />
      </Helmet>
      <div className={style.project}>
        <GoBackLink link="/" text="Discover" />
        <div className={style.project__content}>
          <div className={cn(style.project__info, style.project__box)}>
            <ProjectInfo
              preview={presales[0].img}
              name={presales[0].title}
              story={presales[0].story}
            />
          </div>
          <div className={cn(style.project__box_controls)}>
            <ProjectControls
              presale={presales[0]}
              handleRefreshData={handleRefreshData}
              presaleLoading={presaleLoading}
            />
            {/* <ProjectLocks /> */}
            {/* <TokenLocks /> */}
          </div>
        </div>
        {/* <ProjectRecommended /> */}
      </div>
    </>
  );
});

export default Project;
