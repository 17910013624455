import React from 'react';

import cn from 'classnames';
import { Field, Form, FormikProps } from 'formik';

import { Button, Input, Uploader } from 'components';

import { IProjectInfoForm, IValues } from '../container';

import style from './ProjectInfo.module.scss';

const ProjectInfoForm: React.FC<FormikProps<IValues> & IProjectInfoForm> = ({
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  touched,
  handleBack,
  setFieldValue,
  values,
}) => {
  const handleUpload = React.useCallback(
    (option) => {
      const fileUrl = URL.createObjectURL(option.file);
      setFieldValue('presaleImage', option.file);
      setFieldValue('presalePreview', fileUrl);
    },
    [setFieldValue],
  );

  return (
    <Form name="project-info-form" onSubmit={handleSubmit} className={style.pi_form}>
      <div className="form__create_presale__info">
        <div className="form__create_presale__info__title">Project Image</div>
      </div>
      <div className={cn(style.pi_form__upload, 'form__create_presale__row')}>
        <Uploader
          title="Upload image"
          subtitle="Max 540x303px"
          className={style.pi_form__uploader}
          type="drag"
          accept="image/*"
          customRequest={handleUpload}
          img={values.presalePreview}
          error={touched.presalePreview && errors.presalePreview ? errors.presalePreview : ''}
        />
        <Field id="description" name="description">
          {() => (
            <Input
              name="description"
              color="dark"
              onChange={handleChange}
              component="textarea"
              max={140}
              rows={7}
              onBlur={handleBlur}
              placeholder="Short Description of your project / elevator pitch. This will be displayed in Discover page."
              value={values.description}
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  Description
                </div>
              }
              error={touched.description && errors.description ? errors.description : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.pi_form__row, 'form__create_presale__row')}>
        <Field id="title" name="title">
          {() => (
            <Input
              name="title"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="e.g. QUACK Public Presale"
              value={values.title}
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>Title</div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">The title of your project.</div>
              }
              error={touched.title && errors.title ? errors.title : ''}
            />
          )}
        </Field>
        <Field id="website" name="website">
          {() => (
            <Input
              name="website"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.website}
              placeholder="e.g. https://www.RichQuack.com"
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  Project Web URL
                </div>
              }
              subtitle={<div className="text-gray text-sm text-italic" />}
              error={touched.website && errors.website ? errors.website : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.pi_form__row, 'form__create_presale__row')}>
        <Field id="logo" name="logo">
          {() => (
            <Input
              name="logo"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.logo}
              placeholder="e.g. https://tinyurl.com/48sntcd9"
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  Project Logo URL (Please use -{' '}
                  <a
                    href="https://tinyurl.com/app"
                    target="_blank"
                    rel="noreferrer"
                    className="text-orange"
                  >
                    TinyURL
                  </a>
                  )
                </div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">
                  A web link to the image of your project&apos;s logo.
                </div>
              }
              error={touched.logo && errors.logo ? errors.logo : ''}
            />
          )}
        </Field>
        <Field id="twitter" name="twitter">
          {() => (
            <Input
              name="twitter"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.twitter}
              placeholder="e.g. https://twitter.com/richquack"
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>Twitter</div>
              }
              subtitle={<div className="text-gray text-sm text-italic">Twitter Link</div>}
              error={touched.twitter && errors.twitter ? errors.twitter : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.pi_form__row, 'form__create_presale__row')}>
        <Field id="telegram" name="telegram">
          {() => (
            <Input
              name="telegram"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.telegram}
              placeholder="e.g. https://t.me/richquack"
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>Telegram</div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">Telegram group chat link.</div>
              }
              error={touched.telegram && errors.telegram ? errors.telegram : ''}
            />
          )}
        </Field>
        <Field id="medium" name="medium">
          {() => (
            <Input
              name="medium"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.medium}
              placeholder="e.g. https://discord.gg/richquack"
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  Medium (Optional)
                </div>
              }
              subtitle={<div className="text-gray text-sm text-italic">Medium link</div>}
              error={touched.medium && errors.medium ? errors.medium : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.pi_form__row, 'form__create_presale__row')}>
        <Field id="discord" name="discord">
          {() => (
            <Input
              name="discord"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.discord}
              placeholder="e.g. https://discord.gg/richquack"
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>Discord</div>
              }
              subtitle={<div className="text-gray text-sm text-italic">Discord link</div>}
              error={touched.discord && errors.discord ? errors.discord : ''}
            />
          )}
        </Field>
        <Field id="github" name="github">
          {() => (
            <Input
              name="github"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.github}
              placeholder="e.g. https://github.com/richquack"
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  Github (Optional)
                </div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">Project&apos;s GitHub URL</div>
              }
              error={touched.github && errors.github ? errors.github : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.pi_form__row, 'form__create_presale__row')}>
        <Field id="customer" name="customer">
          {() => (
            <Input
              name="customer"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.customer}
              placeholder="KYC Documents"
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  KYC Documents (Optional)
                </div>
              }
              subtitle={<div className="text-gray text-sm text-italic"> KYC Documents</div>}
              error={touched.customer && errors.customer ? errors.customer : ''}
            />
          )}
        </Field>
        <Field id="whitepaper" name="whitepaper">
          {() => (
            <Input
              name="whitepaper"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.whitepaper}
              placeholder="Link to your project's whitepaper."
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  Whitepaper URL (Optional)
                </div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">
                  Link to your project&apos;s whitepaper.
                </div>
              }
              error={touched.whitepaper && errors.whitepaper ? errors.whitepaper : ''}
            />
          )}
        </Field>
      </div>
      <div className="form__create_presale__box">
        <div className="text-gray form__create_presale__text" />
        <div className="form__create_presale__btns">
          <Button
            className="form__create_presale__btns__item back"
            onClick={handleBack}
            size="medium"
          >
            Back
          </Button>
          <Button
            color={Object.values(errors).length ? 'disabled' : 'filled'}
            size="medium"
            type="submit"
            className="form__create_presale__btns__item"
          >
            Next
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ProjectInfoForm;
