import { useCallback, useEffect, useState } from 'react';

import { useWalletConnectorContext, WalletService } from 'services';
import { TNullable } from 'types';

export default (
  userAddress: TNullable<string>,
  tokenAddress: string,
  refresh = false,
  isIntervalUpdate = false,
): [string, number] => {
  const [balance, setBalance] = useState<string>('');
  const [decimals, setDecimals] = useState(0);

  const { walletService } = useWalletConnectorContext();

  const getUserTokenBalance = useCallback(async () => {
    const tokenDecimals = await walletService.getTokenDecimals(tokenAddress);
    setDecimals(tokenDecimals);
    const tokenBalance = await walletService.getTokenBalance(tokenAddress);

    setBalance(WalletService.weiToEth(tokenBalance.toString(), tokenDecimals));
  }, [tokenAddress, walletService]);

  useEffect(() => {
    let interval: any = null;
    if (userAddress || (userAddress && refresh)) {
      getUserTokenBalance();

      if (isIntervalUpdate && !interval) {
        interval = setInterval(getUserTokenBalance, 30000);
      }
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [getUserTokenBalance, userAddress, refresh, isIntervalUpdate]);

  return [balance, decimals];
};
