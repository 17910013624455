import { cardData } from '../CoinCard';

import badge from 'assets/img/mock/staking/Badge.svg';
import firstCircleImg from 'assets/img/mock/staking/Ellipse297.png';
import firstBackgroundImg from 'assets/img/mock/staking/Rectangle360.png';

export const arrayCardData: Array<cardData> = [
  {
    backgroundImg: firstBackgroundImg,
    circleImg: firstCircleImg,
    badge,
    quack: 'QUACK POOL',
    APY: '76%',
    entryFee: '0.3%',
    staked: 0,
    quackEarned: '100,000',
    value: '$0,00',
    tvl: '12,324,231',
    total: '2,319,281,457,445',
  },
];
