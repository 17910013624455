import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowImg } from 'assets/img/icons/go-back-arrow.svg';

import style from './GoBackLink.module.scss';

interface IGoBackLink {
  text?: string;
  link?: string;
}

const GoBackLink: React.FC<IGoBackLink> = ({ link, text }) => {
  const navigate = useNavigate();
  const handleLink = React.useCallback(() => {
    if (!link) {
      navigate(-1);
      return;
    }
    navigate(link);
  }, [navigate, link]);

  return (
    <div
      className={style.back}
      onClick={handleLink}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
    >
      <ArrowImg className={style.img} />
      <span className="text-smd">{`Back ${text ? `to ${text}` : ''}`}</span>
    </div>
  );
};

export default GoBackLink;
