import { Link } from 'react-router-dom';

export const roadmap = [
  {
    title: 'Launch QUACK V2',
    content: (
      <>
        <Link to="/">Tech Rate Security Audit</Link>
        <ul>
          <li>Airdrop to V1 Holder</li>
          <li>9 June 2021</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Day 2',
    content: <p>$25K for Ads, News Publication & Influencer.</p>,
  },
  {
    title: 'Day 3',
    content: (
      <ul>
        <li>Featured on CMC News as</li>
        <li>&quot;The next DOGE competitor&quot;</li>
      </ul>
    ),
  },
  {
    title: 'Day 6',
    content: <p>ATH 1B QUACK = $0.16536</p>,
  },
  {
    title: 'Day 7',
    content: <p>Coin Gecko Listing</p>,
  },
  {
    title: 'Day 16',
    content: <p>Coin Market Cap Listing</p>,
  },
  {
    title: 'Day 20',
    content: (
      <ul>
        <li>Surprise HotBit Listing</li>
        <li>Confirmation</li>
      </ul>
    ),
  },
  {
    title: 'Day 21',
    content: (
      <ul>
        <li>First day of Q3. Team completed all Q3 Task from QUACK</li>
        <li>Confirmation</li>
      </ul>
    ),
  },
  {
    title: 'Day 27',
    content: <p>HotBit AMA & Official Listing</p>,
  },
  {
    title: 'Day 28',
    content: (
      <ul>
        <li>Top 10 Most Searched</li>
        <li>on CoinMarketCap</li>
      </ul>
    ),
  },
  {
    title: 'Day 30',
    content: (
      <ul>
        <li>Top 10 Most Searched</li>
        <li>on CoinMarketCap</li>
      </ul>
    ),
  },
];
