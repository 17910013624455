import React from 'react';

import { withFormik } from 'formik';
import * as Yup from 'yup';

import { IForm } from 'pages/CreateProject';

import Form from '../component';

export interface IValues {
  name: string;
  email: string;
  telegramUsername: string;
}

export interface IProjectCreatorInfo {
  handleBack: () => void;
  handleUpdateData: (key: keyof IForm, data: IForm[typeof key]) => void;
  initialValues: IValues;
  isEndStep: boolean;
  btnText: string;
  isLoading: boolean;
  isApprovingToken: boolean;
  isApprovingLink: boolean;
}

export type IProjectCreatorInfoForm = Pick<
  IProjectCreatorInfo,
  'handleBack' | 'isEndStep' | 'btnText' | 'isLoading' | 'isApprovingToken' | 'isApprovingLink'
>;

const ProjectCreatorInfo: React.FC<IProjectCreatorInfo> = ({
  handleBack,
  handleUpdateData,
  initialValues,
  isEndStep,
  btnText,
  isLoading,
  isApprovingToken,
  isApprovingLink,
}) => {
  const FormWithFormik = withFormik<IProjectCreatorInfoForm, IValues>({
    enableReinitialize: true,
    mapPropsToValues: () => initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Email is invalid').required('Name is required'),
      telegramUsername: Yup.string().required('Telegram is required'),
    }),

    handleSubmit: (values) => {
      handleUpdateData('projectCreatorInfo', values);
    },

    displayName: 'ProjectCreatorInfo',
  })(Form);
  return (
    <FormWithFormik
      handleBack={handleBack}
      isEndStep={isEndStep}
      btnText={btnText}
      isLoading={isLoading}
      isApprovingToken={isApprovingToken}
      isApprovingLink={isApprovingLink}
    />
  );
};

export default ProjectCreatorInfo;
