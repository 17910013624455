import React from 'react';
import DatePicker from 'react-datepicker';

import cn from 'classnames';

import { IPicker } from '../DatePicker';
import { IInput } from '../Input';
import { ITextArea } from '../TextArea';
import { Input, Picker, TextArea } from '..';

import { ReactComponent as DateImg } from 'assets/img/icons/date.svg';

import style from '../Input.module.scss';

interface IInputColor {
  color?: 'blue' | 'dark';
}

interface IInputWrapper extends IInputColor, IInput, IPicker, ITextArea {
  title?: React.ReactElement;
  prefix?: React.ReactElement;
  postfix?: React.ReactElement;
  component?: 'input' | 'textarea' | 'date';
  error?: string;
  subtitle?: React.ReactElement;
  className?: string;
  disabled?: boolean;
}

const InputWrapper: React.FC<IInputWrapper> = ({
  title,
  prefix,
  postfix,
  component = 'input',
  color = 'blue',
  error,
  subtitle,
  className,
  ...other
}) => {
  const datePickerRef = React.useRef<DatePicker>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleClickDateImg = React.useCallback(() => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus();
    }
  }, []);

  const handleClickPrefixPostfix = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className={cn(style.wrapper, className)}>
      {title ? <div className={style.title}>{title}</div> : null}
      <div className={cn(style.content, style[`content_${color}`])}>
        {prefix ? (
          <div
            className={style.prefix}
            onClick={handleClickPrefixPostfix}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            {prefix}
          </div>
        ) : null}
        {component === 'input' ? <Input ref={inputRef} {...other} /> : null}
        {component === 'date' ? <Picker ref={datePickerRef} {...other} /> : null}
        {component === 'date' ? (
          <div
            className={style.date}
            onClick={handleClickDateImg}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <DateImg />
          </div>
        ) : null}
        {component === 'textarea' ? <TextArea {...other} /> : null}
        {postfix ? (
          <div
            className={style.postfix}
            onClick={handleClickPrefixPostfix}
            onKeyDown={() => {}}
            role="button"
            tabIndex={-1}
          >
            {postfix}
          </div>
        ) : null}
      </div>
      {error ? <div className={style.error}>{error}</div> : null}
      {subtitle ? <div className={style.subtitle}>{subtitle}</div> : null}
    </div>
  );
};

export default InputWrapper;
