import { FC } from 'react';

import cn from 'classnames';

import { Button, Modal } from 'components';

import { IModalProps } from 'types';

import { items } from './mock';

import itemArrow from 'assets/img/icons/item_arrow.svg';

import style from './style.module.scss';

interface IConfirmationModal extends IModalProps {
  handleVote: () => void;
}

const ConfirmationModal: FC<IConfirmationModal> = ({ visible, onClose, handleVote }) => (
  <Modal className={style.modal} visible={visible} onClose={onClose}>
    <div className={cn(style.title, 'text-slg', 'text-bold')}>Confirmation</div>
    <div className={cn(style.description, 'text-smd')}>
      Your about to vote Rebasing Protocol Launch Event. The action be undone
    </div>
    <div className={cn(style.confirm, 'text-smd', 'text-orange')}>
      Please confirm that you are aware of spotting Scams.
    </div>
    {items.map((item: string) => (
      <div key={item} className={cn(style.item, 'text-smd')}>
        <img className={cn(style.itemArrow, 'text-smd')} src={itemArrow} alt="item arrow" />
        {item}
      </div>
    ))}
    <div className={style.control}>
      <Button className={style.disagree} onClick={onClose}>
        No
      </Button>
      <Button className={style.agree} color="filled" onClick={handleVote}>
        Yes
      </Button>
    </div>
  </Modal>
);

export default ConfirmationModal;
