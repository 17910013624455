import cn from 'classnames';

import img1 from 'assets/img/mock/earthling_1.png';
import img2 from 'assets/img/mock/earthling_2.png';
import img3 from 'assets/img/mock/earthling_3.png';
import img4 from 'assets/img/mock/earthling_4.png';
import img5 from 'assets/img/mock/earthling_5.png';
import img6 from 'assets/img/mock/earthling_6.png';
import img7 from 'assets/img/mock/earthling_7.png';
import img8 from 'assets/img/mock/earthling_8.png';
import img9 from 'assets/img/mock/earthling_9.png';

import style from './ProjectInfo.module.scss';

export const project = [
  {
    title: 'The Story behind Earthling',
    text: (
      <div className={cn(style.p_info__text, 'text-gray')}>
        <p>
          “You cannot escape the responsibility of tomorrow by evading it today” - Abraham Lincoln
        </p>
        <p>
          Humans spent billions of dollars to escape into another realm with movies, games,
          metaverse, psychedelics and the ideas of becoming a multi-planetary yet we forget what
          truly matters, our mother earth.
        </p>
        <p>
          We are a group of geeks, vegan, meat eaters, flexitarian, wannabe environmental activists,
          gamers that believe that we all have been part of the problem, now it&lsquo;s time to be
          part of the solution!
        </p>
        <p>
          Inspired by the ideas of planetary consciousness that human beings are members of a
          planetary society of Earth. We call ourselves Earthling so that we are no longer divided
          by race, nation, districts, island, cities, or villages in this movement to awaken global
          consciousness.
        </p>
        <p>
          Earthling will build a platform that helps individuals, families and businesses remove
          carbon, restore nature and become ‘Carbon Neutral&lsquo; by supporting smallholder farmers
          in the tropics with reforestation.
        </p>
      </div>
    ),
    img: [img1],
  },
  {
    title: 'Features',
    text: (
      <div className={cn(style.p_info__text, 'text-gray')}>
        <h5>Measure Carbon Footprint</h5>
        <p>
          Take the first step into restoring earth balance by calculating and understand your carbon
          footprint. Tracker will be used by individuals and businesses to measure their carbon
          emission.
        </p>
        <h5>Erase Your Footprint</h5>
        <p>
          We want to spread the awareness and empower people to reverse global warming and
          ecological collapse into their own hands. Now everyone can easily offset their carbon
          footprint by buying carbon credits from high quality nature based solutions, supporting
          projects that remove carbon, restore earth & support people. Showcase your impact
          investment portfolio with everyone through engaging visualization on Earhling.
        </p>
        <h5>Earthling DAO</h5>
        <p>
          Earthlings can discuss potential projects on our discord and vote on the direct
          distribution of funds to reforestation programs or other impactful non-profit organization
          that need funding.
        </p>
      </div>
    ),
    img: [],
  },
  {
    title: 'Token Economy',
    text: null,
    beforeImg: [img2, img3],
  },
  {
    title: null,
    text: (
      <div className={cn(style.p_info__text, 'text-gray')}>
        <p>
          Carbon token is a tokenization of carbon credits, aims to increase the security of carbon
          credit transactions and trackability by further programming into the blockchain, an asset
          that is already digital, and improves the possibility of composability of carbon credits.
        </p>
        <p>
          Carbon Token is a proof of your carbon credit and this coin will be backed entirely by
          carbon credit. Every Carbon token minted equals to 1 Carbon credit, a digital certificate
          that lets you emit 1 tonne of CO2. Initially, we will work with a high quality nature
          based reforestation program and then hopefully we can build our own reforestation program
          in the future.
        </p>
      </div>
    ),
    img: [img4],
  },
  {
    title: 'How are we going to do it?',
    text: (
      <div className={cn(style.p_info__text, 'text-gray')}>
        <p />
      </div>
    ),
    img: [img5],
  },
  {
    title: 'Token Distribution',
    text: (
      <div className={cn(style.p_info__text, 'text-gray')}>
        <p />
      </div>
    ),
    img: [img6],
  },
  {
    title: 'Marketing Plan',
    text: (
      <div className={cn(style.p_info__text, 'text-gray')}>
        <h5>TARGET MARKET</h5>
        <p>
          Earthling aims to reach out to everyone who wants to make a positive impact for the
          environment, but simply don’t have the time to contribute or make a proper research about
          high quality nature based investment. We will start by building a simple app for
          individuals to start measuring their daily emission rate and also educate people about
          carbon emission and how to offset their carbon emissions.
        </p>
        <h5>PRELAUNCH</h5>
        <p>
          Earthling will be releasing teasers and previews of assets, visual and snippets of the
          tracker, carbon offset marketplace, and partners that we will be working with. Content
          will be shared across all of our marketing campaigns on all social media and we will
          engage the community through airdrops, contests, giveaways and many other events.
        </p>
        <h5>POSTLAUNCH</h5>
        <p>
          Shortly after launch, Earthling will announce a genesis staking event which offers users
          attractive APY. People will be able to invest in multiple high quality projects to offset
          their carbon emissions. We will start working with PR & Digital Marketing Agency to
          acquire users to our platform. There will be referral incentives programs for token
          holders who help us spread the word about our Earthling platform. We are also considering
          multiple rounds of NFT x Adopt a forest program to help legit reforestation programs raise
          money.
        </p>
      </div>
    ),
    img: [],
  },
  {
    title: 'Revenue Generation',
    text: (
      <div className={cn(style.p_info__text, 'text-gray')}>
        <p />
      </div>
    ),
    img: [img7],
  },
  {
    title: 'Team',
    text: (
      <div className={cn(style.p_info__text, 'text-gray')}>
        <p />
      </div>
    ),
    img: [img8],
  },
  {
    title: 'Investors',
    text: (
      <div className={cn(style.p_info__text, 'text-gray')}>
        <p />
      </div>
    ),
    img: [img9],
  },
];
