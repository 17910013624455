import telegram from 'assets/img/icons/telegram.svg';
import twitter from 'assets/img/icons/twitter.svg';
import web from 'assets/img/icons/web.svg';

export const socials = [
  {
    name: 'web',
    link: '/',
    icon: web,
  },
  {
    name: 'twitter',
    link: '/',
    icon: twitter,
  },
  {
    name: 'telegram',
    link: '/',
    icon: telegram,
  },
];
