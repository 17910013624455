import React from 'react';

import cn from 'classnames';

import { Button, Modal } from 'components';

import { IModalProps } from 'types';

import ModalImg from 'assets/img/congrat.svg';

import style from './CongratulationModal.module.scss';

interface ICongratulationModal extends IModalProps {
  btnAction: () => void;
  btnText: string;
  text?: string;
}

const CongratulationModal: React.FC<ICongratulationModal> = ({
  visible,
  onClose,
  btnAction,
  text,
  btnText,
}) => {
  return (
    <Modal visible={visible} onClose={onClose}>
      <div className={style.congrat_modal}>
        <img src={ModalImg} alt="" />
        <div className={style.congrat_modal__title}>Congratulation !</div>
        {text ? <div className={cn(style.congrat_modal__subtitle, 'text-gray')}>{text}</div> : null}
        <Button color="filled" onClick={btnAction} className={style.congrat_modal__btn}>
          {btnText}
        </Button>
      </div>
    </Modal>
  );
};

export default CongratulationModal;
