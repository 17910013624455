import { FC } from 'react';

import { ThemeEnum, useMst } from '../../../../store';
import { observer } from 'mobx-react-lite';

import cn from 'classnames';
import { Circle } from 'rc-progress';
import Tooltip from 'rc-tooltip';

import 'rc-tooltip/assets/bootstrap.css';
import s from './Chart.module.scss';

const charts = [
  {
    name: 'presale',
    percent: 100,
  },
  {
    name: 'liquidity',
    percent: 66,
  },
  {
    name: 'fees',
    percent: 80,
  },
  {
    name: 'locked',
    percent: 66,
  },
  {
    name: 'unlocked',
    percent: 33,
  },
];

const Chart: FC = observer(() => {
  const { theme } = useMst();
  return (
    <div className={s.chart_wrapper}>
      <div className={s.chart}>
        {charts.map((chart, index) => (
          <Tooltip
            key={chart.name}
            prefixCls="chart-tooltip"
            placement="right"
            destroyTooltipOnHide
            overlay={
              <div className={cn(s.legend, s.tooltip, s[chart.name], s[theme.value])}>
                {chart.name}
              </div>
            }
          >
            <div className={cn(s.chart_circle, s[chart.name])}>
              <Circle
                key={chart.name}
                percent={chart.percent}
                strokeWidth={7 + index * index * 1.04}
                strokeColor="#000"
                trailColor={theme.value === ThemeEnum.dark ? '#14163c' : '#FFFFFF'}
              />
            </div>
          </Tooltip>
        ))}
      </div>
      <div className={s.legends}>
        {charts.map((chart) => (
          <div key={chart.name} className={cn(s.legend, s[chart.name])}>
            {chart.name}
          </div>
        ))}
      </div>
    </div>
  );
});

export default Chart;
