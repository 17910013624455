import React from 'react';

import { withFormik } from 'formik';
import * as Yup from 'yup';

import { IForm } from 'pages/CreateProject';

import { TOptionable } from 'types';

import Form from '../component';

export interface IValues {
  lockingProvider: string;
  lockPurpose: string;
  expiryDate: TOptionable<Date>;
  proofLink: string;
}

export interface ITokenLockingReports {
  handleBack: () => void;
  handleUpdateData: (key: keyof IForm, data: IForm[typeof key]) => void;
  initialValues: IValues;
}

export type ITokenLockingReportsForm = Pick<ITokenLockingReports, 'handleBack'>;

const TokenLockingReports: React.FC<ITokenLockingReports> = ({
  handleBack,
  handleUpdateData,
  initialValues,
}) => {
  const FormWithFormik = withFormik<ITokenLockingReportsForm, IValues>({
    enableReinitialize: true,
    mapPropsToValues: () => initialValues,
    validationSchema: Yup.object().shape({
      lockingProvider: Yup.string().required('Token Locking Provider is required'),
      lockPurpose: Yup.string().required('Token Lock Purpose is required'),
      expiryDate: Yup.date()
        .min(new Date(), 'Liquidity Locking Expiry Date is should be greater than current day')
        .required('Liquidity Locking Expiry Date is required'),
      proofLink: Yup.string()
        .url('Link to proof your Token Lock must be an url')
        .required('Link to proof your Token Lock is required'),
    }),

    handleSubmit: (values) => {
      handleUpdateData('tokenLockingReports', values);
    },

    displayName: 'TokenLockingReports',
  })(Form);
  return <FormWithFormik handleBack={handleBack} />;
};

export default TokenLockingReports;
