import React from 'react';

import { observer } from 'mobx-react-lite';
import { ThemeEnum, useMst } from 'store';

import cn from 'classnames';

import style from './Empty.module.scss';

interface IEmpty {
  title: string;
  subtitle?: string;
  imgDark: string;
  imgLight: string;
}

const Empty: React.FC<IEmpty> = observer(({ title, subtitle, imgDark, imgLight }) => {
  const { theme } = useMst();
  return (
    <div className={style.empty}>
      <img src={theme.value === ThemeEnum.dark ? imgDark : imgLight} alt="" />
      <div className={style.empty__title}>{title}</div>
      {subtitle ? <div className={cn(style.empty__text, 'text-gray')}>{subtitle}</div> : null}
    </div>
  );
});

export default Empty;
