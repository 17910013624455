import React from 'react';

import BigNumber from 'bignumber.js/bignumber';
import cn from 'classnames';
import { addDays } from 'date-fns';
import { Field, Form, FormikProps } from 'formik';

import { Button, Input, Selector } from 'components';

import { ITokenPoolInfoForm, IValues } from '../container';

import style from './TokenPoolInfo.module.scss';

const fundingTokens = [
  {
    value: 'USDT',
  },
];

const TokenPoolInfoForm: React.FC<FormikProps<IValues> & ITokenPoolInfoForm> = ({
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  values,
  handleBack,
}) => {
  const calculateTokenPoolSize = React.useCallback(
    (field: string, e: React.ChangeEvent<any>) => {
      handleChange(e);
      if (field === 'hardCap') {
        setFieldValue(
          'tokenPool',
          new BigNumber(e.target.value).dividedBy(values.tokenIdoPrice || 1).toString(),
        );
      } else {
        setFieldValue(
          'tokenPool',
          new BigNumber(values.hardCap || 0).dividedBy(e.target.value).toString(),
        );
      }
      if (+e.target.value === 0) {
        setFieldValue('tokenPool', '0');
      }
    },
    [handleChange, setFieldValue, values.hardCap, values.tokenIdoPrice],
  );

  return (
    <Form name="token-pool-info-form" onSubmit={handleSubmit} className={style.tok_form}>
      <div className={cn(style.tok_form__row, 'form__create_presale__row')}>
        <Field id="tokenContractAddress" name="tokenContractAddress">
          {() => (
            <Input
              name="tokenContractAddress"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={`e.g. ${'0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7'.toLowerCase()}`}
              value={values.tokenContractAddress}
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  BEP20 Token Contract
                </div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The BEP20 Token Address of your project&apos;s smart contract.
                </div>
              }
              error={
                touched.tokenContractAddress && errors.tokenContractAddress
                  ? errors.tokenContractAddress
                  : ''
              }
            />
          )}
        </Field>
        <Field id="ticker" name="ticker">
          {() => (
            <Input
              name="ticker"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="e.g. QUACK"
              value={values.ticker}
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>Ticker</div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The ticker symbol as listed on BSCscan
                </div>
              }
              error={touched.ticker && errors.ticker ? errors.ticker : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.tok_form__row, 'form__create_presale__row')}>
        <Field id="tokenSupply" name="tokenSupply">
          {() => (
            <Input
              name="tokenSupply"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              isNumber
              positiveOnly
              placeholder="e.g. 100,000,000,000,000 QUACK"
              value={values.tokenSupply?.toString() || ''}
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  Token Supply
                </div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The total token supply including burn, locked, etc.
                </div>
              }
              error={touched.tokenSupply && errors.tokenSupply ? errors.tokenSupply : ''}
            />
          )}
        </Field>
        <Field id="tokenPool" name="tokenPool">
          {() => (
            <Input
              name="tokenPool"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              isNumber
              positiveOnly
              placeholder="e.g. 2,500,000 Token"
              value={values.tokenPool?.toString() || ''}
              readonly
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  Token Pool Size
                </div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The amount of token being offered in the IDO.
                </div>
              }
              error={touched.tokenPool && errors.tokenPool ? errors.tokenPool : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.tok_form__row, 'form__create_presale__row')}>
        <Field id="fundingToken" name="fundingToken">
          {() => (
            <Selector
              color="dark"
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  Funding Token (USDT)
                </div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The token you want to use for your fund raising.
                </div>
              }
              size="big"
              defaultOption={{ value: '' }}
              options={fundingTokens}
              onSelect={(option) => setFieldValue('fundingToken', option.value)}
              error={touched.fundingToken && errors.fundingToken ? errors.fundingToken : ''}
            />
          )}
        </Field>
        <Field id="tokenIdoPrice" name="tokenIdoPrice">
          {() => (
            <Input
              name="tokenIdoPrice"
              color="dark"
              onChange={(e) => calculateTokenPoolSize('tokenIdoPrice', e)}
              onBlur={handleBlur}
              isNumber
              positiveOnly
              placeholder="e.g. 0.0008 USDT per QUACK"
              value={values.tokenIdoPrice?.toString() || ''}
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  Token IDO Price
                </div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The price of your token per unit in USDT
                </div>
              }
              error={touched.tokenIdoPrice && errors.tokenIdoPrice ? errors.tokenIdoPrice : ''}
            />
          )}
        </Field>
      </div>
      <div className={cn(style.tok_form__row, 'form__create_presale__row')}>
        <Field id="softCap" name="softCap">
          {() => (
            <Input
              name="softCap"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              isNumber
              positiveOnly
              placeholder="e.g. 100,000 USDT"
              value={values.softCap?.toString() || ''}
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  SoftCap (USDT)
                </div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The minimum amount of BNB required from a pool to be launched on QUACK Funding.
                </div>
              }
              error={touched.softCap && errors.softCap ? errors.softCap : ''}
            />
          )}
        </Field>
        <Field id="hardCap" name="hardCap">
          {() => (
            <Input
              name="hardCap"
              color="dark"
              onChange={(e) => calculateTokenPoolSize('hardCap', e)}
              onBlur={handleBlur}
              isNumber
              positiveOnly
              placeholder="e.g. 200,000 USDT"
              value={values.hardCap?.toString() || ''}
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  HardCap (USDT)
                </div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The total amount of BNB that can be pooled before launch.
                </div>
              }
              error={touched.hardCap && errors.hardCap ? errors.hardCap : ''}
            />
          )}
        </Field>
      </div>
      {/* <div className={cn(style.tok_form__row, 'form__create_presale__row')}> */}
      {/*  <Field id="marketCap" name="marketCap"> */}
      {/*    {() => ( */}
      {/*      <Input */}
      {/*        name="marketCap" */}
      {/*        color="dark" */}
      {/*        onChange={handleChange} */}
      {/*        onBlur={handleBlur} */}
      {/*        isNumber */}
      {/*        positiveOnly */}
      {/*        placeholder="e.g. $4,200,000 BUSD" */}
      {/*        value={values.marketCap?.toString() || ''} */}
      {/*        title={ */}
      {/*          <div className={cn(style.d_form__input__label, 'text-600 text-sm')}> */}
      {/*            Initial Market Cap (USD) */}
      {/*          </div> */}
      {/*        } */}
      {/*        subtitle={ */}
      {/*          <div className="text-gray text-sm text-italic"> */}
      {/*            The limit of the number of decimals for your token quantities. */}
      {/*          </div> */}
      {/*        } */}
      {/*        error={touched.marketCap && errors.marketCap ? errors.marketCap : ''} */}
      {/*      /> */}
      {/*    )} */}
      {/*  </Field> */}
      {/*  <Field id="tokenCirculation" name="tokenCirculation"> */}
      {/*    {() => ( */}
      {/*      <Input */}
      {/*        name="tokenCirculation" */}
      {/*        color="dark" */}
      {/*        onChange={handleChange} */}
      {/*        onBlur={handleBlur} */}
      {/*        isNumber */}
      {/*        positiveOnly */}
      {/*        placeholder="e.g. 5,000,000,000,000 QUACK" */}
      {/*        value={values.tokenCirculation?.toString() || ''} */}
      {/*        title={ */}
      {/*          <div className={cn(style.d_form__input__label, 'text-600 text-sm')}> */}
      {/*            Initial Token Circulation */}
      {/*          </div> */}
      {/*        } */}
      {/*        subtitle={ */}
      {/*          <div className="text-gray text-sm text-italic"> */}
      {/*            The total amount Token in Circulation after IDO excluding liquidity. */}
      {/*          </div> */}
      {/*        } */}
      {/*        error={ */}
      {/*          touched.tokenCirculation && errors.tokenCirculation ? errors.tokenCirculation : '' */}
      {/*        } */}
      {/*      /> */}
      {/*    )} */}
      {/*  </Field> */}
      {/* </div> */}
      <div className={cn(style.tok_form__row, 'form__create_presale__row')}>
        <Field id="saleStartTime" name="saleStartTime">
          {() => (
            <Input
              name="saleStartTime"
              component="date"
              minDate={addDays(new Date(), 15)}
              value={new Date(values.saleStartTime ? values.saleStartTime : new Date())}
              onBlur={handleBlur('saleStartTime')}
              onChange={(value) => setFieldValue('saleStartTime', value, true)}
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  Sale Start Time (UTC)
                </div>
              }
              color="dark"
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The date and time your presale will begin accepting investor funds.
                </div>
              }
              error={touched.saleStartTime && errors.saleStartTime ? errors.saleStartTime : ''}
            />
          )}
        </Field>
        <Field id="saleEndTime" name="saleEndTime">
          {() => (
            <Input
              name="saleEndTime"
              component="date"
              minDate={addDays(new Date(), 16)}
              value={new Date(values.saleEndTime ? values.saleEndTime : new Date())}
              onBlur={handleBlur('saleEndTime')}
              onChange={(value) => setFieldValue('saleEndTime', value, true)}
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  Sale End Time (UTC)
                </div>
              }
              color="dark"
              subtitle={
                <div className="text-gray text-sm text-italic">
                  The date and time your presale will stop accepting investor funds.
                </div>
              }
              error={touched.saleEndTime && errors.saleEndTime ? errors.saleEndTime : ''}
            />
          )}
        </Field>
      </div>
      {/* <div className={cn(style.tok_form__row, 'form__create_presale__row')}> */}
      {/*  <Field id="minContribution" name="minContribution"> */}
      {/*    {() => ( */}
      {/*      <Input */}
      {/*        name="minContribution" */}
      {/*        color="dark" */}
      {/*        onChange={handleChange} */}
      {/*        onBlur={handleBlur} */}
      {/*        isNumber */}
      {/*        positiveOnly */}
      {/*        placeholder="e.g. 200 BUSD" */}
      {/*        value={values.minContribution?.toString() || ''} */}
      {/*        title={ */}
      {/*          <div className={cn(style.d_form__input__label, 'text-600 text-sm')}> */}
      {/*            Minimum Contribution (BUSD) */}
      {/*          </div> */}
      {/*        } */}
      {/*        subtitle={ */}
      {/*          <div className="text-gray text-sm text-italic"> */}
      {/*            The minimum contribution allowed in private sales. */}
      {/*          </div> */}
      {/*        } */}
      {/*        error={ */}
      {/*          touched.minContribution && errors.minContribution ? errors.minContribution : '' */}
      {/*        } */}
      {/*      /> */}
      {/*    )} */}
      {/*  </Field> */}
      {/*  <Field id="maxContribution" name="maxContribution"> */}
      {/*    {() => ( */}
      {/*      <Input */}
      {/*        name="maxContribution" */}
      {/*        color="dark" */}
      {/*        onChange={handleChange} */}
      {/*        onBlur={handleBlur} */}
      {/*        isNumber */}
      {/*        positiveOnly */}
      {/*        placeholder="e.g. 400 BUSD" */}
      {/*        value={values.maxContribution?.toString() || ''} */}
      {/*        title={ */}
      {/*          <div className={cn(style.d_form__input__label, 'text-600 text-sm')}> */}
      {/*            Maximum Contribution (BUSD) */}
      {/*          </div> */}
      {/*        } */}
      {/*        subtitle={ */}
      {/*          <div className="text-gray text-sm text-italic"> */}
      {/*            The maximum contribution allower in private sales. */}
      {/*          </div> */}
      {/*        } */}
      {/*        error={ */}
      {/*          touched.maxContribution && errors.maxContribution ? errors.maxContribution : '' */}
      {/*        } */}
      {/*      /> */}
      {/*    )} */}
      {/*  </Field> */}
      {/* </div> */}
      <div className={cn(style.tok_form__row, 'form__create_presale__row')}>
        {/* <Field id="tokenDecimals" name="tokenDecimals"> */}
        {/*  {() => ( */}
        {/*    <Input */}
        {/*      name="tokenDecimals" */}
        {/*      color="dark" */}
        {/*      onChange={handleChange} */}
        {/*      onBlur={handleBlur} */}
        {/*      isNumber */}
        {/*      positiveOnly */}
        {/*      integer */}
        {/*      placeholder="0" */}
        {/*      value={values.tokenDecimals?.toString() || ''} */}
        {/*      title={ */}
        {/*        <div className={cn(style.d_form__input__label, 'text-600 text-sm')}> */}
        {/*          Token Decimals */}
        {/*        </div> */}
        {/*      } */}
        {/*      subtitle={ */}
        {/*        <div className="text-gray text-sm text-italic"> */}
        {/*          The limit of the number of decimals for your token quantities. */}
        {/*        </div> */}
        {/*      } */}
        {/*      error={touched.tokenDecimals && errors.tokenDecimals ? errors.tokenDecimals : ''} */}
        {/*    /> */}
        {/*  )} */}
        {/* </Field> */}
        <Field id="unsoldTokensAddress" name="unsoldTokensAddress">
          {() => (
            <Input
              name="unsoldTokensAddress"
              color="dark"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={`e.g. ${'0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7'.toLowerCase()}`}
              value={values.unsoldTokensAddress}
              title={
                <div className={cn(style.d_form__input__label, 'text-600 text-sm')}>
                  Unsold Tokens Address
                </div>
              }
              subtitle={
                <div className="text-gray text-sm text-italic">
                  This is the address where any unsold tokens will be sent when IDO is completed.
                </div>
              }
              error={
                touched.unsoldTokensAddress && errors.unsoldTokensAddress
                  ? errors.unsoldTokensAddress
                  : ''
              }
            />
          )}
        </Field>
      </div>
      <div className={cn(style.tok_form__btns, 'form__create_presale__box')}>
        <div className="form__create_presale__btns">
          <Button
            className={cn(style.cancel_btn, 'form__create_presale__btns__item back')}
            onClick={handleBack}
          >
            Cancel
          </Button>
          <Button
            color={Object.values(errors).length ? 'disabled' : 'filled'}
            type="submit"
            className="form__create_presale__btns__item"
          >
            Next
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default TokenPoolInfoForm;
