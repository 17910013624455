import React from 'react';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import cn from 'classnames';
import { Field, Form, FormikProps } from 'formik';

import { Button, Input } from 'components';

import { IValues, IWhitelistForm } from '../container';

import style from './Whitelist.module.scss';

const WhitelistForm: React.FC<FormikProps<IValues> & IWhitelistForm> = observer(
  ({
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleBack,
    values,
    btnText,
    isLoading,
    isApprovingToken,
    isApprovingLink,
  }) => {
    const {
      user,
      modals: { walletConnect },
    } = useMst();

    const handleConnect = React.useCallback(() => {
      walletConnect.toggle();
    }, [walletConnect]);
    return (
      <Form name="project-info-form" onSubmit={handleSubmit} className={style.wl_form}>
        <div className="form__create_presale__info">
          <div className="form__create_presale__info__title">Whitelist</div>
          <div className={cn('text-gray', style.wl_form__text)}>
            This is the list of BEP-20 wallet addresses that will have access to your presale. If
            you choose to use one, only the addresses on the whitelist will be eligible to
            participate in your presale.
          </div>
        </div>
        <div className={style.wl_form__input}>
          <Field id="whitelist" name="whitelist">
            {() => (
              <Input
                max={1000}
                name="whitelist"
                color="dark"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.whitelist}
                error={touched.whitelist && errors.whitelist ? errors.whitelist : ''}
                component="textarea"
                rows={7}
              />
            )}
          </Field>
        </div>
        <div className={style.wl_form__list}>
          <div className={cn('text-gray', style.wl_form__list__item)}>
            Leave this section blank if you do not wish to use a whitelist.{' '}
          </div>
          <div className={cn('text-gray', style.wl_form__list__item)}>
            <div className={style.wl_form__list__item__example_wrapper}>
              Input only one address per line.
              <div className={style.wl_form__list__item__example}>
                <div className={style.wl_form__list__item__example_address}>
                  0xF977814e90dA44bFA03b6295A0616a897441aceC
                </div>
                <div className={style.wl_form__list__item__example_address}>
                  0xF68a4b64162906efF0fF6aE34E2bB1Cd42FEf62d
                </div>
                <div className={style.wl_form__list__item__example_address}>
                  0xBE0eB53F46cd790Cd13851d5EFf43D12404d33E8
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form__create_presale__box">
          <div className="text-gray form__create_presale__text" />
          <div className="form__create_presale__btns">
            <Button
              className="form__create_presale__btns__item back"
              onClick={handleBack}
              size="medium"
            >
              Back
            </Button>
            {user.address ? (
              <Button
                color="filled"
                type="submit"
                className="form__create_presale__btns__item"
                size="medium"
                loading={isLoading || isApprovingToken || isApprovingLink}
              >
                {btnText}
              </Button>
            ) : (
              <Button
                color="filled"
                className="form__create_presale__btns__item"
                size="medium"
                onClick={handleConnect}
              >
                Connect
              </Button>
            )}
          </div>
        </div>
      </Form>
    );
  },
);

export default WhitelistForm;
