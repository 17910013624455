import { FC, useCallback } from 'react';

// import { CopyToClipboard } from 'react-copy-to-clipboard';
import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import cn from 'classnames';

import { Button } from 'components';
import { addressWithDots } from 'utils';

import copyLink from 'assets/img/icons/copy_link.svg';

import s from './ConnectButton.module.scss';

interface IConnectProps {
  showOnMobile: boolean;
}
const ConnectButton: FC<IConnectProps> = observer(({ showOnMobile }) => {
  const {
    user,
    modals: { walletConnect, userModal },
  } = useMst();

  const handleConnect = useCallback(() => {
    walletConnect.toggle();
  }, [walletConnect]);
  const openUserModal = useCallback(() => {
    userModal.toggle();
  }, [userModal]);

  if (user.address === null) {
    return (
      <Button
        className={cn(s.connect, { [s.mobile]: showOnMobile })}
        size="medium"
        onClick={handleConnect}
      >
        Connect Wallet
      </Button>
    );
  }
  return (
    <div className={s.after_connect_wrapper}>
      {/* <CopyToClipboard text={user.address}> */}
      <Button
        className={cn(s.wallet, { [s.mobile]: showOnMobile })}
        color="filled"
        size="medium"
        onClick={openUserModal}
      >
        <span>{addressWithDots(user.address)}</span>
        <img src={copyLink} alt="copyLink" />
      </Button>
      {/* </CopyToClipboard> */}
    </div>
  );
});

export default ConnectButton;
