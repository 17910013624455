import { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import s from './PagePreview.module.scss';

interface PagePreviewProps {
  subtitle?: string;
  title: string | React.ReactElement;
  color?: 'light' | 'dark';
  type?: 'default' | 'reverse';
  megaTitle?: string;
  img?: string;
}
const PagePreview: FC<PropsWithChildren<PagePreviewProps>> = ({
  subtitle,
  title,
  children,
  color = 'dark',
  type = 'default',
  megaTitle,
  img,
}) => {
  return (
    <div className={cn(s.page_preview, s[color], s[type])}>
      <div className={cn(s.page_preview__wrapper)}>
        {subtitle ? <div className={s.subtitle}>{subtitle}</div> : null}
        {megaTitle ? <div className={cn(s.mega_title, 'text-orange')}>{megaTitle}</div> : null}
        <div className={s.title}>{title}</div>
      </div>
      {children ? <div className={s.btns}>{children}</div> : null}
      {img ? <img src={img} alt="" className={s.img} /> : null}
    </div>
  );
};

export default PagePreview;
