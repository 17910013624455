import BigNumber from 'bignumber.js/bignumber';
import { intervalToDuration } from 'date-fns';
import Web3 from 'web3';

export const addressWithDots = (address: string, slicePosition = 4, separator = '...'): string => {
  return `${address.slice(0, slicePosition)}${separator}${address.slice(-slicePosition)}`;
};

export const convertQuackTokens = (value: string | number, float = true, floatNum = 2): string => {
  const result = new BigNumber(value);
  if (result.isGreaterThanOrEqualTo(1000000000000)) {
    return `${result
      .dividedBy(1000000000000)
      .toFormat(float ? floatNum : 0)
      .toString()}T`;
  }
  if (result.isGreaterThanOrEqualTo(1000000000)) {
    return `${result
      .dividedBy(1000000000)
      .toFormat(float ? floatNum : 0)
      .toString()}B`;
  }
  if (result.isGreaterThanOrEqualTo(10000000)) {
    return `${result
      .dividedBy(10000000)
      .toFormat(float ? floatNum : 0)
      .toString()}M`;
  }
  return result.toFormat(float ? floatNum : 0).toString();
};

export const convertNumberReadableForm = (value: string | number): string => {
  return new BigNumber(value).toFormat(2);
};

export const convertQuackUsdPrice = (value: number): string => {
  return new BigNumber(value).toFixed(10);
};

export const convertToDaysLeft = (value: number): string => {
  const interval = intervalToDuration({
    start: value,
    end: Date.now(),
  });
  if (interval.days === 0) {
    return 'Last day';
  }
  return `${interval.days} days left`;
};

export const calculatePoolSize = (hardCap: string, tokenPrice: string): string => {
  return new BigNumber(new BigNumber(hardCap).dividedBy(tokenPrice)).toFormat(6);
};

export const getRegisterDate = (deadline: number, showDays = true): string => {
  const getRegistrationInterval = intervalToDuration({
    start: deadline,
    end: Date.now(),
  });
  if (showDays) {
    return `${getRegistrationInterval.days}D ${getRegistrationInterval.hours}H ${getRegistrationInterval.minutes}M`;
  }
  return `${getRegistrationInterval.hours}H ${getRegistrationInterval.minutes}M`;
};

export const calculatePercentOfVotes = (value: number, total: number): number => {
  if (total === 0) {
    return 0;
  }
  return +((value * 100) / total).toFixed(2);
};

export const isAddress = (value: string): boolean => {
  return Web3.utils.isAddress(value);
};

export { default as percentDifference } from './percentDifference';
