import { FC } from 'react';

import { observer } from 'mobx-react-lite';

import { Button, Tab, Tabs } from 'components';

import { Audit, Discuss, Info, Presale } from '..';
import Chart from './Chart';

import s from './ProjectLocks.module.scss';

const ProjectLocks: FC = observer(() => {
  return (
    <div className={s.pl_wrapper}>
      <div className={s.pl_head}>
        <div>
          <div className={s.title}>BNB E Tokenomics</div>
          <div className={s.subtitle}>Total supply; 810.720.000 BNB E</div>
        </div>
        <Button className={s.btn} color="filled">
          Lock Tokens
        </Button>
      </div>
      <Chart />
      <Tabs contentClassName={s.tab_content_wrapper}>
        <Tab label="presale">
          <Presale />
        </Tab>
        <Tab label="info">
          <Info />
        </Tab>
        <Tab label="discuss">
          <Discuss />
        </Tab>
        <Tab label="audit">
          <Audit />
        </Tab>
      </Tabs>
    </div>
  );
});

export default ProjectLocks;
